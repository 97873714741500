import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NoAddressScreen from './NoAddressScreen';
import HasAddressScreen from './HasAddressScreen';
import ModalWithMap from '../../components/GMapComponent/components/ModalWithMap';
import { useDispatch, useSelector } from '../../store';
import { IAddress } from '../../types/user';
import { postNewAddress, updateUserAddress } from '../../store/slices/address';
import { EditAddressInfo } from '../../types/address';

interface Props {
  onSkipClick: () => void;
  hasAddressSubmit: (addressId: number) => void;

}
/**
The component is divided into two parts depending on the user's addresses.
Displays two options. Displays HasAddressScreen if addresses are present, NoAddressScreen if not.
Contains a modal window with Google Maps to manage addresses.
Performs adding and updating user addresses.
*/
const AddAddressView: FC<Props> = ({ onSkipClick, hasAddressSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mapModal, setMapModal] = useState(false);
  const userAddresses = useSelector((state) => state.address.userAddresses);
  const userProfile = useSelector((state) => state.user.userProfile);

  // ! handlers
  const onAddClick = () => {
    setMapModal(true);
  };
  const onAddNewAddress = (modalInfo: EditAddressInfo) => {
    if (!modalInfo || !userProfile) return;
    const {
      addressForm: { search = '' } = {},
      coords: { lng = 0, lat = 0 } = {},
      place = 'Other',
    } = modalInfo;
    const { id, phone = '' } = userProfile;
    const addressToAdd: Omit<IAddress, 'address_line2' | 'notes' | 'id' | 'default'> = {
      name: place,
      address_line1: search.trim(),
      phone_number: phone,
      lat: `${lat}`,
      long: `${lng}`,
      user_id: id,
    };
    dispatch(postNewAddress(addressToAdd));
    setMapModal(false);
  };
  const onUpdateAddress = (addressToUpdate: IAddress) => {
    dispatch(updateUserAddress(addressToUpdate));
  };

  // ! render
  const renderHelp = () => {
    if (userAddresses?.length) {
      return (
        <HasAddressScreen
          onAddNewAddress={onAddNewAddress}
          onSubmit={hasAddressSubmit}
          userAddresses={userAddresses}
          onUpdateAddress={onUpdateAddress}
        />
      );
    }

    return <NoAddressScreen onSkipClick={onSkipClick} onAddClick={onAddClick} />;
  };

  return (
    <>
      <ModalWithMap open={mapModal} setOpen={setMapModal} onSave={onAddNewAddress} />
      <Box className="add-address-box" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h6"
          color="primary"
          mt="3rem"
          mb="1rem"
          textAlign="center"
          fontWeight={600}
        >
          {t('delivery.selectTitle')}
        </Typography>
        {renderHelp()}
      </Box>
    </>
  );
};

export default AddAddressView;
