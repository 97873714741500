import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormCard from '../FormCard';
import { IAddress, IDateTime } from '../../../types/user';
import { useDispatch, useSelector } from '../../../store';
import CreditCardMethod from './CreditCardMethod';
import { IPaymentMethodItem } from '../../../types/payment.t';
import { fetchPaymentInstruments, postPaymentInstrument, postPaymentMethod } from '../../../store/slices/order';
import BackdropLoad from '../../common/BackdropLoad';
import DeliveryAddressSection from './DeliveryAddressSection';

interface Props {
  onAddrEdit: () => void;
  onTimeEdit: () => void;
  userEmail: string;
  userFullName: string;
  currentDateTime: IDateTime | null;
  currentAddress: IAddress | null;
}
// ! The commented parts of the code refer to the trimmed delivery time selection functionality
const FormLeftSide: FC<Props> = ({
  onAddrEdit,
  userFullName,
  userEmail,
  currentDateTime,
  currentAddress,
  onTimeEdit,
}) => {
  const [selectedInstrumentId, setSelectedInstrumentId] = useState<number | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodItem | null>(null);
  // const [currentDate, setCurrentDate] = useState('');
  // const [currentTime, setCurrentTime] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const paymentMethodsArr = useSelector((state) => state.orders.paymentMethods);
  const paymentInstruments = useSelector((state) => state.orders.paymentInstruments);
  const orderId = useSelector((state) => state.orders.currentOrder?.id);
  const currentOrder = useSelector((state) => state.orders.currentOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // ! helpers
  /* const convertDateToString = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
    const dateArr = formatter.formatToParts(date);
    const dateObj = dateArr.reduce<{ [key: string]: string }>((acc, dateItem) => {
      acc[dateItem.type] = dateItem.value;
      return acc;
    }, {});
    const {
      weekday, day, month, year,
    } = dateObj;
    return `${weekday}, ${day} ${month} ${year}`;
  }; */

  // ! handlers
  const onSelectPaymentInstrument = (instrumentId: number) => {
    if (instrumentId && orderId) {
      dispatch(postPaymentInstrument({
        payment_instrument_id: instrumentId,
        orderId,
      }));
    }
    setSelectedInstrumentId(instrumentId);
  };
  const onChangePayMethod = (newMethod: IPaymentMethodItem | null) => {
    if (!newMethod) return;
    if (newMethod.id === currentOrder?.payment_method_id) return setPaymentMethod(newMethod);

    if (newMethod.kind === 'onsite' && !paymentInstruments[newMethod.id]) {
      setLocalLoading(true);
      dispatch(fetchPaymentInstruments(newMethod.id)).finally(() => setLocalLoading(false));
    }
    if (newMethod.kind !== 'onsite' && orderId) {
      const payBody: { payment_method_id: number, orderId: number, order_url?: string } = {
        payment_method_id: newMethod.id,
        orderId,
      };
      if (newMethod.kind === 'offsite') payBody.order_url = window.location.href;
      dispatch(postPaymentMethod(payBody));
    }

    setPaymentMethod(newMethod);
  };

  // ! effects
  useEffect(() => {
    if (!paymentMethod) return;
    if (paymentMethod.name !== 'Credit/Debit Card') setSelectedInstrumentId(null);
  }, [paymentMethod]);

  // preselect payment instrument
  useEffect(() => {
    if (!paymentMethod || !paymentInstruments || selectedInstrumentId) return;
    const { id, kind } = paymentMethod;
    if (kind !== 'onsite') return;
    const methodInstruments = paymentInstruments[id];
    if (!methodInstruments?.length) return;
    const firstInstrument = methodInstruments[0];
    if (!firstInstrument?.id) return;
    onSelectPaymentInstrument(firstInstrument.id);
  }, [paymentMethod, paymentInstruments, selectedInstrumentId]);

  /* useEffect(() => {
    if (!currentDateTime) return;
    const { date, time } = currentDateTime;
    if (date) setCurrentDate(convertDateToString(date));
    if (time) setCurrentTime(time);
  }, [currentDateTime]); */

  return (
    <>
      {localLoading && <BackdropLoad />}
      <Box sx={{ maxWidth: '450px' }}>

        {/* LOGGED IN NAME */}
        <FormCard>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {userFullName && <Typography variant="h6" mr="0.3rem">{userFullName}</Typography>}
            {userEmail && <Typography variant="body1">{`(${userEmail})`}</Typography>}
          </Box>
          <Typography variant="subtitle2" color="secondary.main" fontWeight="normal">
            {t('checkout.securelyLoggedIn')}
          </Typography>
        </FormCard>

        {/* DELIVERY DETAILS */}

        <FormCard>
          <Typography variant="h6">
            {t('checkout.deliveryDetails')}
          </Typography>
          <DeliveryAddressSection
            onAddrEdit={onAddrEdit}
            currentAddress={currentAddress}
          />

          {/* DELIVERY TIME */}
          {/* <CardIconLine onIconClick={onTimeEdit}>
            <Typography variant="body1">{currentDate}</Typography>
          </CardIconLine>
          <Typography variant="body1">{currentTime}</Typography> */}
        </FormCard>

        {/* Select Payment Method */}
        <FormCard>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h6" color="primary" mr="0.2rem">
              {t('checkout.selectPaymentMethod')}
            </Typography>
          </Box>
          <FormControl fullWidth>
            <RadioGroup
              defaultValue="female"
            >
              {!!paymentMethodsArr?.length && paymentMethodsArr.map((method) => {
                if (!paymentMethod && currentOrder?.payment_method_id === method?.id) {
                  setTimeout(() => onChangePayMethod(method), 0);
                }
                if (method.kind === 'onsite') {
                  const cards = paymentInstruments[method.id];
                  return (
                    <CreditCardMethod
                      key={method.id}
                      selectedMethod={paymentMethod}
                      method={method}
                      onSelectCard={onSelectPaymentInstrument}
                      onChangePayMethod={onChangePayMethod}
                      creditCards={cards}
                      selectedCardId={selectedInstrumentId}
                    />
                  );
                }
                return (
                  <FormControlLabel
                    key={method.id}
                    value={method.id}
                    control={
                      <Radio checked={paymentMethod?.id === method.id} onChange={() => onChangePayMethod(method)} />
                    }
                    label={(<Typography variant="body1" fontWeight="500">{method.name}</Typography>)}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </FormCard>
      </Box>
    </>
  );
};

export default FormLeftSide;
