import React from 'react';
import { Grid } from '@mui/material';
import clipboard from '../../../../assets/images/clipboard.png';
import box from '../../../../assets/images/box.png';
import telemedicine from '../../../../assets/images/telemedicine.png';

const IconsSection = () => (
  <div style={{ margin: '5%' }}>
    <Grid container alignItems="center" justifyContent="center" spacing={4}>
      <Grid item xs={3} md={3} className="flex-center">
        <img src={clipboard} alt="" width="50" />
      </Grid>
      <Grid item xs={3} md={3} className="flex-center">
        <img src={box} alt="" width="50" />
      </Grid>
      <Grid item xs={3} md={3} className="flex-center">
        <img src={telemedicine} alt="" width="50" />
      </Grid>
    </Grid>
  </div>
);

export default IconsSection;
