/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError } from './notifier/notifier';
import { IDateTime } from '../../types/user';
import { IActionArg, IDefaultStateFields } from '../../types/store';
import appointmentsService, { CreateAppointmentArg } from '../../services/appointments';
import { gaService } from '../../helpers/googleAnalytics';

interface IAppointmentSlice extends IDefaultStateFields {
  currentAppointment: any | null;
  currentDateTime: IDateTime | null;
}

const appointmentSliceInitialState: IAppointmentSlice = {
  loading: false,
  currentDateTime: null,
  currentAppointment: null,
};

export const fetchAppointmentById = createAsyncThunk(
  'appointmentSlice/fetchAppointmentById',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const prom = await appointmentsService.fetchAppointmentById(userId);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const patchAppointmentById = createAsyncThunk(
  'appointmentSlice/patchAppointmentById',
  async (patchInfo: { id: number, newInfo: any }, { rejectWithValue, dispatch }) => {
    try {
      const prom = await appointmentsService.patchAppointmentById(patchInfo);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const createAppointment = createAsyncThunk(
  'appointmentSlice/createAppointment',
  async (arg: CreateAppointmentArg, { rejectWithValue, dispatch }) => {
    try {
      const prom = await appointmentsService.createAppointment(arg);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

const appointmentSlice = createSlice({
  name: 'appointmentSlice',
  initialState: appointmentSliceInitialState,
  reducers: {
    setCurrentDateTime(state, { payload }: IActionArg<IDateTime>) {
      state.currentDateTime = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppointmentById.pending, pendingCase)
      .addCase(fetchAppointmentById.rejected, errorCase)
      .addCase(fetchAppointmentById.fulfilled, (state, { payload }) => {
        state.currentAppointment = payload;
        state.loading = false;
      });
    builder.addCase(createAppointment.pending, pendingCase)
      .addCase(createAppointment.rejected, errorCase)
      .addCase(createAppointment.fulfilled, (state, { payload: appointment }: IActionArg<any>) => {
        state.currentAppointment = appointment;
        gaService.appointment_created();
        state.loading = false;
      });
    builder.addCase(patchAppointmentById.pending, pendingCase)
      .addCase(patchAppointmentById.rejected, errorCase)
      .addCase(patchAppointmentById.fulfilled, (state, { payload: appointment }: IActionArg<any>) => {
        state.currentAppointment = appointment;
        state.loading = false;
      });
  },
});

export default appointmentSlice.reducer;
