import React, {
  createContext, FC, useContext, useMemo, useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_LINKS } from './routeLinks';
import { useDispatch } from '../store';
import { logout } from '../store/slices/user';
import useFormLinkTo from '../hooks/useFormLinkTo';
import { SymptomParams } from '../types/navigation';

type OnLoginArg = { tokenArg?: string; pathArg?: string; };

// Context API used for Auth related information and methods.
export const AuthContext = createContext({
  token: '',
  isAuth: false,
  onLogin: (onLoginArg?: OnLoginArg) => {},
  onLogout: () => {},
});

// Context Provider to wrap the whole app within and make auth information available.
const AuthProvider: FC = ({ children }) => {
  const tokenLS = localStorage.getItem('token');
  const [token, setToken] = useState(tokenLS || '');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const formLinkTo = useFormLinkTo();
  const params = useParams<SymptomParams>();

  const handleLogin = async (onLoginArg?: OnLoginArg) => {
    const { tokenArg, pathArg } = onLoginArg || {};
    const existingToken = tokenArg || localStorage.getItem('token');
    if (!existingToken) return;
    if (tokenArg) localStorage.setItem('token', existingToken);

    setToken(existingToken);

    const origin = location.state?.from?.pathname || formLinkTo({ mainPath: ROUTE_LINKS.DELIVERY });
    navigate(pathArg || origin);
  };

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    dispatch(logout());
    if (!params.symptomSlug) return;
    navigate(`${ROUTE_LINKS.HOME}/${params.symptomSlug}`);
  };

  const value = useMemo(() => ({
    token,
    isAuth: !!token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  }), [token]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useAuth() {
  return useContext(AuthContext);
}
