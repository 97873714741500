import React, { FC } from 'react';
import { Box } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

interface Props {
  onIconClick: () => void;
  maxWidth?: string;
}

const CardIconLine: FC<Props> = ({ children, onIconClick, maxWidth }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Box sx={{ display: 'flex', maxWidth: maxWidth || 'unset' }}>
      {children}
    </Box>
    <EditOutlined sx={{ width: '1.1rem', cursor: 'pointer' }} onClick={onIconClick} />
  </Box>
);

export default CardIconLine;
