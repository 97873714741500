/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError } from './notifier/notifier';
import countriesService from '../../services/countries';
import { Country } from '../../types/common';
import { IDefaultStateFields } from '../../types/store';

interface ICountriesSlice extends IDefaultStateFields {
  countriesList: Country[];
}

const countriesSliceInitialState: ICountriesSlice = {
  countriesList: [],
  loading: false,
};

export const fetchCountries = createAsyncThunk(
  'countriesSlice/fetchCountries',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const prom = await countriesService.fetchCountries();
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

const countriesSlice = createSlice({
  name: 'countriesSlice',
  initialState: countriesSliceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, pendingCase)
      .addCase(fetchCountries.rejected, errorCase)
      .addCase(fetchCountries.fulfilled, (state, { payload }) => {
        state.countriesList = payload.countries;
        state.loading = false;
      });
  },
});

export default countriesSlice.reducer;
