import { AxiosRequestConfig } from 'axios';

export const insertTokenInConfig = (config: AxiosRequestConfig, token: string) => {
  const oldHeaders = config.headers || {};
  const headersWithToken = {
    ...oldHeaders,
    authorization: token,
  };
  return {
    ...config,
    headers: headersWithToken,
  };
};

export const insertLocaleInConfig = (config: AxiosRequestConfig) => {
  const oldHeaders = config.headers || {};
  const userLocale = localStorage.getItem('locale');
  const headersWithToken = {
    ...oldHeaders,
    'x-locale': userLocale || 'en',
  };
  return {
    ...config,
    headers: headersWithToken,
  };
};
