import React, { FC } from 'react';
import {
  Box, FormControlLabel, Radio, Typography,
} from '@mui/material';
import PaymentDropdown from './PaymentDropdown/PaymentDropdown';
import { IPaymentInstrument, IPaymentMethodItem } from '../../../types/payment.t';

interface Props {
  onSelectCard: (cardId: number) => void;
  onChangePayMethod: (newMethod: IPaymentMethodItem | null) => void;
  creditCards?: IPaymentInstrument[];
  selectedCardId: number | null;
  selectedMethod: IPaymentMethodItem | null;
  method: IPaymentMethodItem;
}

const CreditCardMethod: FC<Props> = ({
  onChangePayMethod,
  creditCards,
  selectedCardId,
  onSelectCard,
  selectedMethod,
  method,
}) => (
  <Box sx={{ position: 'relative', maxWidth: '100%' }}>
    <FormControlLabel
      control={
        <Radio checked={selectedMethod?.id === method.id} onChange={() => onChangePayMethod(method)} />
      }
      label={(
        <Typography
          variant="body1"
          fontWeight="500"
          noWrap
          sx={{ width: '100%' }}
        >
          {method.name}
        </Typography>
      )}
    />
    {(method.id === selectedMethod?.id && !!creditCards?.length) && (
      <PaymentDropdown
        creditCards={creditCards}
        selectedCardId={selectedCardId}
        onSelectCard={onSelectCard}
      />
    )}
  </Box>
);

export default CreditCardMethod;
