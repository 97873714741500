import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IconsSection from './components/IconsSection';
import StartSection from './components/StartSection';
import { ROUTE_LINKS } from '../../navigation/routeLinks';
import { SymptomParams } from '../../types/navigation';
import { useDispatch, useSelector } from '../../store';
import { fetchSymptomBySlug } from '../../store/slices/symptoms';
import BackdropLoad from '../../components/common/BackdropLoad';
import { gaService } from '../../helpers/googleAnalytics';

const redirectURL = process.env.REACT_APP_NO_SYMPTOM_REDIRECT_URL;
if (!redirectURL) throw new Error('can`t find REACT_APP_NO_SYMPTOM_REDIRECT_URL in .env');

const HomePage = () => {
  const navigate = useNavigate();
  const params = useParams<SymptomParams>();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.symptoms.loading);

  // ! effects
  /*
  The effect checks the application URL for the {symptomSlug}.
  If the symptom is present and the link leads to the root, it redirects us to /home/{symptomSlug}
  After all, we can fetch numeric symptom definition.
  If URL does not contain {symptomSlug} it redirects to 3-rd party app
  */
  useEffect(() => {
    if (!params.symptomSlug) {
      window.location.href = redirectURL;
      return;
    }
    const { symptomSlug } = params;

    if (symptomSlug?.includes('home')) {
      window.location.href = redirectURL;
      return;
    }

    if (location.pathname.includes('home')) {
      dispatch(fetchSymptomBySlug(symptomSlug));
      gaService.landed();
      return;
    }
    navigate(`${ROUTE_LINKS.HOME}/${symptomSlug}`);
  }, [params, location]);

  // ! handlers
  const handleStart = () => {
    gaService.q_started();
    navigate({
      pathname: `${ROUTE_LINKS.QUESTIONNAIRE}/${params.symptomSlug}`,
    }, { replace: true });
  };

  return (
    <Container maxWidth="md" sx={{ flexGrow: '1', display: 'flex' }}>
      {loading && <BackdropLoad />}
      <Grid container justifyContent="center" alignItems="center" alignSelf="center">
        <Grid item sm={12}>
          <StartSection handleOpen={handleStart} />
          <IconsSection />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
