import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';

interface Props {
  leftSideText: string;
  rightSideText: string;
  captionText?: string | null;
  rightColor?: string;
  leftColor?: string;
  rightFontWeight?: number;
  leftFontWeight?: number;
}

const TextCardLine: FC<Props> = ({
  leftSideText,
  rightSideText,
  captionText,
  rightColor,
  leftColor,
  rightFontWeight,
  leftFontWeight,
}) => (
  <Grid container sx={{ marginTop: '1rem' }}>
    <Grid item xs={8}>
      <Typography
        variant="body1"
        color={leftColor}
        fontWeight={leftFontWeight || 'normal'}
      >
        {leftSideText}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography
        variant="body1"
        textAlign="right"
        color={rightColor}
        fontWeight={rightFontWeight || 'normal'}
      >
        {rightSideText}
      </Typography>
    </Grid>
    <Typography
      variant="caption"
      lineHeight={1}
      fontWeight="400"
      color="secondary"
    >
      {captionText}
    </Typography>
  </Grid>
);

export default TextCardLine;
