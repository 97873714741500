import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Container } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import QuestionnaireScreen from '../../components/Questionnaire/QuestionnaireScreen';
import QuestionnaireProgress from '../../components/Questionnaire/QuestionnaireProgress';
import { useDispatch, useSelector } from '../../store';
import {
  cleanUserAnswers,
  clearUserDescriptions,
  fetchSymptomDataById,
  postQuestionnaireResponses,
  removeUserAnswer,
  setUserAnswer,
  setUserDescriptions,
} from '../../store/slices/questionnaireSlice';
import { getCurrentSymptomQuestions } from '../../store/selectors/questionnaire';
import { IQuestion } from '../../types/common';
import AuthModal from '../../components/modals/AuthModal';
import CustomModalWrap from '../../components/modals/CustomModalWrap';
import MoreDetails, { IMoreDetailsForm } from '../../components/modals/MoreDetails';
import { useAuth } from '../../navigation/AuthProvider';
import {
  INormalizedAnswer,
  INormalizedQuestion,
  IUserAnswer,
  TNormalizedData,
  TUserAnswer,
  TUserAnswers,
} from '../../types/questionnaire';
import { isNormalizedQuestion } from '../../helpers/typeHelpers';
import { CreateAppointmentAnswer, PatientQuestionnaireResponse } from '../../types/appointments';
import { fetchUserProfile, patchUserProfile } from '../../store/slices/user';
import BackdropLoad from '../../components/common/BackdropLoad';
import { IUserProfile } from '../../types/user';
import { handleError } from '../../store/slices/notifier/notifier';
import { ROUTE_LINKS } from '../../navigation/routeLinks';
import useFormLinkTo from '../../hooks/useFormLinkTo';
import { SymptomParams } from '../../types/navigation';
import CustomError from '../../store/slices/notifier/customErrorClass';
import { customErrors } from '../../store/slices/notifier/errorObject';
import { fetchSymptomBySlug } from '../../store/slices/symptoms';
import { createAppointment } from '../../store/slices/appointment';

/*
The component manages the questionnaire.
- Keeps a history of user actions;
- records the answers given by the user;
- calculates  user answers percentage to the number of questions;

The container is also responsible for the final user authentication requests since the authentication modal is
located here.

  How it works:
  - The component receives an object of objects, where the key is the id, and the value is the question or answer.
  - When the first question is received, the component searches for the answer options in the question and
  displays them on the screen.
  - When the user selects some responses, the component receives the id of the selected entities.
  - Component writes answers to the history, calculates questionnaire completion percentage, finds
  and displays the next question.

  The questionnaire feature is that any answer can contain a subsequent question with more answers etc.
  To prevent recursively going deep into the array of arrays, all data converted into
  a flat object of objects structure at the request stage in redux , so any entity can be instantly accessed by its id.
  */

const sxQuestionnaireContainer = {
  flexGrow: '1',
  display: 'flex!important',
  flexDirection: 'column',
};

const QuestionnaireContainer = () => {
  const dispatch = useDispatch();
  const { onLogin } = useAuth();
  const formLinkTo = useFormLinkTo();
  const { symptomSlug } = useParams<SymptomParams>();
  const params = useParams<SymptomParams>();
  const navigate = useNavigate();
  // ! state
  // modals
  const [isAuthModal, setIsAuthModal] = useState(false);
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  // else
  const [percentage, setPercentage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<INormalizedQuestion | null>(null);
  const [currentAnswers, setCurrentAnswers] = useState<INormalizedAnswer[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(0);
  const [existingDetails, setExistingDetails] = useState<IMoreDetailsForm | null>(null);
  // ! refs
  const history = useRef<number[]>([]);
  // ! selectors
  const questionsArray = useSelector<IQuestion[]>(getCurrentSymptomQuestions);
  const normalizedData = useSelector<TNormalizedData | null>((state) => state.questionnaire.normalizedData);
  const userAnswers = useSelector<TUserAnswers>((state) => state.questionnaire.userAnswers);
  const userDescriptions = useSelector<TUserAnswers>((state) => state.questionnaire.userDescriptions);
  const questLoading = useSelector<boolean>((state) => state.questionnaire.loading);
  const appointmentLoading = useSelector<boolean>((state) => state.appointment.loading);
  const userLoading = useSelector<boolean>((state) => state.user.loading);
  const symptomId = useSelector((state) => state.symptoms.currentSymptom?.id);
  const symptomLoading = useSelector((state) => state.symptoms.loading);
  // ! memos
  // eslint-disable-next-line max-len
  const loading = useMemo(
    () => questLoading || userLoading || symptomLoading || appointmentLoading,
    [questLoading, userLoading, symptomLoading, appointmentLoading],
  );
  // ! vars
  const hasPrevQuestions = !!history.current[historyIndex - 1];

  // ! helpers questionnaire flow
  /*
  If the answer contains sub-questions, we add them to the history later than the current question, so when the user
  clicks "next" he gets not to the one of the root question, but to these sub-questions.
  */
  const addQuestionsToHistory = (questionIds: number[]) => {
    if (!currentQuestion?.id) return;
    const index = history.current.indexOf(currentQuestion.id);
    if (index === -1) {
      console.error('Can`t find index in addQuestionsToHistory');
      return;
    }
    const arrCopy = [...history.current];
    arrCopy.splice(index + 1, 0, ...questionIds);
    const set = new Set([...arrCopy]);
    history.current = [...set];
  };
  const pushToHistory = (id: number) => {
    const index = history.current.indexOf(id);
    if (index !== -1) return;
    history.current.push(id);
  };

  const getAnswers = (question: INormalizedQuestion): INormalizedAnswer[] => {
    if (!question) return [];
    if (!normalizedData) return [];
    if (!question.nextStep.length) return [];

    const { nextStep } = question;
    const answers: INormalizedAnswer[] = [];

    nextStep.forEach((answerId) => {
      const normalizedAnswer = normalizedData[answerId];
      if (normalizedAnswer && !isNormalizedQuestion(normalizedAnswer)) answers.push(normalizedAnswer);
    });
    return answers;
  };
  const getQuestionsIdsArrayCase = (answerId: number[]): number[] | null => {
    const questionIds: number[] = [];
    answerId.forEach((id) => {
      const answerInstance = normalizedData![id];
      if (isNormalizedQuestion(answerInstance)) return;

      const { nextRootId, nextStep } = answerInstance;

      if (nextRootId) return pushToHistory(nextRootId);
      nextStep.forEach((questionId) => questionIds.push(questionId));
    });
    return questionIds.length ? questionIds : null;
  };
  /*
  if the answer contains sub-questions, we add them to the history later than the current question, so when the user
  clicks "next" he gets not to the one of the root question, but to these sub-questions
  */
  const getQuestionsIds = (answerId: TUserAnswer): number[] | null => {
    if (!normalizedData) return null;
    // array case
    if (Array.isArray(answerId)) return getQuestionsIdsArrayCase(answerId);

    // string case
    const numberedAnswerId = Number.parseInt(answerId, 10);
    if (currentQuestion?.symptom_type === 'text') {
      pushToHistory(currentQuestion.nextRootId);
      return null;
    }
    const answerInstance = normalizedData[numberedAnswerId];
    const { nextRootId, nextStep } = answerInstance || {};
    if (nextRootId) {
      pushToHistory(nextRootId);
      return null;
    }
    return nextStep?.length ? nextStep : null;
  };
  /**
  The function gets the question id and finds its entity.
  Then he looks at the id of answer options for this question and finds them
  */
  const setCurrentQuestionById = (questionId: number) => {
    if (!normalizedData) return;
    const normalizedQuestion = normalizedData[questionId];
    if (!isNormalizedQuestion(normalizedQuestion)) return;
    const answers = getAnswers(normalizedQuestion);
    setCurrentAnswers(answers);
    setCurrentQuestion(normalizedQuestion);
    pushToHistory(normalizedQuestion.id);
  };
  /**
  Сlears the history forward from the given position
  */
  const clearNextHistory = () => {
    const newHistory: number[] = [];
    const answersToRemove: number[] = [];
    history.current.forEach((id, index) => {
      if (index <= historyIndex) return newHistory.push(id);
      answersToRemove.push(id);
    });
    history.current = newHistory;
    dispatch(removeUserAnswer(answersToRemove));
  };
  /**
   If answer is not same - clears the history forward from the given position
   */
  const checkIsAnswerSame = (userAnswer: IUserAnswer) => {
    const { answer, questionId } = userAnswer;
    const existingAnswer = userAnswers[questionId];
    if (!existingAnswer && typeof existingAnswer !== 'string') return;
    if (JSON.stringify(existingAnswer) !== JSON.stringify(answer)) clearNextHistory();
  };
  const countPercentage = () => {
    const historyLength = history.current.length;
    const allLength = questionsArray?.length || 0;
    const totalQuestionsLength = (historyLength > allLength) ? historyLength : allLength;
    const answeredLength = Object.keys(userAnswers)?.length || 0;
    const percen = (answeredLength / (totalQuestionsLength || 1)) * 100;
    setPercentage(Math.round(percen));
  };
  /**
  reset questionnaire state
  */
  const clearHistoryAndAnswers = () => {
    dispatch(cleanUserAnswers());
    dispatch(clearUserDescriptions());
    setHistoryIndex(0);
    history.current = [];
  };

  // ! helpers auth and answers
  const getAnswerText = (answerId: TUserAnswer) => {
    if (!normalizedData) return;
    if (!Array.isArray(answerId)) {
      const answerInstance = normalizedData[Number.parseInt(answerId, 10)];
      const answerDescription = userDescriptions[answerId as unknown as number] || userDescriptions[answerInstance?.id];
      let currentAnswer = answerInstance?.name || answerId;
      if (answerDescription) currentAnswer = `${currentAnswer}, ${answerDescription}`;
      return currentAnswer;
    }
    // array case
    const answerTextArray = answerId.reduce<string[]>((acc, id) => {
      const answerInstance = normalizedData[id];
      if (!answerInstance) return acc;

      let answerText = answerInstance.name;
      if (!answerText) return acc;

      const answerDescription = userDescriptions[id];
      if (answerDescription) answerText = `${answerText}, ${answerDescription}`;

      acc.push(answerText);
      return acc;
    }, []);
    return answerTextArray.join(',');
  };
  /**
  change answers data structure to send it to server
  */
  const serializeUserAnswers = (): PatientQuestionnaireResponse | void => {
    if (!userAnswers) return;
    if (!normalizedData) return;

    const answersEntries = Object.entries(userAnswers);
    if (!answersEntries?.length) return;

    const questionnaire = answersEntries.reduce<CreateAppointmentAnswer[]>((acc, [question, answer]) => {
      const questionInstance = normalizedData[Number.parseInt(question, 10)];
      const questionText = questionInstance?.name;
      if (!questionText) return acc;

      let ansText = getAnswerText(answer);
      if (ansText) ansText = ansText.trim();
      if (!ansText) ansText = '';

      const answerItem: CreateAppointmentAnswer = {
        question: questionText,
        answer: ansText,
      };
      acc.push(answerItem);
      return acc;
    }, []);
    return {
      symptom_id: symptomId,
      set_invoice_value: false,
      questionnaire,
    };
  };
  const createNewAppointment = async (symptom_id: string | number) => {
    try {
      const wrappedAppointment = await dispatch(createAppointment({
        symptom_id,
        expects_order: true,
        appointment_type: 'stigmatized',
      }));
      return unwrapResult(wrappedAppointment);
    } catch (e) {
      return null;
    }
  };
  const sendUserAnswers = async () => {
    if (!symptomId) return null;

    const serializedAnswers = serializeUserAnswers();
    if (!serializedAnswers) {
      dispatch(handleError(new CustomError(customErrors.CANT_FIND_ANSWERS)));
      clearHistoryAndAnswers();
      setIsAuthModal(false);
      return null;
    }

    const appointment = await createNewAppointment(symptomId);
    if (!appointment) {
      dispatch(handleError(new CustomError(customErrors.CAN_NOT_CREATE_APPOINTMENT)));
      return null;
    }
    try {
      const res = await dispatch(postQuestionnaireResponses(
        { questionnaireResponse: serializedAnswers, appointmentId: appointment.id },
      ));
      unwrapResult(res);
      return true;
    } catch (e) {
      console.error('sendUserAnswers error');
      console.dir(e);
      return null;
    } finally {
      if (appointment?.id) {
        const deliveryRedirectLink = formLinkTo({
          mainPath: ROUTE_LINKS.TREATMENT,
          appointmentIdArg: appointment.id,
        });
        onLogin({ pathArg: deliveryRedirectLink });
      }
    }
  };
  const findUserProfile = async () => {
    try {
      if (!localStorage.getItem('token')) return null;

      const wrappedProfile = await dispatch(fetchUserProfile());
      return unwrapResult(wrappedProfile);
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 422) return null;
      console.error('findUserProfile error: ');
      console.dir(e);
      return null;
    }
  };

  // ! handlers
  const onNext = (answer: IUserAnswer) => {
    // no answers block
    if (!currentQuestion) return;
    checkIsAnswerSame(answer);
    dispatch(setUserAnswer(answer));
    if (answer.descriptions) dispatch(setUserDescriptions(answer.descriptions));

    const nextQuestions = getQuestionsIds(answer.answer);
    if (nextQuestions) addQuestionsToHistory(nextQuestions);

    const hasNoHistoryMore = !history.current[historyIndex + 1];
    const hasNoSubQuestions = !nextQuestions?.length;
    if (hasNoHistoryMore && hasNoSubQuestions) {
      setIsAuthModal(true);
      setPercentage(99);
      return;
    }
    setHistoryIndex((prevState) => prevState + 1);
  };
  const onPrev = () => {
    setHistoryIndex((prevState) => prevState - 1);
  };
  /*
  If the user has not yet filled out information about himself, an additional modal window appears with fields to
  fill out
  */
  const onSubmitAuth = async () => {
    try {
      const userProf = await findUserProfile();
      if (!userProf) {
        setIsAuthModal(false);
        setMoreDetailsOpen(true);
        return;
      }

      const {
        first_name,
        gender,
        email,
        birth_date,
        last_name,
      } = userProf;
      const hasName = last_name || first_name;
      const hasInfo = hasName && gender && birth_date;
      if (!hasInfo) {
        const birth = new Date(birth_date);
        let fullName = first_name || '';
        if (last_name) fullName += ` ${last_name}`;
        setExistingDetails({
          birth: birth || null,
          email: email || '',
          gender: gender || 'M',
          name: fullName || '',
        });
        setIsAuthModal(false);
        setMoreDetailsOpen(true);
        return;
      }
      sendUserAnswers();
    } catch (e) {
      console.error('onSubmitAuth Error: ');
      console.dir(e);
    }
  };
  const onMoreDetailsSubmit = async (userDetails: IMoreDetailsForm) => {
    if (!userDetails) return;
    const {
      email,
      gender,
      name,
      birth,
    } = userDetails;
    const nameArray = name.split(' ');
    const firstName = nameArray[0];
    const lastName = nameArray[1];
    const infoToUpdate: Partial<IUserProfile> = {
      first_name: firstName || '',
      last_name: lastName || '',
      gender,
      email,
      birth_date: birth,
    };
    try {
      const answer = await dispatch(patchUserProfile(infoToUpdate));
      unwrapResult(answer);
      sendUserAnswers();
    } catch (e) {
      console.error('onMoreDetailsSubmit error');
      console.dir(e);
    }
  };

  // ! effects
  useEffect(() => {
    if (symptomId) return;
    if (!symptomSlug) return;
    dispatch(fetchSymptomBySlug(symptomSlug));
  }, [symptomSlug, symptomId]);

  useEffect(() => {
    if (!symptomId) return;
    dispatch(fetchSymptomDataById(symptomId));
  }, [symptomId]);

  // set first question when component is mounted
  useEffect(() => {
    if (!questionsArray?.length || !normalizedData) return;
    const firstQuestionId = questionsArray[0].id;
    if (!firstQuestionId) return;
    setCurrentQuestionById(firstQuestionId);
  }, [questionsArray, normalizedData]);

  useEffect(() => {
    const candidate = history.current[historyIndex];
    if (candidate) {
      setCurrentQuestionById(candidate);
    }
  }, [historyIndex]);

  useEffect(() => {
    countPercentage();
  }, [historyIndex, userAnswers, userAnswers, questionsArray]);

  // watch for language settings, if language changes - reset questionnaire. Start from the very beginning
  useEffect(() => {
    if (!params.symptomSlug) return;
    i18n.on('languageChanged', () => {
      if (history.current.length < 2) return;
      if (!window.location.href.includes(ROUTE_LINKS.QUESTIONNAIRE)) return;
      clearHistoryAndAnswers();
      navigate(`${ROUTE_LINKS.HOME}/${params.symptomSlug}`);
    });
  }, [params]);

  // ! render
  if (loading) return <BackdropLoad />;
  return (
    <>
      <CustomModalWrap open={moreDetailsOpen} setOpen={setMoreDetailsOpen}>
        <MoreDetails onSubmit={onMoreDetailsSubmit} existingDetails={existingDetails} />
      </CustomModalWrap>
      <AuthModal submitAuth={onSubmitAuth} open={isAuthModal} />

      <Container maxWidth="sm" sx={sxQuestionnaireContainer}>
        <QuestionnaireProgress progress={percentage} />

        {currentQuestion && (
          <QuestionnaireScreen
            onNext={onNext}
            onPrev={onPrev}
            hasPrevQuestions={hasPrevQuestions}
            hasMoreQuestions
            currentQuestion={currentQuestion}
            currentAnswers={currentAnswers}
            existingAnswer={userAnswers[currentQuestion.id]}
          />
        )}
      </Container>
    </>
  );
};
export default QuestionnaireContainer;
