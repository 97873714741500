import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import PreferredTreatmentView from '../pages/PreferredTreatmentView';
import SelectPlanView from '../pages/SelectPlanView';
import { useDispatch, useSelector } from '../store';
import {
  fetchTreatmentBySymptomId,
  postTreatment,
} from '../store/slices/order';
import { ITreatment, ITreatmentPlan, ITreatmentVariant } from '../types/treatment';
import BackdropLoad from '../components/common/BackdropLoad';
import { handleError } from '../store/slices/notifier/notifier';
import CustomError from '../store/slices/notifier/customErrorClass';
import { customErrors } from '../store/slices/notifier/errorObject';
import { ROUTE_LINKS } from '../navigation/routeLinks';
import useFormLinkTo from '../hooks/useFormLinkTo';
import { OptionalOrderParams } from '../types/navigation';
import { fetchSymptomBySlug } from '../store/slices/symptoms';
import { gaService } from '../helpers/googleAnalytics';

export interface IPlanVariant extends Omit<ITreatmentVariant, 'portion'> {
  subtitle: string;
}

/**
The component displays treatment options with the user's choice of treatment option and treatment plan.
*/
const TreatmentContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<OptionalOrderParams>();
  const [step, setStep] = useState<'treatment' | 'plan'>('treatment');
  const [localLoading, setLocalLoading] = useState(false);
  const [treatmentPlans, setTreatmentPlans] = useState<ITreatmentPlan[]>([]);
  const formLinkTo = useFormLinkTo();

  // ! selectors
  const allTreatments = useSelector((state) => state.orders.treatments);
  const country_id = useSelector((state) => state.user.userProfile?.country_id);
  const symptomId = useSelector((state) => state.symptoms.currentSymptom?.id);
  const symptomLoading = useSelector((store) => store.symptoms.loading);

  // ! memos
  const { mainTreatment, otherTreatments } = useMemo(() => {
    if (!allTreatments?.length) return {};
    const [main, ...rest] = allTreatments;
    return {
      mainTreatment: main,
      otherTreatments: rest,
    };
  }, [allTreatments]);

  // ! helpers
  const getAppointmentId = () => {
    const appointment_id = params.appointmentId;
    if (!appointment_id) {
      dispatch(handleError(new CustomError(customErrors.NO_CURRENT_APPOINTMENT_ID)));
      return null;
    }
    return appointment_id;
  };

  const postTreatmentVariantFlow = async (treatment_variant_id: number) => {
    const appointment_id = getAppointmentId();
    if (!appointment_id) return;
    await dispatch(postTreatment({
      appointment_id,
      treatment_variant_id,
    }));
    navigate({ pathname: formLinkTo({ mainPath: ROUTE_LINKS.DELIVERY }) });
  };
  // ! handlers
  const onSelectTreatment = (treatmentVariantId: number, treatmentItem: ITreatment) => {
    gaService.treatment_variant_selected();
    const selectedTreatmentVariant = treatmentItem.treatment_variants.find((trVar) => trVar.id === treatmentVariantId);
    if (!selectedTreatmentVariant?.treatment_plans?.length) {
      postTreatmentVariantFlow(treatmentVariantId);
      return;
    }

    const newTreatmentPlans = selectedTreatmentVariant.treatment_plans;
    setTreatmentPlans(newTreatmentPlans);
    setStep('plan');
  };
  const onSelectPlan = async (selectedPlanId: number) => {
    const selectedPlan = treatmentPlans.find((plItem) => plItem.id === selectedPlanId);
    const appointment_id = getAppointmentId();
    if (!appointment_id || !selectedPlan) return;
    await dispatch(postTreatment({
      appointment_id,
      treatment_plan_id: selectedPlanId,
      treatment_variant_id: selectedPlan.treatment_variant_id,
    }));
    gaService.treatment_plan_selected();
    navigate({ pathname: formLinkTo({ mainPath: ROUTE_LINKS.DELIVERY }) });
  };
  const onNoTreatments = async () => {
    const appointment_id = getAppointmentId();
    if (!appointment_id) return;
    navigate({ pathname: formLinkTo({ mainPath: ROUTE_LINKS.DELIVERY }) });
  };

  // ! effects
  useEffect(() => {
    if (symptomId) return;
    if (!params.symptomSlug) return;
    dispatch(fetchSymptomBySlug(params.symptomSlug));
  }, [params, symptomId]);

  useEffect(() => {
    if (!symptomId) return;
    setLocalLoading(true);
    const fetchTreatments = async (symptomIdArg: number) => {
      try {
        const wrappedTreatments = await dispatch(fetchTreatmentBySymptomId({
          symptomId: symptomIdArg,
          country_id,
        }));
        const unwrappedTreatments = unwrapResult(wrappedTreatments);
        if (!unwrappedTreatments?.length) await onNoTreatments();
        setLocalLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLocalLoading(false);
      }
    };

    fetchTreatments(symptomId);
  }, [symptomId]);

  // ! render
  const renderHelp = () => {
    if (localLoading || symptomLoading) return <BackdropLoad />;
    switch (step) {
      case 'treatment':
        return (
          <PreferredTreatmentView
            onSelectTreatment={onSelectTreatment}
            mainTreatment={mainTreatment}
            otherTreatments={otherTreatments}
          />
        );
      case 'plan':
        return (
          <SelectPlanView
            onSelectPlan={onSelectPlan}
            variants={treatmentPlans}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {renderHelp()}
    </Box>
  );
};

export default TreatmentContainer;
