import React, { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

const skipButtonSx: SxProps = {
  cursor: 'pointer!important',
  padding: '0.2rem 0.5rem',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'lightgray',
  },
};

interface Props {
  onSkipClick: () => void;
}

const SkipQuestionButton: FC<Props> = ({ onSkipClick }) => (
  <Box className="flex-center" sx={{ position: 'relative', top: '1.4rem' }}>
    <Typography
      variant="caption"
      sx={skipButtonSx}
      textAlign="center"
      onClick={onSkipClick}
    >
      skip question
    </Typography>
  </Box>
);

export default SkipQuestionButton;
