import React, { FC, useEffect, useState } from 'react';
import {
  Box, Button, Container, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { IPlanVariant } from '../../containers/TreatmentContainer';
import VariantsList from '../../components/PreferredTreatmentPage/VariantsList';
import { ITreatmentPlan } from '../../types/treatment';
import { useSelector } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  planContainer: {
    [theme.breakpoints.down('sm')]: {
      '& .item-subtitle': {
        lineHeight: '1',
      },
      '& .variant-item-wrap': {
        alignItems: 'center',
        minHeight: 'unset',
      },
      '& .item-discount': {
        textAlign: 'right',
      },
      '& .MuiFormControlLabel-root': {
        maxWidth: '75%',
        marginRight: '0',
      },
    },
  },
}));

interface Props {
  variants: IPlanVariant[] | ITreatmentPlan[];
  onSelectPlan: (selectedPlanVariant: number) => void;
}

const SelectPlanView: FC<Props> = ({
  variants, onSelectPlan,
}) => {
  const { planContainer } = useStyles();
  const [selectedPlanVariant, setSelectedPlanVariant] = useState<number | null>(null);
  const loading = useSelector((state) => state.orders.loading);
  const { t } = useTranslation();

  // ! handlers
  const onPlanVariantSelect = (planId: number | null) => {
    setSelectedPlanVariant(planId);
  };
  const handleNextClick = () => {
    if (!selectedPlanVariant) return;
    onSelectPlan(selectedPlanVariant);
  };

  // ! effects
  useEffect(() => {
    if (!variants?.length) return;
    const firstPlan = variants[0];
    if (!firstPlan?.id) return;
    onPlanVariantSelect(firstPlan.id);
  }, [variants]);

  // ! render
  return (
    <Container maxWidth="md" className={planContainer}>
      <Typography
        variant="h6"
        fontWeight={600}
        textAlign="center"
        color="primary.main"
        mb="4rem"
      >
        {t('treatmentPlan.treatmentPlanTitle')}
      </Typography>
      <Container maxWidth="sm" sx={{ marginBottom: '5.2rem' }}>
        <VariantsList
          variants={variants}
          selectedTrVariant={selectedPlanVariant}
          onVariantSelect={onPlanVariantSelect}
        />
      </Container>
      <Box width="100%" className="flex-center" sx={{ marginBottom: '2rem' }}>
        <Button
          disabled={!selectedPlanVariant || loading}
          variant="contained"
          color="primary"
          sx={{ paddingX: '2rem', fontSize: '16px' }}
          onClick={handleNextClick}
        >
          {t('buttons.next')}
          {' '}
          {'>'}
        </Button>
      </Box>
    </Container>
  );
};

export default SelectPlanView;
