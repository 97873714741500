import React, { FC } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PriceBlock from './PriceBlock';
import VariantLabel from './VariantLabel';
import { ITreatmentPlan, ITreatmentVariant } from '../../../types/treatment';

const useStyles = makeStyles(() => ({
  card: ({ isChecked }: { isChecked: boolean }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem 0.2rem 1rem',
    border: isChecked ? '1px solid #76a2b1' : 'none',
    borderRadius: '6px',
    minHeight: '65px',
  }),
}));

interface Props {
  variant: ITreatmentVariant | ITreatmentPlan;
  selectedTrVariant: number | null;
  onVariantSelect: (variantId: number | null) => void;
}

const VariantsListItem: FC<Props> = ({ variant, selectedTrVariant, onVariantSelect }) => {
  const { card } = useStyles({ isChecked: selectedTrVariant === variant.id });

  // ! render
  return (
    <Box
      className={[card, 'variant-item-wrap'].join(' ')}
    >
      <FormControlLabel
        control={(
          <Radio
            checked={selectedTrVariant === variant.id}
            onChange={() => onVariantSelect(variant.id)}
          />
        )}
        label={(
          <VariantLabel
            title={variant.title}
            subtitle={variant.subtitle}
          />
        )}
        sx={{ flexGrow: 1 }}
      />
      <PriceBlock
        currency={variant.currency_code_iso}
        salePrice={variant.sale_price}
        unitPrice={variant.unit_price}
      />
    </Box>
  );
};

export default VariantsListItem;
