/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError } from './notifier/notifier';
import { IOrderInstance } from '../../types/order';
import { IActionArg, IDefaultStateFields } from '../../types/store';
import { IPaymentInstrument, IPaymentMethodItem } from '../../types/payment.t';
import orderService from '../../services/order';
import treatmentService from '../../services/treatments';
import { IPostTreatmentArgs, ITreatment } from '../../types/treatment';
import { gaService } from '../../helpers/googleAnalytics';

interface IOrderSlice extends IDefaultStateFields {
  currentOrder: IOrderInstance | null;
  treatments: ITreatment[];
  paymentMethods: IPaymentMethodItem[] | null;
  paymentInstruments: { [key: number]: IPaymentInstrument[] };
}

const orderSliceInitialState: IOrderSlice = {
  loading: false,
  currentOrder: null,
  paymentMethods: null,
  paymentInstruments: {},
  treatments: [],
};

export const fetchPaymentInstruments = createAsyncThunk(
  'orderSlice/fetchPaymentInstruments',
  async (methodId: number, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const prom = await orderService.fetchPaymentInstruments(methodId);
      return fulfillWithValue({ instruments: prom.data, methodId });
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

export const createOrderByAppointmentId = createAsyncThunk(
  'orderSlice/createOrderByAppointmentId',
  async (appointmentId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.createOrderByAppointmentId(appointmentId);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const postDeliveryAddressIntoOrder = createAsyncThunk(
  'orderSlice/postDeliveryAddressIntoOrder',
  async (info: { orderId: string | number, addressId: string | number }, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.postDeliveryAddressIntoOrder(info);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const fetchPaymentMethods = createAsyncThunk(
  'orderSlice/fetchPaymentMethods',
  async (countryId: number, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.fetchPaymentMethods(countryId);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const postPaymentMethod = createAsyncThunk(
  'orderSlice/postPaymentMethod',
  async (arg: {
    payment_method_id: number,
    orderId: number,
    order_url?: string
  }, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.postPaymentMethod(arg);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const postPaymentInstrument = createAsyncThunk(
  'orderSlice/postPaymentInstrument',
  async (arg: { payment_instrument_id: number, orderId: number }, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.postPaymentInstrument(arg);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const postPerformActivity = createAsyncThunk(
  'orderSlice/postPerformActivity',
  async (arg: {
    resource_id: string,
    activity: string,
    resource: string,
  }, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.postPerformActivity(arg);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const fetchOrderById = createAsyncThunk(
  'orderSlice/fetchOrderById',
  async (orderId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const prom = await orderService.fetchOrderById(orderId);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const fetchTreatmentBySymptomId = createAsyncThunk(
  'orderSlice/fetchTreatmentBySymptomId',
  async (arg: { symptomId: number; country_id?: number | null }, { rejectWithValue, dispatch }) => {
    try {
      const prom = await treatmentService.fetchTreatmentBySymptomId(arg);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const postTreatment = createAsyncThunk(
  'orderSlice/postTreatment',
  async (treatmentArg: IPostTreatmentArgs, { rejectWithValue, dispatch }) => {
    try {
      const prom = await treatmentService.postTreatment(treatmentArg);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState: orderSliceInitialState,
  reducers: {
    logout() {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentInstruments.pending, pendingCase)
      .addCase(fetchPaymentInstruments.rejected, errorCase)
      .addCase(fetchPaymentInstruments.fulfilled, (state, { payload }) => {
        const { instruments, methodId } = payload as unknown as { instruments: IPaymentInstrument[], methodId: number };
        state.paymentInstruments[methodId] = instruments;
        state.loading = false;
      });
    builder.addCase(createOrderByAppointmentId.pending, pendingCase)
      .addCase(createOrderByAppointmentId.rejected, errorCase)
      .addCase(createOrderByAppointmentId.fulfilled, (state, { payload: order }: IActionArg<any>) => {
        state.currentOrder = order;
        gaService.order_created();
        state.loading = false;
      });
    builder.addCase(postDeliveryAddressIntoOrder.pending, pendingCase)
      .addCase(postDeliveryAddressIntoOrder.rejected, errorCase)
      .addCase(postDeliveryAddressIntoOrder.fulfilled, (state, { payload: order }: IActionArg<any>) => {
        state.currentOrder = order;
        gaService.delivery_address_selected();
        state.loading = false;
      });
    builder.addCase(fetchPaymentMethods.pending, pendingCase)
      .addCase(fetchPaymentMethods.rejected, errorCase)
      .addCase(fetchPaymentMethods.fulfilled, (state, { payload }: IActionArg<IPaymentMethodItem[]>) => {
        state.paymentMethods = payload;
        state.loading = false;
      });
    builder.addCase(postPaymentMethod.pending, pendingCase)
      .addCase(postPaymentMethod.rejected, errorCase)
      .addCase(postPaymentMethod.fulfilled, (state, { payload: order }: IActionArg<IOrderInstance>) => {
        state.currentOrder = order;
        gaService.payment_method_selected();
        state.loading = false;
      });
    builder.addCase(postPaymentInstrument.pending, pendingCase)
      .addCase(postPaymentInstrument.rejected, errorCase)
      .addCase(postPaymentInstrument.fulfilled, (state, { payload: order }: IActionArg<IOrderInstance>) => {
        state.currentOrder = order;
        state.loading = false;
      });
    builder.addCase(postPerformActivity.pending, pendingCase)
      .addCase(postPerformActivity.rejected, errorCase)
      .addCase(postPerformActivity.fulfilled, (state, { payload }: IActionArg<IOrderInstance>) => {
        state.currentOrder = payload;
        state.loading = false;
      });
    builder.addCase(fetchOrderById.pending, pendingCase)
      .addCase(fetchOrderById.rejected, errorCase)
      .addCase(fetchOrderById.fulfilled, (state, { payload }: IActionArg<IOrderInstance>) => {
        state.currentOrder = payload;
        state.loading = false;
      });
    builder.addCase(fetchTreatmentBySymptomId.pending, pendingCase)
      .addCase(fetchTreatmentBySymptomId.rejected, errorCase)
      .addCase(fetchTreatmentBySymptomId.fulfilled, (state, { payload }: IActionArg<ITreatment[]>) => {
        state.treatments = payload;
        state.loading = false;
      });
    builder.addCase(postTreatment.pending, pendingCase)
      .addCase(postTreatment.rejected, errorCase)
      .addCase(postTreatment.fulfilled, (state) => {
        gaService.treatment_preference_submitted();
        state.loading = false;
      });
  },
});

export const { logout } = orderSlice.actions;

export default orderSlice.reducer;
