const authEndpoints = {
  LOGIN: '/auth/mobile',
  SEND_SMS: '/auth/send_sms',
};

const profileEndpoints = {
  PROFILE: 'profile',
};
const questionnaireEndpoints = {
  FETCH_BY_SYMPTOM_ID: 'symptoms',
};

export const apiEndpoints = {
  ...authEndpoints,
  ...profileEndpoints,
  ...questionnaireEndpoints,
};
