import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  GoogleMap, InfoWindow, Marker, StreetViewService, useJsApiLoader,
} from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MapInfoWindow from '../MapInfoWindow';
import clearIcon from '../../images/Icon material-cancel.svg';
import featherMapIcon from '../../images/Icon feather-map-pin.svg';
import arrowMapIcon from '../../images/Icon feather-arrow-left.svg';
import pinDropIcon from '../../images/Icon material-pin-drop.svg';
import { MapModalVariant } from '../../../../types/address';
import { useSelector } from '../../../../store';
import { getDirection } from '../../../../store/selectors/user';

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const containerStyle = {
  width: '100%',
  height: '100%',
};
const AutocompleteWrap = styled(Box)(
  ({ theme }) => `
  padding: 0 26px;
  & #gmap_autocomplete_input {
    font-family: ${theme.direction === 'ltr' ? 'Inter, sans-serif' : 'Noto Kufi Arabic, sans-serif;'};
  }
  `,
);

const AutocompleteBox = styled(Box)`
  position: relative;
  margin-bottom: 0.625rem;

  input {
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: inline-block;
    width: 100%;
    height: 45px;
    padding: 14.1px 40px 12px 51px;
    border-radius: 6px;
    border: solid 1px #d9d9d9;
  }

  input::placeholder {
    color: #b5b5b5;
    font-weight: normal;
  }

  input:focus, input:hover {
    outline: none;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.7%;
  }
`;

const AutocompleteFeatherIcon = styled('img')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
`;

const AutocompleteArrowIcon = styled('img')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
`;

interface Props {
  coords?: google.maps.LatLngLiteral;
  withInfo?: boolean;
  getPlace?: (newPlace: google.maps.places.PlaceResult, ref: React.RefObject<HTMLInputElement>) => void;
  address?: string;
  onReset?: (e: MouseEvent) => void;
  withAutocomplete?: boolean;
  variant?: MapModalVariant;
  onAutocompleteChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  setCoords: Dispatch<SetStateAction<google.maps.LatLngLiteral>>;
}

export const defaultMapCoords = { lat: 24.560810028696547, lng: 46.614284634590156 };

const MapComponent: FC<Props> = ({
  coords,
  withInfo = false,
  getPlace,
  address,
  onReset = () => {},
  withAutocomplete = false,
  variant,
  onAutocompleteChange,
  setCoords,
}) => {
  const { t } = useTranslation();
  const [libraries] = useState<Libraries>(['places', 'geometry', 'localContext', 'visualization', 'drawing']);
  const [gmap, setGmap] = useState<google.maps.Map | null>(null);
  const [dragCoords, setDragCoords] = useState<google.maps.LatLngLiteral | null>(null);
  const timeoutToCancel = useRef<ReturnType<typeof setTimeout>[]>([]);

  // ! selectors
  const direction = useSelector(getDirection);

  // ! memos
  const centerWindow = useMemo(() => {
    let coordsToSpread: google.maps.LatLngLiteral | null | undefined = dragCoords;
    if (!coordsToSpread) coordsToSpread = coords;
    if (!coordsToSpread) return { lat: 0, lng: 0 };
    return {
      ...coordsToSpread,
      lat: coordsToSpread.lat + 0.00015,
    };
  }, [coords, dragCoords]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAP_API_KEY || '',
    libraries,
  });

  // ! handlers
  const onLoad = useCallback((map: google.maps.Map) => {
    setGmap(map);

    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  }, []);
  const onUnmount = useCallback(() => {}, []);
  const onLoadStreet = (streetViewService: google.maps.StreetViewService | null) => {
    streetViewService?.getPanorama({
      location: coords,
      radius: 50,
    }, (data, status) => console.log(
      'StreetViewService results',
      { data, status },
    ));
  };
  const onDrag = () => {
    if (!gmap) return;
    const centerInstance = gmap.getCenter();
    if (!centerInstance) return;
    const centerLatLng: google.maps.LatLngLiteral = {
      lat: centerInstance.lat(),
      lng: centerInstance.lng(),
    };
    setDragCoords(centerLatLng);
  };
  const onDragEnd = () => {
    if (!dragCoords) return;
    setCoords(dragCoords);
  };

  // ! effects
  useEffect(() => {
    if (!gmap) return;
    if (coords?.lat) {
      setDragCoords(null);
      timeoutToCancel.current.push(setTimeout(() => gmap.setCenter(coords), 1000));
      return;
    }
    if (variant === 'edit') return;
    window.navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const latLng: google.maps.LatLngLiteral = { lat: latitude, lng: longitude };
      setCoords(latLng);
    }, () => {
      timeoutToCancel.current.push(setTimeout(() => gmap.setZoom(5), 1000));
      setCoords(defaultMapCoords);
    });
  }, [coords, gmap]);
  useEffect(() => () => {
    timeoutToCancel.current.forEach((id) => clearTimeout(id));
  }, []);
  useEffect(() => {
    if (!gmap) return;
    if (gmap.getZoom() !== 18) timeoutToCancel.current.push(setTimeout(() => gmap.setZoom(18), 1000));
  }, [gmap]);

  // ! render
  if (!isLoaded) return null;
  return (
    <>
      {withAutocomplete && (
        <AutocompleteWrap>
          <AutocompleteBox>
            <Autocomplete
              dir={direction}
              id="gmap_autocomplete_input"
              placeholder={t('googleMap.searchLocation.autocompletePlaceholder')}
              apiKey={process.env.REACT_APP_GMAP_API_KEY}
              onPlaceSelected={getPlace}
              onChange={onAutocompleteChange}
              defaultValue={address || ''}
              options={{
                types: ['establishment'],
                fields: ['place_id', 'geometry', 'name', 'formatted_address'],
              }}
              className="pac-target-input"
            />
            {address
              ? <AutocompleteArrowIcon src={arrowMapIcon} alt="" />
              : <AutocompleteFeatherIcon src={featherMapIcon} alt="" />}
            <IconButton onClick={(e: any) => onReset(e as MouseEvent)}>
              <img
                src={clearIcon}
                alt="reset address"
                title={t('googleMap.searchLocation.cleanButtonTitle')}
              />
            </IconButton>
          </AutocompleteBox>
        </AutocompleteWrap>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{ maxZoom: 30 }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapTypeId="roadmap"
          onDrag={onDrag}
          onDragEnd={onDragEnd}
        >
          <StreetViewService
            onLoad={onLoadStreet}
          />
          {withInfo
            && (
              <InfoWindow position={centerWindow}>
                <MapInfoWindow />
              </InfoWindow>
            )}
          <Marker position={dragCoords || coords || defaultMapCoords} icon={pinDropIcon} />
        </GoogleMap>
      </Box>
    </>
  );
};

export default MapComponent;
