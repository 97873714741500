import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import pin from '../../assets/images/pin.png';
import map from '../../assets/images/map.png';

const sxStyles = {
  imagesBox: {
    position: 'relative',
    marginBottom: '1.5rem',
  },
  containerBox: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

interface Props {
  onSkipClick: () => void;
  onAddClick: () => void;
}
/**
Сomponent displays information about the absence of addresses, buttons for adding an address and skipping the
current step
*/
const NoAddressScreen: FC<Props> = ({ onSkipClick, onAddClick }) => {
  const { t } = useTranslation();
  const {
    imagesBox,
    containerBox,
  } = sxStyles;
  return (
    <Box sx={containerBox}>
      <Box sx={imagesBox}>
        <img src={pin} alt="" />
        <img
          style={{
            position: 'absolute',
            top: '13%',
            right: '1.13rem',
          }}
          src={map}
          alt=""
        />
      </Box>
      <Typography variant="subtitle1" mb="1.5rem" fontWeight={600}>
        {t('delivery.addAddress.noAddresses')}
      </Typography>
      <Button
        onClick={onAddClick}
        variant="contained"
        color="primary"
        sx={{ marginBottom: '1.5rem', fontWeight: 600 }}
      >
        {t('buttons.addAddress')}
      </Button>
      <Typography variant="subtitle2">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <small
          style={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '1.4rem',
            cursor: 'pointer',
            color: '#707070',
            fontSize: '14px',
          }}
          onClick={onSkipClick}
        >
          {t('buttons.skip')}
        </small>
        <small style={{ display: 'block', textAlign: 'center', fontWeight: 400 }}>
          {t('delivery.addAddress.skip')}
        </small>
      </Typography>
    </Box>
  );
};

export default NoAddressScreen;
