export const TRANSLATIONS_EN = {
  buttons: {
    start: 'Start',
    next: 'Next',
    previous: 'Previous',
    resendOtp: 'Resend OTP',
    wrongNumber: 'Entered a wrong number ?',
    verify: 'Verify',
    addNewAddress: 'Add new +',
    saveAndContinue: 'Save & Continue',
    showAllTreatments: 'See all treatments',
    placeRequest: 'Place Request',
    addAddress: 'Add Address',
    skip: 'Skip',
    submit: 'Submit',
    toggleLanguage: 'العربية',
  },
  progressBar: {
    questionnaire: 'Questionnaire',
    delivery: 'Delivery',
    checkout: 'Checkout',
  },
  home: {
    briefQuestionnaire: 'Please complete our brief health questionnaire. This should only take 2-4 minutes.',
    doctorsReview: 'Our board-certified doctors will review and assess your eligibility for pharmaceutical therapy.',
    healthInformation: 'Your health information will always remain confidential and 100% encrypted.',
  },
  questionnaire: {
    progressComplete: 'Complete',
    textareaPlaceholder: 'Type your response here',
  },
  phoneLogIn: {
    title: 'Phone number verification',
    phoneInputLabel: 'Phone Number',
  },
  otpLogIn: {
    instructions: 'Please enter the One-Time Password that has been sent to',
    inputLabel: 'One Time Password',
  },
  moreDetailsForm: {
    title: 'Just a few more details',
    name: 'Name',
    email: 'Email',
    gender: 'Gender',
    birthDate: 'Date of birth',
    male: 'Male',
    female: 'Female',
  },
  delivery: {
    selectTitle: 'Select your delivery address',
    hasAddress: {
      welcomeTitle: 'Welcome, ',
      savedAddresses: 'Saved addresses',
    },
    addAddress: {
      noAddresses: 'No addresses added yet!',
      skip: 'You may skip this step if you would like our Delivery Team to get in touch with you.',
    },
  },
  googleMap: {
    searchLocation: {
      searchLocationTitle: 'Search Location',
      autocompletePlaceholder: 'Enter a building or street name…',
      cleanButtonTitle: 'clean address',
    },
    editLocation: {
      editAddressTitle: 'Edit Address',
      mapInfoPopup: 'Your order will be delivered here. Drag and adjust the map for accurate location',
      home: 'Home',
      office: 'Office',
      other: 'Other',
      detailsInputPlaceholder: 'flat number/building name/gate number...',
      searchInputLabel: 'Address',
      closeAlt: 'close',
    },
  },
  treatment: {
    treatmentTitle: 'Select a preferred treatment',
    recommendedTitle: 'Recommended for you:',
    otherTreatments: 'Other treatments',
  },
  treatmentPlan: {
    treatmentPlanTitle: 'Select a treatment plan',
  },
  checkout: {
    checkoutTitle: 'Checkout',
    securelyLoggedIn: 'You are securely logged in',
    deliveryDetails: 'Delivery Details',
    selectPaymentMethod: 'Select Payment Method',
    orderSummary: 'Order Summary',
    items: 'Items',
    privacyPolicyCheck: 'I agree to Sihaty’s Terms & Conditions and Privacy Policy and Consent to Telehealth',
    totalSum: 'Total',
  },
  thankYou: {
    thankYouTitle: 'Thank you!',
    // eslint-disable-next-line max-len
    thankYouSubtitle: 'Your medication will be delivered to the mentioned address after our medical team reviews your request. In case your request was NOT approved by a doctor, the full amount will be refunded to your account.',
    installApp: 'Install the Sihaty app to track your request',
  },
  errors: {
    unexpectedError: 'Unexpected error',
    noCurrentAppointmentId: 'Can not find current appointment id',
    cantFetchQuestions: 'Can`t load questions',
    cantCreateOrder: 'Can`t create order by appointment id',
    cantCreateAppointment: 'Can not create an appointment',
    cantFindAnswers: 'Can not find user answers',
    logIn: 'Log In Please',
    cantFindSymptom: 'Can`t find current symptom',
  },
  notifications: {
    wrongCode: 'Can`t verify this code. It could be wrong.',
    emailIsRequired: 'Email is required',
    nameIsRequired: 'Name is required',
    fillProfile: 'Fill additional info please.',
  },
};
