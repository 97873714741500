import { useSearchParams } from 'react-router-dom';

const useAppLocale = () => {
  const [urlSearchParams, setSearchParams] = useSearchParams();

  const getLocaleFlow = () => {
    let locale = urlSearchParams.get('locale');
    const localeFromStorage = localStorage.getItem('locale');

    if (!locale) locale = localeFromStorage || 'en';

    if (localeFromStorage !== locale) localStorage.setItem('locale', locale);

    return locale;
  };

  const setLocaleFlow = (updatedLocale: string) => {
    urlSearchParams.set('locale', updatedLocale);
    setSearchParams(urlSearchParams);
  };
  return {
    getLocaleFlow,
    setLocaleFlow,
  };
};

export default useAppLocale;
