import { customErrors } from './errorObject';

class CustomError extends Error {
  constructor(message: `${customErrors}`) {
    super(message);
    this.name = 'CustomError';
  }
}

export default CustomError;
