import httpService from '../httpService';
import { apiEndpoints } from './apiEndpoints';
import { IServiceMethodFunc } from '../types/services';

interface IQuestionnaireService {
  fetchSymptomDataById: IServiceMethodFunc<string | number>;
}

const questionnaireService: IQuestionnaireService = {
  fetchSymptomDataById: (symptomId) => httpService.get(`${apiEndpoints.FETCH_BY_SYMPTOM_ID}/${symptomId}`),
};

export default questionnaireService;
