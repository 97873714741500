import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_AR } from './ar/translations';

// eslint-disable-next-line max-len
// ! https://lokalise.com/blog/how-to-internationalize-react-application-using-i18next/#Why_is_i18next_better_than_other_libraries

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  ar: {
    translation: TRANSLATIONS_AR,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
