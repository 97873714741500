import i18n from 'i18next';

// eslint-disable-next-line no-shadow
export enum customNotifications {
  EMAIL_IS_REQUIRED = 'EMAIL_IS_REQUIRED',
  NAME_IS_REQUIRED = 'NAME_IS_REQUIRED',
  WRONG_CODE = 'WRONG_CODE',
  FILL_PROFILE = 'FILL_PROFILE',
}

const createNotificationObj = () => {
  // ? GENERAL NOTIFICATIONS
  const generalNotifications = {
    [customNotifications.WRONG_CODE]: i18n.t('notifications.wrongCode'),
  };
  // ? VALIDATION NOTIFICATIONS
  const validationNotifications = {
    [customNotifications.EMAIL_IS_REQUIRED]: i18n.t('notifications.emailIsRequired'),
    [customNotifications.NAME_IS_REQUIRED]: i18n.t('notifications.nameIsRequired'),
  };
  // ? PROFILE NOTIFICATIONS
  const profileNotifications = {
    [customNotifications.FILL_PROFILE]: i18n.t('notifications.fillProfile'),
  };
  return {
    ...generalNotifications,
    ...validationNotifications,
    ...profileNotifications,
  };
};

// eslint-disable-next-line import/no-mutable-exports
export let notificationObject = createNotificationObj();

i18n.on('languageChanged', () => {
  notificationObject = createNotificationObj();
});
