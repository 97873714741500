import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import {
  Box, Button, Paper, SelectChangeEvent, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useTranslation } from 'react-i18next';
import CheckCircle from '../../assets/icons/CheckCircle';
import CustomInput from '../common/CustomInput';
import CustomSelect from '../common/CustomSelect';
import CustomDatePicker from '../common/CustomDatePicker';
import { StringSelectOption } from '../../types/common';

const useStyles = makeStyles((theme: Theme) => ({
  paperStyles: {
    minWidth: '350px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width:600px)': {
      maxWidth: '100%',
      minWidth: 0,
      margin: '0 20px',
    },
  },
  formStyles: {
    '& svg': {
      color: theme.palette.primary.dark,
    },
  },
  mainSvgWrapper: {
    '& svg': {
      width: '30px',
    },
  },
}));

export interface IMoreDetailsForm {
  name: string;
  gender: string;
  email: string;
  birth: Date;
}

interface Props {
  onSubmit: (userInfo: IMoreDetailsForm) => void;
  existingDetails: IMoreDetailsForm | null;
}
/**
Form for collecting information about the user during registration
*/
const MoreDetails: FC<Props> = ({ onSubmit, existingDetails }) => {
  const { paperStyles, formStyles, mainSvgWrapper } = useStyles();
  const [moreDetailsForm, setMoreDetailsForm] = useState({
    name: '',
    gender: 'M',
    email: '',
    birth: new Date(),
  });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    name: '',
    email: '',
  });
  const { t } = useTranslation();
  const selectGenderOptions: StringSelectOption[] = [
    {
      label: t('moreDetailsForm.male'),
      value: 'M',
    },
    {
      label: t('moreDetailsForm.female'),
      value: 'F',
    },
  ];

  // ! handlers
  const handleDateChange = (newValue: Date | null) => {
    if (!newValue) return;
    setMoreDetailsForm((prevState) => ({
      ...prevState,
      birth: newValue,
    }));
  };
  const isValidEmail = (value: string) => {
    // eslint-disable-next-line max-len
    const rule = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rule.test(value);
  };
  const validateDetailsForm = (): boolean => {
    if (!moreDetailsForm.name) {
      setFormErrors({
        name: !moreDetailsForm.name ? t('notifications.nameIsRequired') : '',
      });
      return false;
    }
    const isEmail = isValidEmail(moreDetailsForm.email);
    if (moreDetailsForm.email && !isEmail) {
      setFormErrors((prev) => ({
        ...prev,
        email: t('notifications.emailIsRequired'),
      }));
      return false;
    }
    return true;
  };
  const onFormSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateDetailsForm()) return;
    onSubmit(moreDetailsForm);
  };
  const onInputFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    setMoreDetailsForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue,
    }));
  };
  const onSelectChange = (event: SelectChangeEvent) => {
    setMoreDetailsForm((prevState) => ({
      ...prevState,
      gender: event.target.value,
    }));
  };

  // ! effects
  useEffect(() => {
    if (!existingDetails) return;
    setMoreDetailsForm(existingDetails);
  }, [existingDetails]);

  // ! render
  return (
    <Paper
      elevation={0}
      className={paperStyles}
    >
      <Box className={mainSvgWrapper}>
        <CheckCircle />
      </Box>
      <Typography variant="h6" color="primary" mb="1rem">
        {t('moreDetailsForm.title')}
      </Typography>
      <form
        onSubmit={onFormSubmit}
        className={[formStyles, 'flex-column-center'].join(' ')}
      >
        <CustomInput
          onInputChange={onInputChange}
          value={moreDetailsForm.name}
          svgIcon={<PersonOutlineIcon />}
          name="name"
          onFocus={onInputFocus}
          error={formErrors.name}
          label={t('moreDetailsForm.name')}
          placeholder="John Doe"
        />
        <CustomSelect
          onSelectChange={onSelectChange}
          options={selectGenderOptions}
          value={moreDetailsForm.gender}
          name="gender"
          svgIcon={<AssignmentIndIcon />}
        />
        <CustomInput
          error={formErrors.email}
          onInputChange={onInputChange}
          value={moreDetailsForm.email}
          name="email"
          svgIcon={<MailOutlineIcon />}
          label={t('moreDetailsForm.email')}
          onFocus={onInputFocus}
          placeholder="johndoe@gmail.com"
        />
        <CustomDatePicker
          svgIcon={<DateRangeIcon />}
          handleAccept={handleDateChange}
          existingValue={moreDetailsForm.birth}
        />
        <Button
          type="submit"
          variant="contained"
          color="success"
          sx={{ marginTop: '2.5rem', marginBottom: '1rem' }}
        >
          {t('buttons.submit')}
        </Button>
      </form>
    </Paper>
  );
};

export default MoreDetails;
