import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import './translations/i18n';
import App from './App';
import store from './store';
import './index.css';
import AuthProvider from './navigation/AuthProvider';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
