import { TNormalizedDataValue } from '../../types/questionnaire';
import { IAnswer } from '../../types/common';

/**
The function determines the presence of the field is_exclusive in the answer option
*/
export const isDisableOthersVariant = (variantId: number, normalizedData?: { [p: number]: TNormalizedDataValue }) => {
  if (!normalizedData) return false;

  const answerInstance = normalizedData[variantId];

  return (answerInstance as IAnswer).is_exclusive;
};
