import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import CardIconLine from './CardIconLine';
import { IAddress } from '../../../types/user';

interface Props {
  onAddrEdit: () => void;
  currentAddress: IAddress | null;
}

const DeliveryAddressSection: FC<Props> = ({
  onAddrEdit,
  currentAddress,
}) => {
  if (!currentAddress) {
    return (
      <Button
        variant="outlined"
        sx={{
          paddingY: '0.1rem',
        }}
        onClick={onAddrEdit}
      >
        + Add Address
      </Button>
    );
  }
  return (
    <CardIconLine onIconClick={onAddrEdit} maxWidth="95%">
      <Typography variant="body1" fontWeight="500" mr="0.3rem">{currentAddress?.name}</Typography>
      <Typography
        variant="caption"
        noWrap
        lineHeight={2.26}
        mr="0.3rem"
        fontWeight="400"
      >
        {`${currentAddress?.address_line1 || ''} ${currentAddress?.address_line2 || ''}`}
      </Typography>
    </CardIconLine>
  );
};

export default DeliveryAddressSection;
