import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useStyles } from './customInputStyles';
import { INormalizedAnswer } from '../../types/questionnaire';
import AnswerDescriptionField from './AnswerDescriptionField';

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formValue: string;
  answers: INormalizedAnswer[];
  onDescChange: ({ text, answerId } : { text: string; answerId: number | string }) => void;
}

const OneAnswerQuestionType: FC<Props> = ({
  handleChange,
  formValue,
  answers,
  onDescChange,
}) => {
  const { formLabel, checkedLabel } = useStyles();

  return (
    <FormControl>
      <RadioGroup
        value={formValue}
        onChange={handleChange}
      >
        {answers.map((answerItem) => {
          const itemChecked = formValue === `${answerItem.id}`;
          return (
            <React.Fragment key={answerItem.id}>
              <FormControlLabel
                sx={{ maxWidth: '407px', paddingRight: '1.5rem' }}
                checked={itemChecked}
                value={`${answerItem.id}`}
                control={(<Radio checked={itemChecked} />)}
                label={answerItem.name}
                className={[formLabel, itemChecked ? checkedLabel : ''].join(' ')}
              />
              {(answerItem.describable && itemChecked)
                && (
                  <AnswerDescriptionField
                    answerItem={answerItem}
                    onDescChange={onDescChange}
                  />
                )}
            </React.Fragment>
          );
        })}

      </RadioGroup>
    </FormControl>
  );
};

export default OneAnswerQuestionType;
