import React, {
  ChangeEvent, FC, ReactNode, useRef,
} from 'react';
import {
  Box, InputAdornment, TextField, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from '../../store';
import { getDirection } from '../../store/selectors/user';

const useStyles = makeStyles((theme: Theme) => ({
  inputStyles: {
    marginBottom: '1rem!important',
    '&:after': {
      content: '""',
      width: '36px',
      height: '30px',
      position: 'absolute',
      top: '18px',
      left: theme.direction === 'ltr' ? '1px' : 'calc(100% - 37px)',
      background: '#eaf0f2',
      zIndex: 0,
    },
    '& .MuiInputAdornment-root': {
      zIndex: 1,
      paddingLeft: '5px',
      paddingRight: theme.direction === 'ltr' ? '0' : '5px',
    },
    '& .MuiInput-root': {
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: '4px',
    },
    '& .MuiInputLabel-root': {
      color: '#333333',
      fontSize: '18px',
      top: '-3px!important',
    },
    '& .MuiInputBase-root:after': {
      border: 'none!important',
    },
    '& .MuiInput-input': {
      paddingLeft: '5px',
    },
    '& .MuiInputBase-root:before': {
      border: 'none!important',
    },
    '& .MuiInputAdornment-root:after': {
      content: '',
      position: 'absolute',
      width: '30px',
      height: '30px',
      top: 0,
      right: 0,
      background: 'red',
    },
    '&.MuiFormControl-root': {
      width: '100%',
    },
  },
}));

interface Props {
  error?: string;
  onFocus?: (name: string) => void;
  label: string;
  placeholder: string;
  svgIcon: ReactNode;
  name: string;
  value: string;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: FC<Props> = ({
  placeholder,
  label,
  svgIcon,
  name,
  value,
  onInputChange,
  error,
  onFocus = () => {},
}) => {
  const { inputStyles } = useStyles();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const direction = useSelector(getDirection);

  // ! handlers
  const onIconClick = () => {
    if (!inputRef.current) return;
    const input = inputRef.current?.querySelector('input');
    if (!input) return;
    input.focus();
  };

  // ! render
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TextField
        dir={direction}
        name={name}
        placeholder={placeholder}
        className={inputStyles}
        label={label}
        value={value}
        onChange={onInputChange}
        error={!!error}
        onFocus={(e) => onFocus(e.target.name)}
        InputProps={{
          ref: inputRef,
          startAdornment: (
            <InputAdornment position="start" onClick={onIconClick}>
              {svgIcon}
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      {error && (
        <Typography
          variant="caption"
          color="red"
          sx={{
            position: 'absolute',
            bottom: -1,
            left: 0,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default CustomInput;
