/* eslint-disable react/destructuring-assignment,react/jsx-props-no-spreading */
import * as React from 'react';
import { FC } from 'react';
import SelectUnstyled, { SelectUnstyledProps } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const blue = {
  main: '#cddce9',
  dark: '#b6cadb',
  100: '#daecff',
  900: '#003a75',
};

const grey = {
  100: '#e7ebf0',
  300: '#cdd2d7',
  400: '#b2bac2',
  900: '#1a2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-size: 18px;
  box-sizing: border-box;
  height: 100%;
  border: none;
  min-width: 4.5rem;
  /*@noflip*/
  padding-left: 2px;
  text-align: center;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  background-color: ${blue.main};
  min-height: 3.4rem;
  /*@noflip*/
  margin-left: 0.07rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${blue.dark};
  }
  & .MuiTypography-root:first-of-type {
    display: none;
  }
  &:after {
    content: "";
    border: solid ${theme.palette.primary.main};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    margin-right: ${theme.direction === 'rtl' ? '5px' : '3px'};
    transform: ${theme.direction === 'ltr' ? 'rotate(45deg)' : 'rotate(-45deg)'};
    margin-bottom: 3px;

  }
  &[aria-expanded="true"]:after {
    transform: ${theme.direction === 'ltr' ? 'rotate(-135deg)' : 'rotate(135deg)'};
    margin-bottom: 0px;
  }
  & .prefix-span {
    font-family: ${theme.direction === 'ltr' ? 'Inter, sans-serif' : 'Noto Kufi Arabic, sans-serif;'};
    font-weight: 500;
  }
  `,
);

const StyledListbox = styled('ul')`
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  width: 290px;
  max-width: 100%;
  max-height: 10rem;
  background: #fff;
  border: 1px solid ${grey[300]};
  border-radius: 0.75em;
  color: ${grey[900]};
  overflow: auto;
  outline: 0;
`;
const StyledOption = styled(OptionUnstyled)`
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  max-width: 100%;
`;
const StyledName = styled(Typography)`
  display: inline;
`;

const CustomSelect = (props: SelectUnstyledProps<string>) => {
  const components: SelectUnstyledProps<string>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
};

interface Props {
  phonePrefixes: { prefix: string; name: string }[];
  selectedPrefix: string | null;
  onChange: (value: string | null) => void;
}

const AuthSelectAdornment: FC<Props> = ({ selectedPrefix, phonePrefixes, onChange }) => (
  <CustomSelect value={selectedPrefix} onChange={onChange}>
    {
      phonePrefixes.map((country) => (
        <StyledOption
          key={country.prefix}
          value={country.prefix}
        >
          <span className="prefix-span">{`+${country.prefix}`}</span>
          <StyledName title={country.name} noWrap>
            {country.name}
          </StyledName>
        </StyledOption>
      ))
    }
  </CustomSelect>
);
export default AuthSelectAdornment;
