import { IServiceMethodFunc } from '../types/services';
import httpService from '../httpService';
import { Country } from '../types/common';

interface ICountriesService {
  fetchCountries: IServiceMethodFunc<void, { countries: Country[] }>;
}

const countriesService: ICountriesService = {
  fetchCountries: () => httpService.get('countries'),
};

export default countriesService;
