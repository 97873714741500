export const TRANSLATIONS_AR = {
  buttons: {
    start: 'إبدأ',
    next: 'التالي',
    previous: 'السابق',
    resendOtp: 'ارسال الرمز السري مرة اخرى',
    wrongNumber: 'تم ادخال رقم غير صحيح',
    verify: 'تأكيد',
    addNewAddress: 'اضافة عنوان جديد',
    saveAndContinue: 'الحفظ و المتابعة',
    showAllTreatments: 'اظهار جميع العلاجات',
    placeRequest: 'انشاء طلب',
    addAddress: 'اضافة عنوان',
    skip: 'تخطي',
    submit: 'إرسال',
    toggleLanguage: 'English',
  },
  progressBar: {
    questionnaire: 'استبيان',
    delivery: 'توصيل',
    checkout: 'الدفع',
  },
  home: {
    briefQuestionnaire: 'يرجى تعبئة الاستبيان الصحي القصير، سيستغرق 2 - 4 دقائق',
    doctorsReview: 'سيقوم أطباؤنا المعتمَدون بمراجعة وتقييم أهليتك لتَلقي للعلاج ',
    healthInformation: 'ستبقى معلوماتك الصحية سرية 100٪.',
  },
  questionnaire: {
    progressComplete: 'إتمام',
    textareaPlaceholder: 'املأ إجابتك هنا',
  },
  phoneLogIn: {
    title: 'التحقق من رقم الهاتف',
    phoneInputLabel: 'رقم الهاتف',
  },
  otpLogIn: {
    instructions: 'الرجاء إدخال رمز التحقق التي تم إرساله إلى',
    inputLabel: 'رمز المرور الصالح لمره واحده',
  },
  moreDetailsForm: {
    title: 'تبقى القليل من البيانات',
    name: 'الاسم',
    email: 'البريد الإلكتروني',
    gender: 'النوع',
    birthDate: 'تاريخ الميلاد',
    male: 'ذكر',
    female: 'أنثى',
  },
  delivery: {
    selectTitle: 'أدخل العنوان  ',
    hasAddress: {
      welcomeTitle: 'أهلا وسهلاً, ',
      savedAddresses: 'العناوين المحفوظة',
    },
    addAddress: {
      noAddresses: 'لم يتم إضافة عناوين حتى الآن',
      skip: 'يمكنك تخطي هذه الخطوة إذا كنت ترغب في أن يتواصل معك فريق التوصيل',
    },
  },
  googleMap: {
    searchLocation: {
      searchLocationTitle: 'البحث عن العنوان',
      autocompletePlaceholder: 'أدخل اسم المبنى أو شارع',
      cleanButtonTitle: 'إزالة العنوان',
    },
    editLocation: {
      editAddressTitle: 'تعديل العنوان',
      mapInfoPopup: 'سيتم تسليم طلبنا هنا. اسحب الخريطة واضبطها للحصول على موقع دقيق',
      home: 'منزل',
      office: 'مكتب',
      other: 'آخر',
      detailsInputPlaceholder: 'رقم الشقة / اسم المبنى / رقم البوابة ...',
      searchInputLabel: 'العنوان',
      closeAlt: 'إغلاق',
    },
  },
  treatment: {
    treatmentTitle: 'اختر العلاج المفضل',
    recommendedTitle: 'الموصى به',
    otherTreatments: 'علاجات أخرى',
  },
  treatmentPlan: {
    treatmentPlanTitle: 'اختر خطة العلاج',
  },
  checkout: {
    checkoutTitle: 'الدفع',
    securelyLoggedIn: 'أنت مسجل الدخول بأمان',
    deliveryDetails: 'تفاصيل التوصيل',
    selectPaymentMethod: 'اختار طريقة الدفع',
    orderSummary: 'ملخص الطلب',
    items: 'العناصر',
    // eslint-disable-next-line max-len
    privacyPolicyCheck: 'أوافق على الشروط والأحكام وسياسة الخصوصية الخاصة بشركة صحتي،  أوافق على الخدمات الصحية عن بُعد',
    totalSum: 'الإجمالي',
  },
  thankYou: {
    thankYouTitle: 'شكرا',
    // eslint-disable-next-line max-len
    thankYouSubtitle: 'سيتم توصيل الدواء للعنوان الذي تم تحديدة بعد مراجعة طلبك من قبل فريقنا الطبي. في حال عدم صرف الدواء من قبل الطبيب سيتم ارجاع المبلغ بالكامل في حسابك',
    installApp: 'قم بتحميل تطبيق صحتي لمتابعة طلبك.',
  },
  errors: {
    unexpectedError: 'خطأ غير متوقع',
    noCurrentAppointmentId: 'لا يمكن العثور على هوية الموعد الحالي',
    cantFetchQuestions: 'لا يمكن تحميل الأسئلة',
    cantCreateOrder: 'لا يمكن إنشاء الطلب عن طريق معرف الموعد',
    cantCreateAppointment: 'لا يمكن إنشاء موعد',
    cantFindAnswers: 'لا يمكن العثور على إجابات المستخدم',
    logIn: 'الرجاء تسجيل الدخول',
    cantFindSymptom: 'لا يمكن العثور على الأعراض',
  },
  notifications: {
    wrongCode: 'لا يمكن التحقق من هذا الرمز. قد يكون الرمز خاطئ.',
    emailIsRequired: 'يتطلب ادخال البريد الالكتروني',
    nameIsRequired: 'يتطلب ادخال الاسم',
    fillProfile: 'برجاء ادخال معلومات اضافيه',
  },
};
