import React, { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PrevNextButton from './PrevNextButton';
import SkipQuestionButton from './SkipQuestionButton';

interface Props {
  onPrevClick: () => void;
  onSkipClick: () => void;
  onNextClick: () => void;
  prevButtonText?: string;
  nextButtonText?: string;
  hidePrevious?: boolean;
  canSkip?: boolean;
  nextDisabled: boolean;
}

const BoldBox = styled(Box)`
  & .MuiButton-root {
    font-weight: 600;
  }
`;

const PrevNextSection: FC<Props> = ({
  onPrevClick,
  onNextClick,
  hidePrevious,
  nextButtonText,
  prevButtonText,
  canSkip,
  onSkipClick,
  nextDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      {canSkip && <SkipQuestionButton onSkipClick={onSkipClick} />}
      <BoldBox>
        {!hidePrevious && (
          <PrevNextButton
            label={prevButtonText || `< ${t('buttons.previous')}`}
            color="primary"
            onClick={onPrevClick}
          />
        )}
        <PrevNextButton
          label={nextButtonText || `${t('buttons.next')} >`}
          color="success"
          onClick={onNextClick}
          disabled={nextDisabled}
        />
      </BoldBox>
    </Box>
  );
};

export default PrevNextSection;
