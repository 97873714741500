import { styled } from '@mui/material/styles';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import Box from '@mui/material/Box';

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(205, 220, 233, 0.7);
`;

export const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`;

export const ModalBox = styled(Box)`
  width: 100%;
  max-width: 605px;
  height: 95vh;
  overflow-y: auto;
  outline: none;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;
