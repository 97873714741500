import React, { FC, ReactNode } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, Theme, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StringSelectOption } from '../../types/common';
import { useSelector } from '../../store';
import { getDirection } from '../../store/selectors/user';

const useStyles = makeStyles((theme: Theme) => createStyles({
  selectAdornment: {},
  formControlStyles: {
    border: `1px solid ${theme.palette.primary.dark}!important`,
    borderRadius: '4px!important',
    width: '100%',

    '& .MuiSelect-select': {
      padding: '4px 0 4px 5px!important',
    },

    '& .MuiOutlinedInput-root': {
      paddingLeft: '5px!important',
      paddingRight: theme.direction === 'ltr' ? '0' : '5px',
    },

    '& .MuiInputAdornment-root': {
      zIndex: 1,
      paddingLeft: theme.direction === 'rtl' ? '5px' : '0',
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none!important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none!important',
    },

    '&:after': {
      content: '""',
      width: '36px',
      height: '30px',
      position: 'absolute',
      top: '1px',
      left: theme.direction === 'ltr' ? '1px' : 'calc(100% - 37px)',
      background: '#eaf0f2',
      zIndex: 0,
    },
  },
  selectWrapper: {
    width: '100%',
    marginBottom: '1.2rem!important',
    '@media (max-width:600px)': {
      width: '100%!important',
    },
  },
}));

interface Props {
  svgIcon: ReactNode;
  name: string;
  options: StringSelectOption[];
  value: StringSelectOption['value'];
  onSelectChange: (event: SelectChangeEvent) => void;
}

const CustomSelect: FC<Props> = ({
  svgIcon,
  name,
  options,
  value,
  onSelectChange,
}) => {
  const { selectAdornment, formControlStyles, selectWrapper } = useStyles();
  const direction = useSelector(getDirection);
  const { t } = useTranslation();
  return (
    <div className={selectWrapper}>
      <Typography textAlign="left" variant="body2">{t('moreDetailsForm.gender')}</Typography>

      <FormControl className={formControlStyles} dir={direction}>
        <Select
          name={name}
          value={value}
          onChange={onSelectChange}
          startAdornment={(
            <InputAdornment className={selectAdornment} position="start">
              {svgIcon}
            </InputAdornment>
          )}
        >
          {options.map((item) => (
            <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
  );
};

export default CustomSelect;
