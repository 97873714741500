import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import {
  Box, Button, Container, FormControl, RadioGroup, SxProps, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalWithMap from '../../components/GMapComponent/components/ModalWithMap';
import { IAddress } from '../../types/user';
import HasAddressItem from '../../components/Delivery/HasAddressItem';
import { EditAddressInfo, MapModalVariant } from '../../types/address';
import { useSelector } from '../../store';

const sxStyles: SxProps = {
  containerBox: {
    marginTop: '2rem',
    flexGrow: 1,
    display: 'flex!important',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 352px)',
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  radioContainer: {
    minHeight: '200px',
    overflowY: 'auto',
    padding: '0 1rem',
    marginBottom: '3rem',
    marginTop: '1rem',
  },
  nextButton: {
    bottom: '2rem',
  },
};

interface Props {
  onSubmit: (addressId: number) => void;
  userAddresses: IAddress[];
  onUpdateAddress: (addressToUpdate: IAddress) => void;
  onAddNewAddress: (modalInfo: EditAddressInfo) => void;
}
/**
The component displays the user's available addresses.
Controls adding and updating user addresses, opening a modal window with Google Maps
*/
const HasAddressScreen: FC<Props> = ({
  onSubmit,
  userAddresses,
  onUpdateAddress,
  onAddNewAddress,
}) => {
  const { t } = useTranslation();
  const [addressValue, setAddressValue] = useState<number | null>(null);
  const [editAddrModal, setEditAddrModal] = useState(false);
  const [addrToEdit, setAddrToEdit] = useState<IAddress | null>(null);
  const [mapModalVariant, setMapModalVariant] = useState<MapModalVariant>(null);
  // ! selectors
  const currentAddressId = useSelector((state) => state.address.currentAddressId);
  const appointmentLoading = useSelector<any | null>((state) => state.appointment.loading);
  const ordersLoading = useSelector((state) => state.orders.loading);

  // ! styles
  const {
    containerBox,
    formHeader,
    radioContainer,
    nextButton,
  } = sxStyles;

  // ! effects
  // set currentAddress ID or first address in the list
  useEffect(() => {
    if (currentAddressId) {
      setAddressValue(currentAddressId);
      return;
    }
    if (!userAddresses?.length) return;
    const firstAddress = userAddresses[0];
    const { id } = firstAddress;
    if (id) setAddressValue(id);
  }, [userAddresses, currentAddressId]);

  // ! handlers
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddressValue(Number.parseInt(event.target.value, 10));
  };
  const onNextClick = () => {
    if (!addressValue) return;
    onSubmit(addressValue);
  };
  const onEditAddressClick = (addressItem: IAddress) => {
    setAddrToEdit(addressItem);
    setEditAddrModal(true);
    setMapModalVariant('edit');
  };
  const updateAddress = (modalInfo: EditAddressInfo) => {
    if (!addrToEdit) return;
    const { addressForm: { search }, place, coords: { lng, lat } } = modalInfo;
    const addressToUpdate: IAddress = {
      ...addrToEdit,
      address_line1: search || addrToEdit?.address_line1 || '',
      lat: `${lat || addrToEdit?.lat || 0}`,
      long: `${lng || addrToEdit?.long || 0}`,
      name: place || addrToEdit.name || 'other',
    };
    onUpdateAddress(addressToUpdate);
  };

  const onModalSave = (modalInfo: EditAddressInfo) => {
    if (!modalInfo) return;
    if (mapModalVariant === 'edit') {
      updateAddress(modalInfo);
    } else {
      onAddNewAddress(modalInfo);
    }
    setEditAddrModal(false);
  };
  const onAddAddressClick = () => {
    setMapModalVariant('add');
    setEditAddrModal(true);
  };

  // ! effects
  useEffect(() => {
    if (editAddrModal) return;
    setAddrToEdit(null);
    setMapModalVariant(null);
  }, [editAddrModal]);

  // ! render
  return (
    <>
      {editAddrModal && (
        <ModalWithMap
          open={editAddrModal}
          setOpen={setEditAddrModal}
          onSave={onModalSave}
          addrToEdit={addrToEdit}
          variant={mapModalVariant}
        />
      )}
      <Container maxWidth="sm" sx={containerBox}>

        {/* FORM HEADER */}
        <Box sx={formHeader}>
          <Typography fontWeight="bold">{t('delivery.hasAddress.savedAddresses')}</Typography>
          <Typography
            sx={{ cursor: 'pointer' }}
            fontWeight="bold"
            onClick={onAddAddressClick}
          >
            {t('buttons.addNewAddress')}
          </Typography>
        </Box>

        {/* FORM RADIO */}
        <Box sx={radioContainer}>
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              value="Home"
              onChange={handleChange}
            >
              {userAddresses.map((addressItem) => (
                <HasAddressItem
                  key={addressItem.id}
                  addressItem={addressItem}
                  onIconClick={() => onEditAddressClick(addressItem)}
                  addressValue={addressValue}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        {/* FORM CONTROLS */}
        <Box className={[nextButton, 'flex-center'].join(' ')}>
          <Button
            disabled={appointmentLoading || ordersLoading}
            variant="contained"
            color="success"
            sx={{ paddingX: '2rem' }}
            onClick={onNextClick}
          >
            {t('buttons.next')}
          </Button>
        </Box>

      </Container>
    </>
  );
};

export default HasAddressScreen;
