/* eslint-disable no-param-reassign */

import { IDefaultStateFields } from '../types/store';

export const pendingCase = (state: IDefaultStateFields) => {
  state.loading = true;
};
export const errorCase = (state: IDefaultStateFields) => {
  state.loading = false;
};
