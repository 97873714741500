import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ToggleRight from '../RTL/ToggleRight';

const useStyles = makeStyles({
  imageContainer: {
    display: 'flex',
    marginBottom: '1.5rem',
    '@media (max-width:1056px)': {
      justifyContent: 'center',
    },
  },
  headerLogo: {
    marginLeft: '100px',
    marginRight: '100px',
    paddingTop: '10px',
    width: '150px',
    height: '53px',
    '@media (max-width:1056px)': {
      marginLeft: '0',
      marginRight: '0',
    },
    '@media (max-width:756px)': {
      width: '47px',
    },
  },
});

function Header() {
  const isCenteredLogo = useMediaQuery({ query: '(max-width: 756px)' });
  const { imageContainer, headerLogo } = useStyles({ isCenteredLogo });

  // ! render
  return (
    <Box className={imageContainer}>
      <img
        src={isCenteredLogo ? '/smallLogo.png' : '/sihatyLogo.png'}
        alt=""
        className={headerLogo}
      />
      <ToggleRight />
    </Box>
  );
}

export default Header;
