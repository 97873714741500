/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { VariantType } from 'notistack';
import i18n from 'i18next';
import { customErrors, errObject } from './errorObject';
import { notificationObject } from './notificationObject';
import { NotifyUserPayload } from './notifier.t';
import CustomError from './customErrorClass';
import { isAxiosError } from '../../../helpers/typeHelpers';

type TMessageType = {
  message: string;
  variant: VariantType;
  is401?: boolean;
};

interface IInitialNotifyState {
  errorMessage: TMessageType;
  notificationMessage: TMessageType;
  duration: number;
  anchorOrigin: {
    vertical: string;
    horizontal: string;
  };
}

// ! helpers
const getMessageFromError = (err: CustomError | AxiosError) => {
  let msg = '';
  if (isAxiosError(err)) msg = err.response?.data?.message || err.response?.data?.error;
  if (!msg) msg = err.message;
  return msg || customErrors.UNKNOWN_ERROR;
};

export const initialState: IInitialNotifyState = {
  errorMessage: { message: '', variant: 'error', is401: false },
  notificationMessage: { message: '', variant: 'success' },
  duration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

const notifierSlice = createSlice({
  name: 'notifierSlice',
  initialState,
  reducers: {
    handleError: (state, { payload }: { payload: CustomError | AxiosError }) => {
      console.log('handleError: ');
      console.dir(payload);
      const msg = getMessageFromError(payload);
      const errorFromObject = errObject[msg];

      if (!errorFromObject) {
        state.errorMessage = {
          message: msg || i18n.t('errors.unexpectedError'),
          variant: 'error',
        };
        return;
      }

      if (errorFromObject.message === '401') {
        state.errorMessage = {
          message: i18n.t('errors.logIn'),
          variant: 'error',
          is401: true,
        };
        return;
      }

      if (errorFromObject.duration) state.duration = errorFromObject.duration;

      state.errorMessage = {
        message: errorFromObject.message,
        variant: 'error',
      };
    },

    notifyUser: (state, { payload }: NotifyUserPayload) => {
      state.notificationMessage = {
        message: notificationObject[payload?.message],
        variant: payload?.variant || 'success',
      };
      state.duration = payload?.duration || 3000;
    },

    cleanNotifierMessages: (state) => {
      state.errorMessage = { message: '', variant: 'error', is401: false };
      state.notificationMessage = { message: '', variant: 'success' };
      state.duration = 3000;
    },
  },
});

export const { handleError, cleanNotifierMessages, notifyUser } = notifierSlice.actions;

export default notifierSlice.reducer;
