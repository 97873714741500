import React, { FC } from 'react';
import {
  Box, LinearProgress, SxProps, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const sxStyles: SxProps = {
  progressContainer: {
    height: '10px',
    width: '100%',
    marginBottom: '3rem',
  },
  progressLine: {
    height: '8px!important',
    borderRadius: '4px',
  },
};

interface Props {
  progress: number;
}

const QuestionnaireProgress: FC<Props> = ({ progress }) => {
  const { progressContainer, progressLine } = sxStyles;
  const { t } = useTranslation();
  return (
    <Box sx={progressContainer}>
      <LinearProgress variant="determinate" value={progress} sx={progressLine} />
      <Typography variant="caption" color="primary" fontWeight={500}>
        <span>{progress}</span>
        %
        {' '}
        {t('questionnaire.progressComplete')}
      </Typography>
    </Box>
  );
};

export default QuestionnaireProgress;
