import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '8px',
    border: `solid 1px ${theme.palette.secondary.light}`,
    padding: '0.9rem',
    margin: '1rem',
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
  },
}));

const FormCard: FC = ({ children }) => {
  const { card } = useStyles();
  return (
    <div className={card}>
      {children}
    </div>
  );
};

export default FormCard;
