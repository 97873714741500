import { ISelector } from '../../types/store';
import { IAddress } from '../../types/user';

export const getUserFullName: ISelector<void, string> = (store) => {
  const { userProfile } = store.user;
  if (!userProfile) return '';
  const { first_name, last_name } = userProfile;
  let fullName = '';
  if (first_name) fullName += `${first_name} `;
  if (last_name) fullName += `${last_name}`;
  return fullName || 'Hello';
};

export const getCurrentAddress: ISelector<void, IAddress | null> = (store) => {
  const { currentOrder } = store.orders;
  if (currentOrder?.delivery_address) return currentOrder?.delivery_address;

  const { currentAddressId, userAddresses } = store.address;
  if (!currentAddressId || !userAddresses?.length) return null;
  const address = userAddresses.find((addressItem) => addressItem.id === currentAddressId);
  return address || null;
};

export const getDirection: ISelector<void, 'ltr' | 'rtl'> = (store) => {
  const { user: { locale } } = store;
  return locale === 'en' ? 'ltr' : 'rtl';
};
