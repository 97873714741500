import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const sxStyles = {
  root: {
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    paddingBottom: '10vh',
  },
  containerStyles: {
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  storeImagesWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomWrap: {
    maxWidth: '300px',
  },
};

const storeImageStyles = {
  maxWidth: '150px',
  cursor: 'pointer',
};

const ThankYouPage = () => {
  const { t } = useTranslation();
  const {
    containerStyles, storeImagesWrap, bottomWrap, root,
  } = sxStyles;
  return (
    <Container maxWidth="md" sx={root}>
      <Box sx={containerStyles}>
        <Typography
          variant="h1"
          color="success.main"
          fontWeight="600"
          textAlign="center"
          mb="1rem"
          fontSize="36px"
        >
          {t('thankYou.thankYouTitle')}
        </Typography>
        <Typography variant="body1" textAlign="center" fontWeight="300">
          {t('thankYou.thankYouSubtitle')}
        </Typography>
        <img src="/deviceImage.png" alt="" style={{ height: '280px', margin: '1rem 0' }} />

        <Box sx={bottomWrap}>
          <Typography
            variant="h5"
            color="primary.main"
            fontWeight="600"
            textAlign="center"
            mb="1rem"
          >
            {t('thankYou.installApp')}
          </Typography>
          <Box sx={storeImagesWrap}>
            <img src="/GPay.png" alt="" style={storeImageStyles} />
            <img src="/AppStore.png" alt="" style={storeImageStyles} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ThankYouPage;
