import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { insertLocaleInConfig, insertTokenInConfig } from './helpers';
import store from '../store';
import { handleError } from '../store/slices/notifier/notifier';
import CustomError from '../store/slices/notifier/customErrorClass';
import { customErrors } from '../store/slices/notifier/errorObject';

if (!process.env.REACT_APP_API_ENDPOINT) throw new Error('REACT_APP_API_ENDPOINT not exists');

// https://github.com/axios/axios#creating-an-instance
const httpService = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// ! interceptors (https://github.com/axios/axios#interceptors)
// * request
const requestInterceptor = (config: AxiosRequestConfig) => {
  const isAuthRoute = config?.url?.includes('auth');
  if (isAuthRoute) return config;

  const token = localStorage.getItem('token');
  if (!token) return insertLocaleInConfig(config);

  const tokenizedConfig = insertTokenInConfig(config, token);
  return insertLocaleInConfig(tokenizedConfig);
};

// * response
const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const status = error.response?.status;
  if (status === 401) store.dispatch(handleError(new CustomError(customErrors.NOT_AUTH)));
  return Promise.reject(error);
};

httpService.interceptors.request.use(requestInterceptor);
httpService.interceptors.response.use(onResponse, onResponseError);

export default httpService;
