import React, { ChangeEvent, FC, useState } from 'react';
import axios from 'axios';
import LoginView from './LoginView';
import VerifyOtp from './VerifyOtpView';
import authService from '../../services/auth';
import BackdropLoad from '../../components/common/BackdropLoad';
import { useDispatch } from '../../store';
import { handleError, notifyUser } from '../../store/slices/notifier/notifier';
import { customNotifications } from '../../store/slices/notifier/notificationObject';
import { apiEndpoints } from '../../services/apiEndpoints';
import { gaService } from '../../helpers/googleAnalytics';

interface Props {
  submitAuth: () => void;
}

const AuthContainer: FC<Props> = ({ submitAuth }) => {
  const [phone, setPhone] = useState('');
  const [phonePrefix, setPhonePrefix] = useState('');
  const [step, setStep] = useState<'otp' | 'phone' | 'loading'>('phone');
  const dispatch = useDispatch();

  // ! handlers
  const handleSms = async (newPhonePrefix: string) => {
    if (!phone) return;
    setStep('loading');

    setPhonePrefix(newPhonePrefix);
    try {
      await authService.sendSms(`${newPhonePrefix}${phone}`);
      gaService.phone_submitted();
      setStep('otp');
    } catch (e: any) {
      setStep('phone');
      dispatch(handleError(e));
    }
  };
  const handleLogin = async (otp: string) => {
    setStep('loading');
    const fullPhone = `${phonePrefix}${phone}`;
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;

    try {
      const res = await axios.post(`${baseUrl}${apiEndpoints.LOGIN}`, { phone: fullPhone, sms: otp });
      if (res.data?.authorization) localStorage.setItem('token', res.data.authorization);
      gaService.signed_up();
      submitAuth();
    } catch (e: any) {
      dispatch(notifyUser({
        message: customNotifications.WRONG_CODE,
        variant: 'error',
      }));
      setStep('otp');
    }
  };
  const onWrongNumberEntered = () => {
    setStep('phone');
  };
  const onResendOtp = async () => {
    gaService.otp_resend_requested();
    setStep('loading');
    try {
      await authService.sendSms(`${phonePrefix}${phone}`);
      setStep('otp');
    } catch (e: any) {
      setStep('phone');
      dispatch(handleError(e));
    }
  };
  const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const replaced = value.replace(/[^0-9]/gi, '');
    if (replaced?.length > 9) return;
    setPhone(replaced);
  };

  // ! render
  const renderContent = React.useCallback(() => {
    switch (step) {
      case 'phone':
        return (
          <LoginView
            handleSms={handleSms}
            existingPhone={phone}
            existingPrefix={phonePrefix}
            onPhoneChange={onPhoneChange}
          />
        );
      case 'otp':
        return (
          <VerifyOtp
            handleLogin={handleLogin}
            phoneNumber={phone}
            onWrongNumberEntered={onWrongNumberEntered}
            onResendOtp={onResendOtp}
          />
        );
      case 'loading':
        return <BackdropLoad />;
      default:
        return <BackdropLoad />;
    }
  }, [step, phone, phonePrefix]);
  return <div>{renderContent()}</div>;
};

export default AuthContainer;
