import * as React from 'react';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import CreditCardWrap from './CreditCardWrap';
import CreditItem from './CreditItem';
import CardWithDeleteButton from './CardWithDeleteButton';
import { IPaymentInstrument } from '../../../../types/payment.t';

interface Props {
  creditCards: IPaymentInstrument[];
  selectedCardId: number | null;
  onSelectCard: (cardId: number) => void;
}

const PaymentDropdown: FC<Props> = ({
  creditCards,
  selectedCardId,
  onSelectCard,
}) => (
  <Box
    sx={{
      paddingLeft: '1.9rem',
      '@media all and (max-width: 400px)': {
        paddingLeft: '0',
      },
    }}
  >
    {/* EXISTING CARDS */}
    {
      creditCards.map((item) => {
        const { id, gateway_meta } = item;
        if (!gateway_meta) return null;
        const { source } = gateway_meta;
        if (!source) return null;
        const { last4, scheme } = source;
        if (!last4 || !scheme) return null;
        const isVisa = scheme === 'Visa';
        return (
          <CardWithDeleteButton key={item.id}>
            <CreditItem
              instrumentId={id}
              lastNums={last4}
              selectedCardId={selectedCardId}
              onRadioClick={onSelectCard}
              isVisa={isVisa}
            />
          </CardWithDeleteButton>
        );
      })
    }

    {/* ADD NEW EMPTY */}
    {!creditCards.length && (
      <Typography
        variant="caption"
        textAlign="left"
        color="success.main"
        fontWeight={500}
      >
        You don’t have any saved cards!
      </Typography>
    )}
    <CreditCardWrap onCardClick={() => {}}>
      <Typography variant="body1" textAlign="center" fontWeight={500}>Add new +</Typography>
    </CreditCardWrap>
  </Box>
);
export default PaymentDropdown;
