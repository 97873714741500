/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError } from './notifier/notifier';
import symptomsService from '../../services/symptoms';
import { IActionArg, IDefaultStateFields } from '../../types/store';
import { ISymptom } from '../../types/symptoms';
import CustomError from './notifier/customErrorClass';
import { customErrors } from './notifier/errorObject';

const redirectURL = process.env.REACT_APP_NO_SYMPTOM_REDIRECT_URL;
if (!redirectURL) throw new Error('can`t find REACT_APP_NO_SYMPTOM_REDIRECT_URL in .env');

interface ISymptomsSlice extends IDefaultStateFields {
  currentSymptom: ISymptom | null;
}

const symptomsSliceInitialState: ISymptomsSlice = {
  currentSymptom: null,
  loading: false,
};

export const fetchSymptomBySlug = createAsyncThunk(
  'symptomsSlice/fetchSymptomBySlug',
  async (symptomSlug: string, { rejectWithValue, dispatch }) => {
    try {
      const prom = await symptomsService.fetchSymptomBySlug(symptomSlug);
      if (!prom.data?.length) {
        dispatch(handleError(new CustomError(customErrors.CAN_NOT_FETCH_SYMPTOM)));
        window.location.href = redirectURL;
        return rejectWithValue([]);
      }
      return prom.data;
    } catch (e: any) {
      window.location.href = redirectURL;
      dispatch(handleError(new CustomError(customErrors.CAN_NOT_FETCH_SYMPTOM)));
      return rejectWithValue(e);
    }
  },
);

const symptomsSlice = createSlice({
  name: 'symptomsSlice',
  initialState: symptomsSliceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSymptomBySlug.pending, pendingCase)
      .addCase(fetchSymptomBySlug.rejected, errorCase)
      .addCase(fetchSymptomBySlug.fulfilled, (state, { payload }: IActionArg<ISymptom[]>) => {
        if (!payload?.length) {
          state.loading = false;
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        state.currentSymptom = payload[0];
        state.loading = false;
      });
  },
});

export default symptomsSlice.reducer;
