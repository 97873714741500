import httpService from '../httpService';
import { apiEndpoints } from './apiEndpoints';
import { IServiceMethodFunc } from '../types/services';

interface IAuthService {
  login: IServiceMethodFunc<{ phone: string, sms: any }, { authorization: string }>;
  sendSms: IServiceMethodFunc<string, void>;
}

const authService: IAuthService = {
  login: ({ phone, sms }) => httpService.post(apiEndpoints.LOGIN, { phone, sms }),
  sendSms: (phone) => httpService.post(apiEndpoints.SEND_SMS, { phone }),
};

export default authService;
