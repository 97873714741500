import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../store';

interface Props {
  handleOpen: () => void;
}

const DemiBoldBox = styled(Box)`
  & .MuiTypography-root {
    font-weight: 500;
  }
`;

const StartSection: FC<Props> = ({ handleOpen }) => {
  const { t } = useTranslation();
  // ! selectors
  const symptomId = useSelector((state) => state.symptoms.currentSymptom?.id);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <DemiBoldBox>
        <Typography variant="body1" marginBottom="1.5rem" fontSize="20px">
          {t('home.briefQuestionnaire')}
        </Typography>
        <Typography variant="body1" marginBottom="1.75rem" fontSize="20px">
          {t('home.doctorsReview')}
        </Typography>

        <Typography variant="body2" marginBottom="3rem" color="secondary" fontSize="20px">
          {t('home.healthInformation')}
        </Typography>
      </DemiBoldBox>
      <Button
        sx={{
          marginBottom: '1.5rem',
          paddingX: '2rem',
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="contained"
        color="primary"
        disabled={!symptomId}
        onClick={() => handleOpen()}
      >
        {t('buttons.start')}
      </Button>
    </Box>
  );
};

export default StartSection;
