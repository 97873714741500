import React, { Dispatch, SetStateAction, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Paper, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MapComponent from '../MapComponent';
import closeModalIcon from '../../images/Icon metro-cross.svg';
import { EditAddressInfo, MapModalVariant } from '../../../../types/address';
import { useSelector } from '../../../../store';
import { getDirection } from '../../../../store/selectors/user';

const inputStyles: SxProps = {
  borderColor: '#d9d9d9',
  height: '3rem',
  marginBottom: '1.5rem!important',
  '& .MuiOutlinedInput-root': {
    height: '3rem',
  },
  '& .MuiInputLabel-root': {
    lineHeight: '1.1375em!important',
  },
};

interface IModalEditAddressProps {
  coords: google.maps.LatLngLiteral;
  address: string;
  onSave: (modalInfo: EditAddressInfo) => void;
  variant?: MapModalVariant;
  setCoords: Dispatch<SetStateAction<google.maps.LatLngLiteral>>;

  onClose(): void;
}

const ModalEditAddress: React.FC<IModalEditAddressProps> = ({
  onClose,
  coords,
  address,
  onSave,
  variant,
  setCoords,
}) => {
  const { t } = useTranslation();
  const [addressForm, setAddressForm] = useState({
    details: '',
    search: address || '',
  });
  const [place, setPlace] = useState('Home');
  // ! selectors
  const direction = useSelector(getDirection);

  // ! handlers
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAddressForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePlace = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    setPlace(value);
  };

  const onReset = (): void => {
    setAddressForm({
      details: '',
      search: '',
    });
    setPlace('Home');
  };

  const handleSave = (): void => {
    if (!addressForm.search) return;
    const modalInfo: EditAddressInfo = {
      addressForm,
      coords,
      place: t(`googleMap.editLocation.${place.toLowerCase()}`),
    };

    onReset();
    onSave(modalInfo);
  };

  return (
    <Paper
      sx={{
        maxWidth: '605px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        width: '100%',
        height: '300px',
        display: 'flex',
      }}
      >
        <MapComponent
          setCoords={setCoords}
          withInfo
          coords={coords}
          variant={variant}
        />
      </Box>
      <Box sx={{
        paddingTop: '0.625rem',
        paddingX: '1.8rem',
      }}
      >
        {/* HEADER */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
          marginTop: '1rem',
        }}
        >
          <Typography
            variant="h6"
            fontWeight="600"
            color="primary"
          >
            {t('googleMap.editLocation.editAddressTitle')}
          </Typography>
          <IconButton onClick={onClose}>
            <img src={closeModalIcon} alt={t('googleMap.editLocation.closeAlt')} />
          </IconButton>
        </Box>

        {/* RADIO GROUP */}
        <FormControl>
          <RadioGroup
            sx={{
              flexDirection: 'row',
              marginBottom: '1rem',
            }}
            aria-labelledby="edit-address"
            defaultValue="Home"
            name="radio-buttons-group"
            onChange={handlePlace}
          >
            <FormControlLabel
              value="Home"
              control={(
                <Radio
                  sx={{ width: '100%', height: '100%' }}
                  icon={(
                    <RadioButtonUnchecked sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    />
                  )}
                  checkedIcon={(
                    <RadioButtonChecked sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    />
                  )}
                />
              )}
              label={(
                <Typography
                  sx={{
                    marginRight: '21px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    fontSize: '22px',
                    fontWeight: '500',
                  }}
                >
                  {t('googleMap.editLocation.home')}
                </Typography>
              )}
            />
            <FormControlLabel
              value="Office"
              control={(
                <Radio
                  sx={{ width: '100%', height: '100%' }}
                  icon={(
                    <RadioButtonUnchecked sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    />
                  )}
                  checkedIcon={(
                    <RadioButtonChecked sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    />
                  )}
                />
              )}
              label={(
                <Typography
                  sx={{
                    marginRight: '21px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    fontSize: '22px',
                    fontWeight: '500',
                  }}
                >
                  {t('googleMap.editLocation.office')}
                </Typography>
              )}
            />
            <FormControlLabel
              value="Other"
              control={(
                <Radio
                  sx={{ width: '100%', height: '100%' }}
                  icon={(
                    <RadioButtonUnchecked sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    />
                  )}
                  checkedIcon={(
                    <RadioButtonChecked sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    />
                  )}
                />
              )}
              label={(
                <Typography
                  sx={{
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    fontSize: '22px',
                    fontWeight: '500',
                  }}
                >
                  {t('googleMap.editLocation.other')}
                </Typography>
              )}
            />
          </RadioGroup>
        </FormControl>

        <TextField
          dir={direction}
          sx={inputStyles}
          name="details"
          placeholder={t('googleMap.editLocation.detailsInputPlaceholder')}
          fullWidth
          value={addressForm.details}
          onChange={onChange}
          variant="outlined"
        />
        <TextField
          dir={direction}
          sx={inputStyles}
          name="search"
          placeholder={t('googleMap.editLocation.searchInputLabel')}
          label={t('googleMap.editLocation.searchInputLabel')}
          fullWidth
          value={addressForm.search}
          onChange={onChange}
          variant="outlined"
        />
      </Box>
      <CardActions sx={{ padding: 0 }}>
        <Button
          variant="contained"
          color="success"
          disabled={!addressForm.search}
          sx={{
            paddingY: '0.5rem',
            paddingX: '1rem',
            marginBottom: '1rem',
            marginTop: '0.5rem',
            marginX: 'auto',
            borderRadius: '6px',
          }}
          onClick={handleSave}
        >
          {t('buttons.saveAndContinue')}
        </Button>
      </CardActions>
    </Paper>
  );
};

export default ModalEditAddress;
