import React from 'react';

const CheckCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="43.177" height="42.396" viewBox="0 0 43.177 42.396">
    <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(2.004 2.023)">
      <path
        id="Path_4"
        data-name="Path 4"
        style={{
          fill: 'none', stroke: '#005cb1', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '4px',
        }}
        className="cls-1"
        d="M38.424 6 19.252 25.191 13.5 19.44"
        transform="translate(-.079 -2.153)"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        style={{
          fill: 'none', stroke: '#005cb1', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '4px',
        }}
        className="cls-1"
        d="M41.344 20.411v1.764A19.172 19.172 0 1 1 29.975 4.651"
        transform="translate(-3 -2.991)"
      />
    </g>
  </svg>
);

export default CheckCircle;
