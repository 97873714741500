import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import i18n from 'i18next';
import AppRouter from './navigation/AppRouter';
import { useAuth } from './navigation/AuthProvider';
import Notifier from './components/Notifier';
import { useDispatch, useSelector } from './store';
import { fetchUserProfile, setLocale } from './store/slices/user';
import { fetchCountries } from './store/slices/countries';
import { ROUTE_LINKS } from './navigation/routeLinks';
import { ltr, rtl } from './styles/muiTheme';
import RTL from './RTL/RTL';
import useAppLocale from './hooks/useAppLocale';
import { gaService } from './helpers/googleAnalytics';

const allowToFetchProfilePaths = [ROUTE_LINKS.DELIVERY, ROUTE_LINKS.TREATMENT, ROUTE_LINKS.CHECKOUT];
const isAllowedToFetchProfile = (pathname: string) => {
  let isAllowed = false;
  allowToFetchProfilePaths.forEach((allowedPath) => {
    if (pathname.includes(allowedPath)) isAllowed = true;
  });
  return isAllowed;
};

function App() {
  const dispatch = useDispatch();
  const { isAuth } = useAuth();
  const location = useLocation();
  const [isRight, setIsRight] = useState(false);
  const [urlSearchParams] = useSearchParams();
  const { getLocaleFlow } = useAppLocale();

  // ! selectors
  const userProfile = useSelector((state) => state.user.userProfile);

  // ! memos
  const { divDirection, customTheme } = useMemo(() => {
    const customThemeArg = isRight ? rtl() : ltr();
    const divDirectionArg = isRight ? 'rtl' : 'ltr';
    return {
      customTheme: customThemeArg,
      divDirection: divDirectionArg,
    };
  }, [isRight]);

  // ! effects
  useEffect(() => {
    const locale = getLocaleFlow();
    i18n.changeLanguage(locale);
    dispatch(setLocale(locale));
    const isArabic = locale === 'ar';
    setIsRight(isArabic);
  }, [urlSearchParams]);

  useEffect(() => {
    if (!isAuth || userProfile) return;
    if (!isAllowedToFetchProfile(location.pathname)) return;
    dispatch(fetchUserProfile());
  }, [isAuth, location]);

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    gaService.pushLocation();
  }, [location.pathname]);

  // ! render
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <RTL isRight={isRight} divDirection={divDirection}>
        <AppRouter />
        <Notifier />
      </RTL>
    </ThemeProvider>
  );
}

export default App;
