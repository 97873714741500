/* eslint-disable no-param-reassign */
import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import notifierSlice from './slices/notifier/notifier';
import userSlice from './slices/user';
import questionnaireSlice from './slices/questionnaireSlice';
import countriesSlice from './slices/countries';
import orderSlice from './slices/order';
import addressSlice from './slices/address';
import symptomsSlice from './slices/symptoms';
import appointmentSlice from './slices/appointment';

const combinedReducer = combineReducers({
  notifier: notifierSlice,
  user: userSlice,
  questionnaire: questionnaireSlice,
  countries: countriesSlice,
  orders: orderSlice,
  address: addressSlice,
  symptoms: symptomsSlice,
  appointment: appointmentSlice,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'userSlice/logout') state = undefined;
  return combinedReducer(state, action);
};
export default rootReducer;
