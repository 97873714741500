import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const CardWithDeleteButton: FC = ({ children }) => (
  <Box sx={{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.8rem',
  }}
  >
    {children}
    <IconButton sx={{ paddingRight: 0 }}>
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  </Box>
);

export default CardWithDeleteButton;
