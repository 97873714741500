import { IServiceMethodFunc } from '../types/services';
import httpService from '../httpService';
import { IOrderInstance } from '../types/order';
import { IPaymentInstrument, IPaymentMethodItem } from '../types/payment.t';

interface IOrderService {
  fetchPaymentMethods: IServiceMethodFunc<number, IPaymentMethodItem[]>;
  fetchOrderById: IServiceMethodFunc<number | string, IOrderInstance>;
  fetchPaymentInstruments: IServiceMethodFunc<number, IPaymentInstrument[]>;
  createOrderByAppointmentId: IServiceMethodFunc<string | number, IOrderInstance>;
  postDeliveryAddressIntoOrder: IServiceMethodFunc<{ orderId: string | number, addressId: string | number }>;
  postPaymentInstrument: IServiceMethodFunc<{ payment_instrument_id: number, orderId: number }, IOrderInstance>;
  postPerformActivity: IServiceMethodFunc<{ resource_id: string, activity: string, resource: string }, IOrderInstance>;
  postPaymentMethod: IServiceMethodFunc<{
    payment_method_id: number,
    orderId: number,
    order_url?: string
  }, IOrderInstance>;
}

const orderService: IOrderService = {
  postPaymentInstrument: ({ payment_instrument_id, orderId }) => httpService.post(`orders/${orderId}/set_payment`, {
    payment_instrument_id,
  }),
  fetchOrderById: (orderId) => httpService.get(`orders/${orderId}`),
  postPerformActivity: ({
    activity,
    resource_id,
    resource,
  }) => httpService.post(`${resource}/${resource_id}/${activity}`),
  postPaymentMethod: ({ payment_method_id, orderId, order_url }) => {
    let methodBody: { payment_method_id: number, order_url?: string } = { payment_method_id };
    if (order_url) methodBody = { ...methodBody, order_url };
    return httpService.post(`orders/${orderId}/set_payment`, methodBody);
  },
  createOrderByAppointmentId: (appointmentId) => httpService.post(`appointments/${appointmentId}/order`),
  fetchPaymentMethods: (id) => httpService.get(`payment_methods?country_id=${id}`),
  fetchPaymentInstruments: (methodId) => httpService.get(`payment_instruments?payment_method_id=${methodId}`),
  postDeliveryAddressIntoOrder: ({ orderId, addressId }) => httpService.post(
    `orders/${orderId}/set_delivery_address`,
    {
      delivery_address_id: addressId,
    },
  ),
};

export default orderService;
