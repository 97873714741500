/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormCard from '../FormCard';
import TextCardLine from './TextCardLine';
import { useSelector } from '../../../store';
import { IOrderInstance } from '../../../types/order';
import FormRightSideWrap from './FormRightSideWrap';
import HasNoItems from './HasNoItems';

// ! Helper functions determine the weight and color of the item based on the text
const greenColor = '#1cb4b0';
const greenEntries = ['discount', 'خصم'];
const greenRightSideEntries = ['free'];
const boldEntries = ['subtotal', '‏المبلغ الفرعي', 'discount', 'خصم'];
// ! helpers
const calcColors = ({ value_text, label }: { label: string; value_text: string; }) => {
  let isGreenLine = false;
  if (label) {
    isGreenLine = greenEntries.some((candidate) => label.toLowerCase().includes(candidate));
  }

  let isGreenRight = false;
  if (value_text) {
    isGreenRight = greenRightSideEntries.some((candidate) => value_text.toLowerCase().includes(candidate));
  }

  let rightColor;
  let leftColor;

  if (isGreenLine) {
    rightColor = greenColor;
    leftColor = greenColor;
  }
  if (isGreenRight) rightColor = greenColor;
  return {
    rightColor,
    leftColor,
  };
};
const calcFontWeight = ({ label }: { label: string; value_text: string; }) => {
  let isBoldLine = false;
  if (label) {
    isBoldLine = boldEntries.some((candidate) => label.toLowerCase().includes(candidate));
  }
  let rightFontWeight = 500;
  let leftFontWeight = 500;
  if (isBoldLine) {
    rightFontWeight = 600;
    leftFontWeight = 600;
  }
  return {
    rightFontWeight,
    leftFontWeight,
  };
};

const FormRightSide = () => {
  const { t } = useTranslation();
  const currentOrder = useSelector<IOrderInstance | null>((state) => state.orders.currentOrder);
  if (!currentOrder) return <FormRightSideWrap isEmpty />;
  const {
    summary,
    currency_code_iso: currencyCode,
    final_amount: finalAmount,
    order_items: orderItems,
  } = currentOrder || {} as IOrderInstance;

  // ! render
  return (
    <FormCard>
      <Typography variant="h6" mb="1rem">
        {t('checkout.orderSummary')}
      </Typography>
      <Typography variant="body1" fontWeight="500">
        {t('checkout.items')}
      </Typography>

      {/* ORDER ITEMS */}
      {
        orderItems?.length ? orderItems.map((orderItem) => {
          const {
            id,
            title,
            sale_price,
            description,
          } = orderItem;
          const priceText = `${currencyCode} ${sale_price}`;
          const captionText = description || null;
          return (
            <TextCardLine
              key={id}
              leftSideText={title}
              rightSideText={priceText}
              captionText={captionText}
            />
          );
        })
          : <HasNoItems itemName="orders" />
      }

      {/* SUMMARY */}
      <Box sx={{ border: '1px dashed', borderColor: 'secondary.main', marginTop: '1rem' }} />
      {
        summary?.length
          ? summary.map(({ value_text, label }, index) => {
            const { rightColor, leftColor } = calcColors({ label, value_text });
            const { rightFontWeight, leftFontWeight } = calcFontWeight({ label, value_text });

            // component return
            return (
              <TextCardLine
                key={index}
                leftSideText={label}
                rightSideText={value_text}
                leftFontWeight={leftFontWeight}
                rightFontWeight={rightFontWeight}
                rightColor={rightColor}
                leftColor={leftColor}
              />
            );
          })
          : <HasNoItems itemName="summary" />
      }
      <Box sx={{ border: '1px dashed', borderColor: 'secondary.main', marginTop: '1rem' }} />

      {typeof finalAmount === 'number'
        ? (
          <TextCardLine
            leftSideText="Total"
            rightSideText={`${currencyCode} ${finalAmount}`}
            rightFontWeight={600}
            leftFontWeight={600}
          />
        )
        : <HasNoItems itemName="total" />}
    </FormCard>
  );
};

export default FormRightSide;
