import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Button, Container, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TreatmentDetails from '../../components/PreferredTreatmentPage/TreatmentDetails';
import { ITreatment } from '../../types/treatment';
import useFormLinkTo from '../../hooks/useFormLinkTo';
import { ROUTE_LINKS } from '../../navigation/routeLinks';
import { useSelector } from '../../store';
import { gaService } from '../../helpers/googleAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  textCenterDownMd: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  titleStyle: {
    '&.MuiTypography-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
  },
  subtitleStyle: {
    '&.MuiTypography-root': {
      fontSize: '18px',
    },
  },
  notFoundStyle: {
    '&.MuiTypography-root': {
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
  },
  otherTreatmentsStyle: {
    '&.MuiTypography-root': {
      fontSize: '18px',
    },
  },
  nextButtonStyle: {
    '&.MuiButton-root': {
      fontSize: '16px',
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
}));

interface Props {
  onSelectTreatment: (treatmentVariantId: number, treatmentItemId: ITreatment) => void;
  mainTreatment?: ITreatment;
  otherTreatments?: ITreatment[];
}

const PreferredTreatmentView: FC<Props> = ({ onSelectTreatment, mainTreatment, otherTreatments }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTrVariant, setSelectedTrVariant] = useState<number | null>(null);
  const [selectedTrItem, setSelectedTrItem] = useState<ITreatment | null>(null);
  const [showOtherTreatments, setShowOtherTreatments] = useState(false);
  const loading = useSelector((state) => state.orders.loading);
  const formLinkTo = useFormLinkTo();

  // ! styles
  const {
    textCenterDownMd,
    otherTreatmentsStyle,
    nextButtonStyle,
    subtitleStyle,
  } = useStyles();

  // ! memos
  const nextDisabled = useMemo(() => {
    if (loading) return true;
    const hasNoTreatments = !mainTreatment && !otherTreatments?.length;
    if (hasNoTreatments) return true;
    return !selectedTrVariant;
  }, [mainTreatment, onSelectTreatment, selectedTrVariant, loading]);

  // ! handlers
  const onVariantSelect = (variantId: number | null, treatmentItem: ITreatment) => {
    setSelectedTrVariant(variantId);
    setSelectedTrItem(treatmentItem);
  };
  const onNextPress = () => {
    const hasNoTreatments = !mainTreatment && !otherTreatments?.length;
    if (hasNoTreatments) {
      navigate({ pathname: formLinkTo({ mainPath: ROUTE_LINKS.CHECKOUT }) });
      return;
    }
    if (selectedTrVariant === null || selectedTrItem === null) return;
    onSelectTreatment(selectedTrVariant, selectedTrItem);
  };
  const onShowOtherTreatments = () => {
    setShowOtherTreatments(true);
    gaService.treatments_expanded();
  };

  // ! effects
  useEffect(() => {
    if (!mainTreatment?.treatment_variants?.length) return;
    const firstVariant = mainTreatment.treatment_variants[0];
    if (!firstVariant?.id) return;
    onVariantSelect(firstVariant.id, mainTreatment);
  }, [mainTreatment]);

  // ! render
  return (
    <Container maxWidth="md" sx={{ flexGrow: 1, marginTop: '1.7rem' }}>
      {/* TITLE */}
      <Box mb="2.5rem">
        <Typography
          variant="h6"
          color="primary.main"
          fontWeight="600"
          textAlign="center"
          mb="4rem"
        >
          {t('treatment.treatmentTitle')}
        </Typography>
        {/* SUBTITLE */}
        {mainTreatment && (
          <Typography
            variant="body1"
            color="success.main"
            fontWeight="500"
            mt="2rem"
            className={subtitleStyle}
          >
            {t('treatment.recommendedTitle')}
          </Typography>
        )}
      </Box>
      {/* MAIN TREATMENT */}
      {!!mainTreatment && (
        <TreatmentDetails
          treatmentItem={mainTreatment}
          selectedTrVariant={selectedTrVariant}
          onVariantSelect={onVariantSelect}
          disableOpenClose
        />
      )}
      {/* OTHER TREATMENTS */}
      {(!!otherTreatments && !showOtherTreatments) && (
        <Box className="flex-center" sx={{ marginTop: '3rem' }}>
          <Button
            sx={{
              border: '1px solid #d8ecf8',
              borderRadius: '8px',
              color: 'common.black',
            }}
            onClick={onShowOtherTreatments}
          >
            {t('buttons.showAllTreatments')}
          </Button>
        </Box>
      )}
      {(!!otherTreatments && showOtherTreatments)
        && (
          <>
            <Typography
              className={[textCenterDownMd, otherTreatmentsStyle].join(' ')}
              variant="body1"
              color="primary.main"
              fontWeight={500}
              mb="2.3rem"
              mt="3rem"
            >
              {t('treatment.otherTreatments')}
            </Typography>
            {
              otherTreatments.map((item, index, array) => (
                <div key={item.id}>
                  <TreatmentDetails
                    treatmentItem={item}
                    selectedTrVariant={selectedTrVariant}
                    onVariantSelect={onVariantSelect}
                  />
                  {
                    (index < array.length - 1)
                    && <Box sx={{ marginY: '2rem', border: '1px dashed #e6e6e6' }} />
                  }
                </div>
              ))
            }
          </>
        )}
      {/* ACTIONS */}
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3rem',
        marginBottom: '2rem',
      }}
      >
        <Button
          sx={{ fontWeight: 600, paddingX: '2rem' }}
          disabled={nextDisabled}
          variant="contained"
          color="primary"
          onClick={onNextPress}
          className={nextButtonStyle}
        >
          {t('buttons.next')}
          {' >'}
        </Button>
      </Box>
    </Container>
  );
};

export default PreferredTreatmentView;
