import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  SxProps,
} from '@mui/material';
import QuestionnaireTitle from './QuestionnaireTitle';
import PrevNextSection from '../PrevNextSection';
import QuestionnaireTextarea from './QuestionnaireTextarea';
import OneAnswerQuestionType from './OneAnswerQuestionType';
import MultipleAnswerQuestionType from './MultipleAnswerQuestionType';
import { IQuestion } from '../../types/common';
import {
  INormalizedAnswer,
  IUserAnswer,
  TUserAnswer,
  UserDescriptions,
} from '../../types/questionnaire';
import { isDisableOthersVariant } from '../../helpers/questionnaire/mutlipleQuestionHelper';
import { useSelector } from '../../store';

const sxStyles: SxProps = {
  titleAndFormContainer: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  scrollDiv: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 'calc(100vh - 331px)',
    padding: '0 15px',
    overflowY: 'auto',
    minHeight: '200px',
    '@media all and (max-width: 442px)': {
      maxHeight: 'calc(100vh - 365px)',
    },
  },
};

interface Props {
  onNext: (answer: IUserAnswer) => void;
  onPrev: () => void;
  hasMoreQuestions: boolean;
  hasPrevQuestions: boolean;
  currentQuestion: IQuestion;
  currentAnswers: INormalizedAnswer[];
  existingAnswer?: TUserAnswer;
}

/**
  Container for question/answer forms and their values
*/
const QuestionnaireScreen: FC<Props> = ({
  onNext,
  hasMoreQuestions,
  hasPrevQuestions,
  onPrev,
  currentQuestion,
  currentAnswers,
  existingAnswer,
}) => {
  const [oneAnswerValue, setOneAnswerValue] = useState('');
  const [descriptions, setDescriptions] = useState<UserDescriptions>({});
  const [multipleAnswers, setMultipleAnswers] = useState<number[]>([]);
  const [textQuestionAnswer, setTextQuestionAnswer] = useState('');
  // ! styles
  const { titleAndFormContainer, scrollDiv } = sxStyles;
  // ! selectors
  const normalizedData = useSelector((state) => state.questionnaire.normalizedData || {});
  // ! memos
  const nextDisabled = useMemo<boolean>(() => {
    switch (currentQuestion.symptom_type) {
      case 'once_choice':
        return !oneAnswerValue;
      case 'multi_choice':
        return !multipleAnswers.length;
      default:
        return false;
    }
  }, [oneAnswerValue, multipleAnswers, textQuestionAnswer, currentQuestion]);

  // ! helpers
  const getAnswerValue = () => {
    if (!currentQuestion) return null;
    switch (currentQuestion.symptom_type) {
      case 'text':
        return textQuestionAnswer;
      case 'once_choice':
        return oneAnswerValue;
      case 'multi_choice':
        return multipleAnswers;
      default:
        return null;
    }
  };

  // ! handlers
  const handleOneAnswerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOneAnswerValue(event.target.value);
  };

  const handleMultipleChange = (event: SyntheticEvent<Element, Event>) => {
    const checkbox = (event.target as HTMLInputElement);
    if (!checkbox) return;
    const checkboxValue = Number(checkbox.value);

    if (checkbox.checked) {
      if (isDisableOthersVariant(checkboxValue, normalizedData)) return setMultipleAnswers([checkboxValue]);
      setMultipleAnswers((prevState) => ([
        ...prevState,
        checkboxValue,
      ]));
      return;
    }
    setMultipleAnswers((prevState) => prevState.filter((el) => el !== checkboxValue));
  };

  const onTextQuestionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const textValue = event.target.value;
    setTextQuestionAnswer(textValue);
  };

  const onDescChange = ({ text, answerId } : { text: string; answerId: number | string }) => {
    setDescriptions((prevState) => ({
      ...prevState,
      [answerId]: text,
    }));
  };

  const handleNextClick = () => {
    if (!currentQuestion) return;
    const ansValue = getAnswerValue();
    if (ansValue === null) return;
    const answer: IUserAnswer = {
      answer: ansValue,
      questionId: currentQuestion.id,
      descriptions,
    };
    onNext(answer);
  };
  const handleSkipClick = () => {
    if (!currentQuestion) return;
    const answer: IUserAnswer = {
      answer: '',
      questionId: currentQuestion.id,
    };
    onNext(answer);
  };

  // ! effects
  useEffect(() => {
    switch (currentQuestion.symptom_type) {
      case 'multi_choice':
        setMultipleAnswers(existingAnswer as number[] || []);
        break;
      case 'once_choice':
        setOneAnswerValue(existingAnswer as string || '');
        break;
      case 'text':
        setTextQuestionAnswer(existingAnswer as string || '');
        break;
      default:
        break;
    }
  }, [existingAnswer, currentQuestion]);

  // ! render
  // renders different types of forms for different types of questions
  const renderHelper = () => {
    if (!currentQuestion) return null;

    switch (currentQuestion.symptom_type) {
      case 'text':
        return <QuestionnaireTextarea onChange={onTextQuestionChange} value={textQuestionAnswer} />;
      case 'multi_choice':
        return (
          <MultipleAnswerQuestionType
            checkedAnswers={multipleAnswers}
            handleChange={handleMultipleChange}
            answers={currentAnswers}
            onDescChange={onDescChange}
          />
        );
      case 'once_choice':
        return (
          <OneAnswerQuestionType
            formValue={oneAnswerValue}
            handleChange={handleOneAnswerChange}
            answers={currentAnswers}
            onDescChange={onDescChange}
          />
        );
      default:
        return null;
    }
  };

  if (!currentQuestion) {
    return (
      <Backdrop
        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box sx={titleAndFormContainer}>
      <Box sx={{ width: '100%' }}>
        <QuestionnaireTitle title={currentQuestion.name} />
        <Box sx={scrollDiv}>
          {renderHelper()}
        </Box>
      </Box>
      <PrevNextSection
        hidePrevious={!hasPrevQuestions}
        onNextClick={handleNextClick}
        nextButtonText={!hasMoreQuestions ? 'Submit' : ''}
        onPrevClick={onPrev}
        canSkip={false}
        onSkipClick={handleSkipClick}
        nextDisabled={nextDisabled}
      />
    </Box>
  );
};

export default QuestionnaireScreen;
