import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { FormControl, TextField, Typography } from '@mui/material';
import { INormalizedAnswer } from '../../types/questionnaire';
import { useSelector } from '../../store';

interface Props {
  answerItem: INormalizedAnswer;
  onDescChange: ({ text, answerId } : { text: string; answerId: number | string }) => void;
}

const AnswerDescriptionField: FC<Props> = ({ answerItem, onDescChange }) => {
  const [existingDescription, setExistingDescription] = useState('');
  const userDescriptions = useSelector((state) => state.questionnaire.userDescriptions);

  // ! handlers
  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = event.target.value;
    const answerId = answerItem.id;
    onDescChange({ text, answerId });
  };

  // ! effects
  useEffect(() => {
    const candidate = userDescriptions[answerItem.id];
    if (!candidate) return;
    setExistingDescription(candidate);
  }, [answerItem, userDescriptions]);
  return (
    <FormControl>
      <Typography mb={0.5}>{ answerItem.description_label }</Typography>
      <TextField
        defaultValue={existingDescription}
        onChange={onChange}
        id="description-textarea"
        sx={{
          marginBottom: '26px',
        }}
        multiline
      />
    </FormControl>
  );
};

export default AnswerDescriptionField;
