import React, { ChangeEvent, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AuthSelectAdornment from './AuthSelectAdornment';
import { useSelector } from '../../store';
import { getCountriesNamesAndPrefixes } from '../../store/selectors/countries';
import { getDirection } from '../../store/selectors/user';

const textFieldStyles: SxProps = {
  '&.MuiFormControl-root': {
    marginTop: '6px',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& input': {
    paddingLeft: '0.4rem',
  },
};

interface Props {
  handleSms: (phonePrefix: string) => void;
  onPhoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  existingPrefix?: string;
  existingPhone: string;
}

const LoginView: FC<Props> = ({
  handleSms, existingPhone, existingPrefix, onPhoneChange,
}) => {
  const { t } = useTranslation();
  const [prefixValue, setPrefixValue] = React.useState<null | string>(null);
  const direction = useSelector(getDirection);
  // ! handlers
  const onChangeSelect = (value: string | null) => {
    if (!value) return;
    setPrefixValue(value);
  };
  const handlePhoneSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!prefixValue) return;
    handleSms(prefixValue);
  };

  const phonePrefixes = useSelector(getCountriesNamesAndPrefixes);

  useEffect(() => {
    if (!phonePrefixes?.length || prefixValue) return;
    const defaultPrefix = phonePrefixes.find((item) => item.current === true);
    setPrefixValue(existingPrefix || defaultPrefix?.prefix || phonePrefixes[0].prefix);
  }, [phonePrefixes, prefixValue, existingPrefix]);

  // ! render
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TaskAltIcon sx={{ m: 1, color: 'primary.main' }} />
        <Typography variant="h6" color="primary.main" fontWeight={600}>
          {t('phoneLogIn.title')}
        </Typography>

        <Box
          dir={direction}
          component="form"
          onSubmit={handlePhoneSubmit}
          noValidate
          sx={{ mt: '1.2rem' }}
          textAlign="center"
        >
          <Typography
            variant="body1"
            textAlign="left"
            fontWeight={500}
          >
            {t('phoneLogIn.phoneInputLabel')}
          </Typography>
          <TextField
            autoFocus
            sx={textFieldStyles}
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            autoComplete="=phoneNumber"
            value={existingPhone}
            onChange={onPhoneChange}
            InputProps={{
              startAdornment: (
                <AuthSelectAdornment
                  phonePrefixes={phonePrefixes}
                  selectedPrefix={prefixValue}
                  onChange={onChangeSelect}
                />
              ),
            }}
          />

          <Button
            disabled={!existingPhone}
            type="submit"
            variant="contained"
            color="success"
            sx={{ mt: 3, mb: 2, fontWeight: 600 }}
          >
            {t('buttons.submit')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginView;
