import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import { useDispatch, useSelector } from '../../store';
import { getCurrentAddress, getUserFullName } from '../../store/selectors/user';
import { IDateTime } from '../../types/user';
import InfoLine from '../../components/PaymentMethod/InfoLine';
import { postPerformActivity } from '../../store/slices/order';
import BackdropLoad from '../../components/common/BackdropLoad';
import { ROUTE_LINKS } from '../../navigation/routeLinks';
import useFormLinkTo from '../../hooks/useFormLinkTo';
import ToggleRight from '../../RTL/ToggleRight';

/**
  The component displays information about all previous steps with the ability to edit them.
  Performs payment selection.
  Some payment options involve switching to third-party resources in a manner similar to OAuth2
  */
const CheckoutView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formLinkTo = useFormLinkTo();
  const [infoHeight, setInfoHeight] = useState(24);
  const { t } = useTranslation();

  // ! selectors
  const userFullName = useSelector(getUserFullName);
  const userEmail = useSelector((state) => state.user.userProfile?.email || '');
  const currentDateTime = useSelector<IDateTime | null>((state) => state.appointment.currentDateTime);
  const orderNextAction = useSelector((state) => state.orders.currentOrder?.next_action);
  const currentAddress = useSelector(getCurrentAddress);
  const orderLoading = useSelector((state) => state.orders.loading);

  // ! helpers
  const performActivityCase = async (resource: string, resource_id: string, activity: string) => {
    try {
      const wrappedOrder = await dispatch(postPerformActivity({ activity, resource_id, resource }));
      const unwrappedOrder = unwrapResult(wrappedOrder);
      // eslint-disable-next-line no-underscore-dangle
      return unwrappedOrder?.is_locked || null;
    } catch (e) {
      return null;
    }
  };

  // ! handlers
  const onPlaceRequest = () => {
    if (!orderNextAction) return;
    const {
      action, url, resource, resource_id, activity,
    } = orderNextAction;
    if (action === 'redirect_to_url' && url) window.location.href = url;
    if (action === 'perform_activity_on_resource' && resource && activity && resource_id) {
      performActivityCase(resource, resource_id, activity);
    }
  };
  const onTimeEdit = () => {
  };
  const onChangeDeliveryAddress = () => {
    navigate({ pathname: formLinkTo({ mainPath: ROUTE_LINKS.DELIVERY }) });
  };

  // ! render
  return (
    <>
      <ToggleRight />
      {orderLoading && <BackdropLoad />}
      <InfoLine setInfoHeight={setInfoHeight} infoHeight={infoHeight} />
      <Container maxWidth="md" sx={{ flexGrow: 1 }}>

        <Typography
          variant="h5"
          fontWeight={600}
          color="success.main"
          textAlign="center"
          mb="0.5rem"
          mt="1.5rem"
        >
          {t('checkout.checkoutTitle')}
        </Typography>
        <PaymentMethod
          infoHeight={infoHeight}
          onTimeEdit={onTimeEdit}
          onPlaceRequest={onPlaceRequest}
          onAddrEdit={onChangeDeliveryAddress}
          userFullName={userFullName}
          userEmail={userEmail}
          currentDateTime={currentDateTime}
          currentAddress={currentAddress}
        />
      </Container>
    </>
  );
};

export default CheckoutView;
