import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  subtitle: string;
}

const VariantLabel: FC<Props> = ({ title, subtitle }) => (
  <Box>
    <Typography
      variant="body1"
      fontWeight="bold"
      color="common.black"
      fontSize={16}
      className="item-title"
    >
      {title}
    </Typography>
    <Typography
      variant="caption"
      display="block"
      fontWeight="500"
      color="#b2b2b2"
      fontSize={12}
      lineHeight={1}
      className="item-subtitle"
    >
      {subtitle}
    </Typography>
  </Box>
);

export default VariantLabel;
