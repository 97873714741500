import React, {
  FC, SyntheticEvent, useEffect, useState,
} from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, FormGroup } from '@mui/material';
import { useStyles } from './customInputStyles';
import { INormalizedAnswer } from '../../types/questionnaire';
import { useSelector } from '../../store';
import { isDisableOthersVariant } from '../../helpers/questionnaire/mutlipleQuestionHelper';
import AnswerDescriptionField from './AnswerDescriptionField';

interface Props {
  handleChange: (event: SyntheticEvent<Element, Event>) => void;
  checkedAnswers: number[];
  answers: INormalizedAnswer[];
  onDescChange: ({ text, answerId } : { text: string; answerId: number | string }) => void;
}

const MultipleAnswerQuestionType: FC<Props> = ({
  checkedAnswers,
  handleChange,
  answers,
  onDescChange,
}) => {
  const { formLabel, checkedLabel } = useStyles();
  const [triggerQuestionId, setTriggerQuestionId] = useState<number | null>(null);
  const normalizedData = useSelector((state) => state.questionnaire.normalizedData || {});

  // ! handlers
  const getTriggerQuestionId = () => {
    let id = null;
    checkedAnswers.forEach((ansId) => {
      if (isDisableOthersVariant(ansId, normalizedData)) id = ansId;
    });
    return id;
  };

  // eslint-disable-next-line max-len
  const isDisabledVariant = (currentQuestionId: number) => (triggerQuestionId
    ? triggerQuestionId !== currentQuestionId
    : false);

  useEffect(() => {
    if (!checkedAnswers?.length) return setTriggerQuestionId(null);

    const triggerId = getTriggerQuestionId();
    if (!triggerId) return setTriggerQuestionId(null);
    setTriggerQuestionId(triggerId);
  }, [checkedAnswers]);

  return (
    <FormControl>
      <FormGroup>
        {answers.map((answerItem) => {
          const isChecked = checkedAnswers.includes(answerItem.id);
          return (
            <React.Fragment key={answerItem.id}>
              <FormControlLabel
                sx={{ maxWidth: '407px', paddingRight: '1.5rem' }}
                onChange={handleChange}
                control={(<Checkbox name={answerItem.name} value={answerItem.id} checked={isChecked} />)}
                label={answerItem.name}
                className={[formLabel, isChecked ? checkedLabel : ''].join(' ')}
                disabled={isDisabledVariant(answerItem.id)}
              />
              {(answerItem.describable && isChecked)
                && (
                  <AnswerDescriptionField
                    answerItem={answerItem}
                    onDescChange={onDescChange}
                  />
                )}
            </React.Fragment>
          );
        })}

      </FormGroup>
    </FormControl>
  );
};

export default MultipleAnswerQuestionType;
