import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  userFirstName: string;
}

const WelcomeTitle: FC<Props> = ({ userFirstName }) => {
  const { t } = useTranslation();
  return (
    <Typography
      width="100% !important"
      variant="h6"
      color="#333333"
      fontWeight="600"
      textAlign="center"
      mb="1.5rem"
    >
      {t('delivery.hasAddress.welcomeTitle')}
      {userFirstName ? `${userFirstName}` : ''}
    </Typography>
  );
};

export default WelcomeTitle;
