import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAppLocale from '../hooks/useAppLocale';
import useIsMobileCheckout from '../hooks/useIsMobileCheckout';

const ToggleRight: FC = () => {
  const { t } = useTranslation();
  const isMobileCheckout = useIsMobileCheckout();
  const { setLocaleFlow, getLocaleFlow } = useAppLocale();

  // ! handlers
  const handleLanguageChange = () => {
    const locale = getLocaleFlow();
    const isArabic = locale === 'ar';
    setLocaleFlow(isArabic ? 'en' : 'ar');
  };

  // ! return
  return (
    <Button
      onClick={handleLanguageChange}
      variant="text"
      sx={{
        position: 'absolute',
        top: isMobileCheckout ? '0' : '10px',
        right: isMobileCheckout ? '0' : '1rem',
      }}
    >
      {t('buttons.toggleLanguage')}
    </Button>
  );
};

export default ToggleRight;
