/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactNode } from 'react';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {
  InputAdornment, TextField, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../store';
import { getDirection } from '../../store/selectors/user';

const useStyles = makeStyles((theme: Theme) => ({
  inputStyles: {
    width: '100%',
    '&:after': {
      content: '""',
      width: '36px',
      height: '30px',
      position: 'absolute',
      top: '1.5px',
      left: theme.direction === 'ltr' ? '1px' : 'calc(100% - 37px)',
      background: '#eaf0f2',
      zIndex: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 4px!important',
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: '1px!important',
      border: `1px solid ${theme.palette.primary.dark}!important`,
      borderRadius: '4px',
      paddingRight: theme.direction === 'ltr' ? '14px' : '5px',
    },

    '& .MuiInputAdornment-root': {
      zIndex: 1,
      paddingLeft: '5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none!important',
    },
  },
}));

interface Props {
  svgIcon: ReactNode;
  handleAccept: (acceptedDate: Date | null) => void;
  existingValue: Date;
}

const CustomDatePicker: FC<Props> = ({ svgIcon, handleAccept, existingValue }) => {
  const { inputStyles } = useStyles();
  const [value, setValue] = React.useState<Date>(new Date());
  const direction = useSelector(getDirection);
  const { t } = useTranslation();

  const handleChange = (newValue: Date | null) => {
    if (newValue) setValue(newValue);
  };
  return (

    <LocalizationProvider dateAdapter={DateAdapter}>
      <div style={{ width: '100%' }}>
        <Typography textAlign="left" variant="body2">{t('moreDetailsForm.birthDate')}</Typography>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          value={existingValue || value}
          allowSameDateSelection
          onChange={handleChange}
          onAccept={handleAccept}
          disableFuture
          InputProps={{
            dir: direction,
            startAdornment: (
              <InputAdornment position="start">
                {svgIcon}
              </InputAdornment>
            ),
          }}
          renderInput={(params) => <TextField className={inputStyles} {...params} />}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
