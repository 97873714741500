import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Questionnaire from '../pages/Questionnaire';
import NotFound from './NotFound';
import ProtectedRoute from './ProtectedRoute';
import HomePage from '../pages/HomePage';
import MainLayout from '../layouts/MainLayout';
import { ROUTE_LINKS } from './routeLinks';
import ThankYouPage from '../pages/ThankYouPage/ThankYouPage';
import TreatmentContainer from '../containers/TreatmentContainer';
import CheckoutView from '../pages/Checkout/CheckoutView';
import DeliveryView from '../pages/Delivery/DeliveryView';
import DeliveryCheckoutLayout from '../layouts/DeliveryCheckoutLayout';

/*
Public routes for every user, private routes for those who have successfully verified their phone in the login form and
received a token from the server
*/

const AppRouter = () => (
  <Routes>
    <Route element={<MainLayout />}>
      {/* PUBLIC ROUTES */}
      <Route path="not-found" element={<NotFound />} />
      <Route path={`${ROUTE_LINKS.ROOT}`} element={<HomePage />} />
      <Route path={`${ROUTE_LINKS.ROOT}/:symptomSlug`} element={<HomePage />} />
      <Route path={`${ROUTE_LINKS.QUESTIONNAIRE}/:symptomSlug`} element={<Questionnaire />} />
      <Route path={`${ROUTE_LINKS.HOME}/:symptomSlug`} element={<HomePage />} />

      {/* PRIVATE ROUTES */}
      <Route element={<DeliveryCheckoutLayout />}>
        <Route
          path={`${ROUTE_LINKS.DELIVERY}/:symptomSlug/:appointmentId`}
          element={(
            <ProtectedRoute>
              <DeliveryView />
            </ProtectedRoute>
          )}
        />
        <Route
          path={`${ROUTE_LINKS.DELIVERY}/:symptomSlug/:appointmentId/:orderId`}
          element={(
            <ProtectedRoute>
              <DeliveryView />
            </ProtectedRoute>
          )}
        />
        <Route
          path={`${ROUTE_LINKS.CHECKOUT}/:symptomSlug/:appointmentId/:orderId`}
          element={(
            <ProtectedRoute>
              <CheckoutView />
            </ProtectedRoute>
          )}
        />
      </Route>

      <Route
        path={`${ROUTE_LINKS.TREATMENT}/:symptomSlug/:appointmentId`}
        element={(
          <ProtectedRoute>
            <TreatmentContainer />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${ROUTE_LINKS.TREATMENT}/:symptomSlug/:appointmentId/:orderId`}
        element={(
          <ProtectedRoute>
            <TreatmentContainer />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTE_LINKS.THANK_YOU}
        element={(
          <ProtectedRoute>
            <ThankYouPage />
          </ProtectedRoute>
        )}
      />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default AppRouter;
