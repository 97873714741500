/* eslint-disable @typescript-eslint/no-use-before-define */

/*
  Each question can contain answer options.
  Each answer may contain sub-questions.
  Each sub-question can contain answers with its own sub-questions.
  Nesting is unknown.
  The function performs a recursive traversal of the array in order to convert it to a "flat" data structure.
  The function returns an object where the key is the element's id and the value is the element itself.
  The object contains all questions and all answers, each element has the id of the next element.
*/

let res = {};

function addToRes(dataItem, prevDataItem, field, nextRootId) {
  const objectId = dataItem.id;
  if (!res[objectId]) {
    res[objectId] = {
      ...dataItem,
      nextStep: [],
      prevStep: prevDataItem?.id || null,
    };
  }
  if (nextRootId && !res[objectId][field]?.length) {
    res[objectId].nextRootId = nextRootId;
  }

  delete res[objectId][field];
  res[objectId].isQuestion = (field === 'answers');

  dataItem[field].forEach((fieldItem) => {
    res[objectId].nextStep.push(fieldItem.id);
  });
}

function objectCase(dataItem, prevDataItem, nextRootId) {
  if (dataItem.answers) {
    addToRes(dataItem, prevDataItem, 'answers', nextRootId);
    recursiveNormalizer(dataItem.answers, dataItem, nextRootId);
  }
  if (dataItem.questions) {
    addToRes(dataItem, prevDataItem, 'questions', nextRootId);
    recursiveNormalizer(dataItem.questions, dataItem, nextRootId);
  }
}

const recursiveNormalizer = (dataItem, prevDataItem, nextRootId) => {
  if (Array.isArray(dataItem)) {
    dataItem.forEach((subData, index, array) => {
      const nextRoot = nextRootId || subData.nextRootId || null;
      const prevItem = prevDataItem || array[index - 1];
      recursiveNormalizer(subData, prevItem, nextRoot);
    });
  }
  if (!Array.isArray(dataItem)) {
    objectCase(dataItem, prevDataItem, nextRootId);
  }
};

export const questionsNormalizer = (arrayOfQuestions) => {
  res = {};
  const arrToRecurse = arrayOfQuestions.map(
    (item, index, array) => ({ ...item, nextRootId: array[index + 1]?.id }),
  );
  recursiveNormalizer(arrToRecurse);
  return res;
};
