import React, { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  checked?: boolean;
  onCardClick: () => void;
}

const CreditCardWrap: FC<Props> = ({ children, checked, onCardClick }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'primary.main',
      borderRadius: '8px',
      paddingX: '0.8rem',
      paddingY: '0.25rem',
      maxWidth: '85%',
      cursor: 'pointer',
      flexGrow: 1,
      backgroundColor: checked ? '#ccd1e8' : '',
      '&:hover': {
        backgroundColor: checked ? '#ccd1e8' : 'rgba(204,209,232,0.31)',
      },
      '&:active': {
        backgroundColor: '#ccd1e8',
      },
    }}
    onClick={onCardClick}
  >
    {children}
  </Box>
);

export default CreditCardWrap;
