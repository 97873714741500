import React, { FC } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from 'react-responsive';

interface Props {
  title: string;
  subtitle: string;
  mobileSubtitle?: string;
  isInfoOpened: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  titleStyle: {
    '&.MuiTypography-root': {
      fontSize: '16px',
      overflowWrap: 'anywhere',
    },
  },
  subTitleStyle: {
    '&.MuiTypography-root': {
      fontSize: '12px',
    },
  },
  mobileSubtitleStyle: {
    '&.MuiTypography-root': {
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
  },
}));

const TreatmentDetailsTitle: FC<Props> = ({
  title, subtitle, mobileSubtitle, isInfoOpened,
}) => {
  const { titleStyle, subTitleStyle, mobileSubtitleStyle } = useStyles();
  const isCollapseSize = useMediaQuery({ query: '(max-width: 899px)' });
  return (
    <>
      <Typography variant="body1" fontWeight="600" className={titleStyle}>
        {title}
      </Typography>
      {(isInfoOpened || !isCollapseSize) && (
        <Typography
          variant="caption"
          color="secondary.main"
          mt="-0.4rem"
          className={subTitleStyle}

        >
          {subtitle}
        </Typography>
      )}
      {(mobileSubtitle && !isInfoOpened && isCollapseSize) && (
        <Typography
          variant="caption"
          color="secondary.main"
          mt="-0.4rem"
          className={mobileSubtitleStyle}
        >
          {mobileSubtitle}
        </Typography>
      )}
    </>
  );
};

export default TreatmentDetailsTitle;
