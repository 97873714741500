import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import AddAddressView from './AddAddressView';
import { useDispatch, useSelector } from '../../store';
import { setCurrentAddressId } from '../../store/slices/address';
import { DeliveryViewSteps } from '../../containers/containersTypes';
import BackdropLoad from '../../components/common/BackdropLoad';
import { ROUTE_LINKS } from '../../navigation/routeLinks';
import useFormLinkTo from '../../hooks/useFormLinkTo';
import { handleError } from '../../store/slices/notifier/notifier';
import CustomError from '../../store/slices/notifier/customErrorClass';
import { customErrors } from '../../store/slices/notifier/errorObject';
import { createOrderByAppointmentId, postDeliveryAddressIntoOrder } from '../../store/slices/order';
import { OptionalOrderParams } from '../../types/navigation';

/**
The component was created as a container to alternately display a choice of delivery address and delivery time.
Delivery time is temporarily canceled and commented out.
Makes a request to create an order and add an address to the order.
*/
const DeliveryView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<OptionalOrderParams>();
  const appointmentLoading = useSelector<any | null>((state) => state.appointment.loading);
  const ordersLoading = useSelector<any | null>((state) => state.orders.loading);
  const currentOrder = useSelector((state) => state.orders.currentOrder);
  const [localStep] = useState<DeliveryViewSteps>('addAddress');
  const formLinkTo = useFormLinkTo();

  // ! helpers
  const createNewOrder = async (appointmentId: string | number) => {
    try {
      const wrappedOrder = await dispatch(createOrderByAppointmentId(appointmentId));
      const unwrappedOrder = unwrapResult(wrappedOrder);
      return unwrappedOrder?.id;
    } catch (e) {
      console.log('can not create new order');
    }
  };
  const getOrderId = async () => {
    const { appointmentId } = params;
    if (!appointmentId) {
      dispatch(handleError(new CustomError(customErrors.CAN_NOT_CREATE_APPOINTMENT)));
      return null;
    }
    // eslint-disable-next-line prefer-destructuring
    let orderId: string | number | null | undefined = params.orderId || currentOrder?.id;
    if (!orderId) orderId = await createNewOrder(appointmentId);
    if (!orderId) {
      dispatch(handleError(new CustomError(customErrors.CAN_NOT_CREATE_ORDER)));
      return null;
    }
    return orderId;
  };
  const postDeliveryAddressFlow = async (addressId: string | number) => {
    const orderId = await getOrderId();
    if (!orderId) return null;
    if (addressId) await dispatch(postDeliveryAddressIntoOrder({ orderId, addressId }));
    return orderId;
  };
  const navigateToCheckout = (orderId: string | number) => {
    const navURL = formLinkTo({ orderIdArg: orderId, mainPath: ROUTE_LINKS.CHECKOUT });
    navigate({ pathname: navURL });
  };

  // ! handlers
  // const onCalendarSubmit = async (selectedDate: Date, selectedTime: string) => {
  //   dispatch(setCurrentDateTime({ date: selectedDate, time: selectedTime }));
  // };

  const onSkipClick = async () => {
    const orderId = await getOrderId();
    if (!orderId) return;
    navigateToCheckout(orderId);
  };

  // const onChangeAddress = () => {
  //   setLocalStep('addAddress');
  // };

  const hasAddressSubmit = async (addressId: number) => {
    dispatch(setCurrentAddressId(addressId));
    const orderId = await postDeliveryAddressFlow(addressId);
    if (!orderId) return;
    navigateToCheckout(orderId);
  };

  // ! render
  const renderHelper = () => {
    switch (localStep) {
      case 'addAddress':
        return <AddAddressView onSkipClick={onSkipClick} hasAddressSubmit={hasAddressSubmit} />;
      // case 'calendar':
      //   return (
      //     <Calendar
      //       address={`${currentAddress?.address_line1 || ''} ${currentAddress?.address_line2 || ''}`}
      //       onCalendarSubmit={onCalendarSubmit}
      //       onChangeAddress={onChangeAddress}
      //     />
      //   );
      default:
        return null;
    }
  };
  return (
    <>
      {(appointmentLoading || ordersLoading) && <BackdropLoad />}
      {renderHelper()}
    </>
  );
};

export default DeliveryView;
