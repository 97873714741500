import React, { useEffect, useState } from 'react';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { cleanNotifierMessages } from '../store/slices/notifier/notifier';
import { useDispatch, useSelector } from '../store';
import { useAuth } from '../navigation/AuthProvider';

/**
Component for centralized notifications and errors display.
*/
const PracticeNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { onLogout } = useAuth();
  const [snackKey, setSnackKey] = useState<SnackbarKey | null>(null);

  // ! selectors
  const { errorMessage, notificationMessage, duration } = useSelector((state) => state.notifier);

  // ! effects
  useEffect(() => {
    if (!errorMessage?.message) return;
    const { message, is401 } = errorMessage;
    const newSnackKey = enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      variant: 'error',
    });
    setSnackKey(newSnackKey);
    if (is401) onLogout();
  }, [errorMessage]);

  useEffect(() => {
    if (!notificationMessage?.message) return;
    const { message, variant } = notificationMessage || {};
    const newSnackKey = enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      variant: variant as VariantType,
    });
    setSnackKey(newSnackKey);
  }, [notificationMessage]);

  useEffect(() => {
    if (!snackKey) return;
    setTimeout(() => {
      closeSnackbar(snackKey);
      dispatch(cleanNotifierMessages());
      setSnackKey(null);
    }, duration || 3000);
  }, [snackKey]);
  return null;
};

export default PracticeNotify;
