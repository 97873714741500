import React, { ChangeEvent, FC } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  value: string;
}

const QuestionnaireTextarea: FC<Props> = ({ onChange, value }) => {
  const { t } = useTranslation();
  return (
    <TextField
      placeholder={t('questionnaire.textareaPlaceholder')}
      value={value}
      autoFocus
      multiline
      rows={7}
      fullWidth
      onChange={onChange}
    />
  );
};

export default QuestionnaireTextarea;
