import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Header from '../components/Header';
import useIsMobileCheckout from '../hooks/useIsMobileCheckout';

const mainBox = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

// adds Header to children
function MainLayout() {
  const isMobileCheckout = useIsMobileCheckout();
  return (
    <Box sx={mainBox}>
      {!isMobileCheckout && <Header />}
      <Outlet />
    </Box>
  );
}

export default MainLayout;
