import React, { FC } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import i18n from 'i18next';

interface Props {
  activeStep: number;
}

const ProgressStepper: FC<Props> = ({ activeStep }) => {
  const steps = [
    i18n.t('progressBar.questionnaire'),
    i18n.t('progressBar.delivery'),
    i18n.t('progressBar.checkout'),
  ];
  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={{ maxWidth: '1021px', margin: 'auto', width: '100%' }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressStepper;
