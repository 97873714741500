import httpService from '../httpService';
import { apiEndpoints } from './apiEndpoints';
import { IServiceMethodFunc } from '../types/services';
import { IUserProfile } from '../types/user';

interface IProfileService {
  getProfile: IServiceMethodFunc<void, IUserProfile>;
  patchProfile: IServiceMethodFunc<any>;
}

const profileService: IProfileService = {
  getProfile: () => httpService.get(apiEndpoints.PROFILE),
  patchProfile: (data: any) => httpService.patch(apiEndpoints.PROFILE, data),
};

export default profileService;
