/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError, notifyUser } from './notifier/notifier';
import profileService from '../../services/profile';
import { IUserProfile } from '../../types/user';
import { IActionArg, IDefaultStateFields } from '../../types/store';
import { customNotifications } from './notifier/notificationObject';

interface IUserSlice extends IDefaultStateFields {
  userProfile: IUserProfile | null;
  locale: string;
}

const userSliceInitialState: IUserSlice = {
  loading: false,
  userProfile: null,
  locale: 'en',
};

export const fetchUserProfile = createAsyncThunk(
  'userSlice/fetchUserProfile',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const prom = await profileService.getProfile();
      return prom.data;
    } catch (e: any) {
      if (e?.response?.status === 422) {
        dispatch(notifyUser({
          variant: 'success',
          message: customNotifications.FILL_PROFILE,
          duration: 5000,
        }));
        return rejectWithValue(e);
      }
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const patchUserProfile = createAsyncThunk(
  'userSlice/patchUserProfile',
  async (userInfo: Partial<IUserProfile>, { rejectWithValue, dispatch }) => {
    try {
      const prom = await profileService.patchProfile(userInfo);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState: userSliceInitialState,
  reducers: {
    logout() {},
    setLocale(state, { payload }: IActionArg<string>) {
      state.locale = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.pending, pendingCase)
      .addCase(fetchUserProfile.rejected, errorCase)
      .addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userProfile = payload;
      });
    builder.addCase(patchUserProfile.pending, pendingCase)
      .addCase(patchUserProfile.rejected, errorCase)
      .addCase(patchUserProfile.fulfilled, (state, { payload }) => {
        state.userProfile = payload;
        state.loading = false;
      });
  },
});

export const {
  logout, setLocale,
} = userSlice.actions;

export default userSlice.reducer;
