import React, {
  Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import Box from '@mui/material/Box';
import ModalSearchLocation from '../ModalSearchLocation/ModalSearchLocation';
import ModalEditAddress from '../ModalEditAddress';
import { Backdrop, ModalBox, StyledModal } from './styledComponents';
import { IAddress } from '../../../../types/user';
import { EditAddressInfo, MapModalVariant } from '../../../../types/address';

interface Props {
  onSave: (modalInfo: EditAddressInfo) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  addrToEdit?: IAddress | null;
  variant?: MapModalVariant;
}

const ModalWithMap: FC<Props> = ({
  onSave,
  open,
  setOpen,
  addrToEdit,
  variant,
}) => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [address, setAddress] = useState<string>('');
  const [step, setStep] = useState<'search' | 'edit'>('search');

  // ! handlers
  const handleClose = (): void => {
    setOpen(false);
    setAddress('');
    setStep('search');
  };
  const handleConfirm = (newCoordinates: google.maps.LatLngLiteral, newAddress: string) => {
    if (!newAddress) return;
    setAddress(newAddress);
    setCoordinates(newCoordinates);
    setStep('edit');
  };

  // ! effects
  useEffect(() => {
    if (!addrToEdit) return;
    const {
      lat,
      long,
      address_line1,
      address_line2,
    } = addrToEdit;
    const numerizedCoords: google.maps.LatLngLiteral = {
      lat: Number.parseFloat(lat) || 0,
      lng: Number.parseFloat(long) || 0,
    };
    let searchAddressLine = `${address_line1 || ''}`;
    if (address_line2) searchAddressLine += ` ${address_line2}`;
    setCoordinates(numerizedCoords);
    setAddress(searchAddressLine);
  }, [addrToEdit]);

  // ! render
  if (!open) return null;
  const renderHelp = () => {
    if (step === 'edit') {
      return (
        <ModalEditAddress
          onClose={handleClose}
          coords={coordinates}
          setCoords={setCoordinates}
          address={address}
          onSave={onSave}
          variant={variant}
        />
      );
    }
    return (
      <ModalSearchLocation
        onClose={handleClose}
        onNext={handleConfirm}
        existingCoors={coordinates}
        exisingAddress={address}
        variant={variant}
      />
    );
  };

  return (
    <Box sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <StyledModal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <ModalBox>
          {renderHelp()}
        </ModalBox>
      </StyledModal>
    </Box>
  );
};

export default ModalWithMap;
