import React from 'react';
import { Backdrop } from '@mui/material';
import SpinningIcon from '../../assets/icons/SpinningIcon';

const BackdropLoad = () => (
  <Backdrop
    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(9,1,1,0.24)' }}
    open
  >
    <SpinningIcon width={100} />
  </Backdrop>
);

export default BackdropLoad;
