import React, { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MapInfoWindow = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxWidth: '296px' }}>
      <Typography sx={{
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        fontSize: '16px',
        fontWeight: '500',
        textAlign: 'center',
      }}
      >
        {t('googleMap.editLocation.mapInfoPopup')}
      </Typography>
    </Box>
  );
};

export default MapInfoWindow;
