import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  Outlet, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { fetchAppointmentById } from '../store/slices/appointment';
import { fetchOrderById, fetchPaymentMethods } from '../store/slices/order';
import { useDispatch, useSelector } from '../store';
import WelcomeTitle from '../components/WelcomeTitle';
import ProgressStepper from '../components/ProgressStepper';
import { ROUTE_LINKS } from '../navigation/routeLinks';
import BackdropLoad from '../components/common/BackdropLoad';
import { OptionalOrderParams } from '../types/navigation';
import { fetchUserAddresses } from '../store/slices/address';
import useIsMobileCheckout from '../hooks/useIsMobileCheckout';
import { gaService } from '../helpers/googleAnalytics';

/**
  The component reuses the same UI elements for Delivery and Checkout pages.
  Requests related data for these pages
*/
const DeliveryCheckoutLayout = () => {
  const dispatch = useDispatch();
  const params = useParams<OptionalOrderParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({
    payment: false,
    appointment: false,
    order: false,
  });
  const isMobileCheckout = useIsMobileCheckout();

  // ! selectors
  const userCountryId = useSelector((state) => state.user.userProfile?.country_id);
  const userFirstName = useSelector((state) => state.user.userProfile?.first_name || '');
  const currentAppointment = useSelector<any | null>((state) => state.appointment.currentAppointment);
  const currentOrder = useSelector((state) => state.orders.currentOrder);
  const paymentMethods = useSelector((state) => state.orders.paymentMethods);

  // ! memos
  const progressStep = useMemo(() => {
    const { pathname } = location;
    if (pathname?.includes(ROUTE_LINKS.DELIVERY)) return 1;
    return 2;
  }, [location]);
  const localLoading = useMemo(() => {
    const { order, payment, appointment } = loadingState;
    return order || payment || appointment;
  }, [loadingState]);

  // ! helpers
  const setLoadingItem = (fieldName: 'order' | 'payment' | 'appointment', status: boolean) => {
    setLoadingState((prev) => ({
      ...prev,
      [fieldName]: status,
    }));
  };

  // ! effects
  useEffect(() => {
    dispatch(fetchUserAddresses());
  }, []);
  useEffect(() => {
    if (!userCountryId || paymentMethods) return;
    setLoadingItem('payment', true);
    dispatch(fetchPaymentMethods(userCountryId))
      .finally(() => setLoadingItem('payment', false));
  }, [userCountryId, paymentMethods]);
  useEffect(() => {
    if (!params || currentAppointment) return;
    const { appointmentId, orderId } = params;
    if (appointmentId && !currentAppointment) {
      setLoadingItem('appointment', true);
      dispatch(fetchAppointmentById(appointmentId))
        .finally(() => setLoadingItem('appointment', false));
    }
    if (orderId && !currentOrder) {
      setLoadingItem('order', true);
      dispatch(fetchOrderById(orderId))
        .finally(() => setLoadingItem('order', false));
    }
  }, [params, currentAppointment]);

  /*
    The effect observes the order. As soon as the order receives the status of finished - redirects the user to the
    final page of the application
  */
  useEffect(() => {
    if (!currentOrder?.is_locked) return;
    gaService.order_confirmed();
    navigate({ pathname: ROUTE_LINKS.THANK_YOU });
  }, [currentOrder]);

  // ! render
  return (
    <Box
      className="delivery-layout"
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {localLoading && <BackdropLoad />}
      {!isMobileCheckout && <WelcomeTitle userFirstName={userFirstName} />}
      {!isMobileCheckout && <ProgressStepper activeStep={progressStep} />}
      <Outlet />
    </Box>
  );
};

export default DeliveryCheckoutLayout;
