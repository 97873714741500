/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError } from './notifier/notifier';
import { IAddress } from '../../types/user';
import addressService from '../../services/address';
import { IActionArg, IDefaultStateFields } from '../../types/store';

interface IAddressSlice extends IDefaultStateFields {
  userAddresses: IAddress[];
  currentAddressId: number | null;
}

const addressSliceInitialState: IAddressSlice = {
  userAddresses: [],
  loading: false,
  currentAddressId: null,
};

export const fetchUserAddresses = createAsyncThunk(
  'addressSlice/fetchUserAddresses',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const prom = await addressService.fetchAddresses();
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const updateUserAddress = createAsyncThunk(
  'addressSlice/updateUserAddress',
  async (address: IAddress, { rejectWithValue, dispatch }) => {
    try {
      const prom = await addressService.updateAddress(address);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);
export const postNewAddress = createAsyncThunk(
  'addressSlice/postNewAddress',
  async (address: Partial<IAddress>, { rejectWithValue, dispatch }) => {
    try {
      const prom = await addressService.postNewAddress(address);
      return prom.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

const addressSlice = createSlice({
  name: 'addressSlice',
  initialState: addressSliceInitialState,
  reducers: {
    logout() {},
    setCurrentAddressId(state, { payload }: IActionArg<number>) {
      state.currentAddressId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAddresses.pending, pendingCase)
      .addCase(fetchUserAddresses.rejected, errorCase)
      .addCase(fetchUserAddresses.fulfilled, (state, { payload }) => {
        state.userAddresses = payload;
        state.loading = false;
      });
    builder.addCase(updateUserAddress.pending, pendingCase)
      .addCase(updateUserAddress.rejected, errorCase)
      .addCase(updateUserAddress.fulfilled, (state, { payload: updatedAddress }: IActionArg<IAddress>) => {
        state.loading = false;
        const index = state.userAddresses.findIndex((address) => address.id === updatedAddress.id);
        if (index === -1) return;
        state.userAddresses[index] = updatedAddress;
      });
    builder.addCase(postNewAddress.pending, pendingCase)
      .addCase(postNewAddress.rejected, errorCase)
      .addCase(postNewAddress.fulfilled, (state, { payload: newAddress }: IActionArg<IAddress>) => {
        state.userAddresses.push(newAddress);
        state.loading = false;
      });
  },
});

export const {
  logout,
  setCurrentAddressId,
} = addressSlice.actions;

export default addressSlice.reducer;
