import { ISelector } from '../../types/store';

// eslint-disable-next-line max-len
export const getCountriesNamesAndPrefixes: ISelector<void, { prefix: string, name: string, current: boolean }[]> = (store) => {
  if (!store.countries.countriesList?.length) return [];
  const canSmsCountries = store.countries.countriesList.filter((country) => country.sms_delivery);
  if (!canSmsCountries.length) return [];
  const prefixes = canSmsCountries.map((country) => ({
    prefix: country.phone_prefix,
    name: country.name,
    current: country.current_user_country,
  }));
  if (!prefixes.length) return [];
  const uniquePrefixes = Array.from(new Set(prefixes));
  if (!uniquePrefixes?.length) return [];
  return uniquePrefixes.sort((a, b) => Number.parseInt(a.prefix, 10) - Number.parseInt(b.prefix, 10));
};
