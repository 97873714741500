import { IServiceMethodFunc } from '../types/services';
import httpService from '../httpService';
import { PatientQuestionnaireResponse } from '../types/appointments';

export type CreateAppointmentArg = {
  appointment_type: string;
  expects_order: boolean;
  symptom_id: string | number;
};

interface IAppointmentsService {
  createAppointment: IServiceMethodFunc<CreateAppointmentArg>;
  fetchAppointmentById: IServiceMethodFunc<number | string>;
  patchAppointmentById: IServiceMethodFunc<{ id: number, newInfo: any }>;
  postQuestionnaireResponses: IServiceMethodFunc<{
    questionnaireResponse: PatientQuestionnaireResponse,
    appointmentId: number
  }>;
}

const appointmentsService: IAppointmentsService = {
  patchAppointmentById: ({ newInfo, id }) => httpService.patch(`appointments/${id}`, newInfo),
  createAppointment: (arg) => httpService.post('appointments', arg),
  fetchAppointmentById: (userId) => httpService.get(`appointments/${userId}`),
  postQuestionnaireResponses: ({ questionnaireResponse, appointmentId }) => httpService.post(
    `appointments/${appointmentId}/patient_responses`,
    questionnaireResponse,
  ),
};

export default appointmentsService;
