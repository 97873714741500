import { createTheme, ThemeOptions } from '@mui/material';

// ! https://mui.com/material-ui/guides/right-to-left/#2-theme

interface CustomThemeOptions extends ThemeOptions {
  type: string;
}

const ltrThemeOptions: CustomThemeOptions = {
  type: 'ltr',
  direction: 'ltr',
  palette: {
    primary: {
      main: '#005cb1',
      contrastText: '#ffffff',
    },
    success: {
      main: '#00b4b0',
      contrastText: '#fffdfd',
    },
    secondary: {
      main: '#707070',
      light: '#b5b5b5',
      contrastText: '#fff',

    },
  },
  typography: {
    button: {
      textTransform: 'none', // Using this will stop transforming all button texts to UPPERCASE
    },
    fontFamily: 'Inter, sans-serif',
  },
};

const rtlThemeOptions: CustomThemeOptions = {
  ...ltrThemeOptions,
  typography: {
    ...ltrThemeOptions.typography,
    fontFamily: 'Noto Kufi Arabic, sans-serif',
  },
  type: 'rtl',
  direction: 'rtl',
};

export const ltr = () => (createTheme(ltrThemeOptions));

export const rtl = () => (createTheme(rtlThemeOptions));
