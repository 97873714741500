// eslint-disable-next-line import/prefer-default-export
export const ROUTE_LINKS = {
  ROOT: '/',
  HOME: '/home',
  QUESTIONNAIRE: '/questionnaire',
  DELIVERY: '/delivery',
  CHECKOUT: '/checkout',
  TREATMENT: '/treatment',
  THANK_YOU: '/thankyou',
};
