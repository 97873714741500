import { IServiceMethodFunc } from '../types/services';
import httpService from '../httpService';
import { ISymptom } from '../types/symptoms';

interface ISymptomsService {
  fetchSymptomBySlug: IServiceMethodFunc<string, ISymptom[]>;
}

const symptomsService: ISymptomsService = {
  fetchSymptomBySlug: (symptomSlug) => httpService.get(`/symptoms?slug=${symptomSlug}`),
};

export default symptomsService;
