import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ROUTE_LINKS } from '../navigation/routeLinks';

/**
  Returns true if the user is on page Checkout and the screen width of his device is smaller then 900px
*/
const useIsMobileCheckout = () => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const location = useLocation();

  return useMemo(() => {
    const isCheckout = location.pathname.includes(ROUTE_LINKS.CHECKOUT);
    return isMobile && isCheckout;
  }, [isMobile, location]);
};
export default useIsMobileCheckout;
