import React, { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormLeftSide from '../../../components/PaymentMethod/FormLeftSide';
import FormRightSide from '../../../components/PaymentMethod/FormRightSide';
import PaymentBottom from '../../../components/PaymentMethod/PaymentBottom/PaymentBottom';
import { IAddress, IDateTime } from '../../../types/user';

interface Props {
  onPlaceRequest: () => void;
  onAddrEdit: () => void;
  onTimeEdit: () => void;
  userEmail: string;
  userFullName: string;
  currentDateTime: IDateTime | null;
  currentAddress: IAddress | null;
  infoHeight: number;
}

const PaymentMethod: FC<Props> = ({
  onPlaceRequest,
  onAddrEdit,
  userFullName,
  userEmail,
  currentDateTime,
  currentAddress,
  onTimeEdit,
  infoHeight,
}) => {
  const ScrollGrid = useMemo(() => {
    const desktopHeight = 390 + (infoHeight / 2);
    const mobileHeight = 200 + 5 + (infoHeight / 2);
    return styled(Grid)`
      max-height: calc(100vh - ${desktopHeight}px);
      min-height: 150px;
      overflow-y: auto;
      @media all and (max-width: 900px) {
        max-height: calc(100vh - ${mobileHeight}px);
      }
    `;
  }, [infoHeight]);
  return (
    <>
      <ScrollGrid
        container
        justifyContent="center"
      >

        {/* LEFT */}
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormLeftSide
            onAddrEdit={onAddrEdit}
            onTimeEdit={onTimeEdit}
            userFullName={userFullName}
            userEmail={userEmail}
            currentDateTime={currentDateTime}
            currentAddress={currentAddress}
          />
        </Grid>

        {/* RIGHT */}
        <Grid item xs={12} md={6}>
          <FormRightSide />
        </Grid>

      </ScrollGrid>
      <PaymentBottom onPlaceRequest={onPlaceRequest} />
    </>
  );
};

export default PaymentMethod;
