import React, { ReactElement } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { SymptomParams } from '../types/navigation';
import { ROUTE_LINKS } from './routeLinks';

/**
The component checks for user authentication.
If there is a token, it allows access to the page.
If not present, redirects the user to the home page
*/
const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const { token } = useAuth();
  const location = useLocation();
  const params = useParams<SymptomParams>();

  const goHomeUrl = () => {
    if (!params.symptomSlug) return 'not-found';
    return `${ROUTE_LINKS.HOME}/${params.symptomSlug}`;
  };
  if (!token) return <Navigate to={goHomeUrl()} replace state={{ from: location }} />;

  return children;
};

export default ProtectedRoute;
