import React, { FC } from 'react';
import { Box, Modal } from '@mui/material';
import AuthContainer from '../../pages/Auth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  fontFamily: 'system-ui',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5',
  maxWidth: '95%',
};

interface Props {
  open: boolean;
  submitAuth: () => void;
}

const AuthModal: FC<Props> = ({ open, submitAuth }) => (
  <Modal open={open}>
    <Box sx={style}>
      <AuthContainer submitAuth={submitAuth} />
    </Box>
  </Modal>
);

export default AuthModal;
