import React, { FC } from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// ! https://mui.com/material-ui/guides/right-to-left/#4-load-the-rtl-plugin
const RTL: FC<{ isRight: boolean, divDirection: string }> = ({ children, isRight, divDirection }) => (
  <div id="direction-wrap" dir={divDirection}>
    {
      isRight
        ? <CacheProvider value={cacheRtl}>{children}</CacheProvider>
        // eslint-disable-next-line react/jsx-no-useless-fragment
        : <>{children}</>
    }
  </div>
);

export default RTL;
