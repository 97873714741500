import React, { FC, useMemo } from 'react';
import {
  Box, Grid, Paper, Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import TreatmentDetailsTitle from '../TreatmentDetailsTitle';
import PriceBlock from '../VariantsListItem/PriceBlock';

const useStyles = makeStyles((theme: Theme) => ({
  changePositionBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleWrap: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.direction === 'ltr' ? '1.4rem' : '0',
      marginRight: theme.direction === 'rtl' ? '1.4rem' : '0',
    },
  },
  iconWrap: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  imageBox: {},
  imageWrapPaper: {
    width: '219px',
    height: '219px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '150px',
    },
  },
}));

const ChangeFlexDirectionBox = styled(Box)`
  @media all and (max-width: 899px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

interface FirstVariantInfo {
  currency_code_iso: string;
  sale_price: number;
  subtitle: string;
  unit_price: number;
}

interface Props {
  firstVariantInfo: FirstVariantInfo | null;
  title: string;
  subtitle: string;
  imageUrl: string;
  isInfoOpened: boolean;
  disableOpenClose?: boolean;
  onDetailsClick: () => void;
}

const LeftSideTreatmentDetails: FC<Props> = ({
  firstVariantInfo,
  subtitle,
  title,
  imageUrl,
  isInfoOpened,
  onDetailsClick,
  disableOpenClose,
}) => {
  const {
    titleWrap,
    changePositionBox,
    iconWrap,
    imageBox,
    imageWrapPaper,
  } = useStyles();
  const actionArrow = useMemo(() => {
    if (disableOpenClose) return null;
    return isInfoOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
  }, [disableOpenClose, isInfoOpened]);
  const isCollapseSize = useMediaQuery({ query: '(max-width: 899px)' });
  return (
    <Grid item xs={12} md={4} onClick={onDetailsClick}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: disableOpenClose ? 'unset' : 'pointer',
        }}
      >
        <ChangeFlexDirectionBox>
          {/* TITLE */}
          <Box
            className={[changePositionBox, titleWrap].join(' ')}
            sx={{
              marginBottom: '2.5rem',
            }}
          >
            <TreatmentDetailsTitle
              title={title}
              subtitle={subtitle}
              mobileSubtitle={firstVariantInfo?.subtitle}
              isInfoOpened={isInfoOpened}
            />
            {(firstVariantInfo && !isInfoOpened && isCollapseSize) && (
              <Box>
                <PriceBlock
                  hideDiscount
                  priceTextAlign="left"
                  currency={firstVariantInfo.currency_code_iso}
                  salePrice={firstVariantInfo.sale_price}
                  unitPrice={firstVariantInfo.unit_price}
                />
              </Box>
            )}
          </Box>
          {/* IMAGE BOX */}
          <Box className={imageBox}>
            <Paper
              sx={{
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)!important',

                padding: '1rem',
              }}
              className={imageWrapPaper}
            >
              <img
                src={imageUrl}
                alt=""
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </Paper>
          </Box>
        </ChangeFlexDirectionBox>

        {/* SHOW INFO ARROW */}
        <Box className={iconWrap}>
          {actionArrow}
        </Box>
      </Box>
    </Grid>
  );
};

export default LeftSideTreatmentDetails;
