import React, {
  ChangeEvent, FC, useLayoutEffect, useState,
} from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import closeModalIcon from '../../images/Icon metro-cross.svg';
import MapComponent from '../MapComponent';
import { MapModalVariant } from '../../../../types/address';

interface IModalSearchLocationProps {
  existingCoors: google.maps.LatLngLiteral;
  exisingAddress: string;
  variant?: MapModalVariant;

  onClose(): void;

  onNext(place: {}, address: string): void;
}

const ModalSearchLocation: FC<IModalSearchLocationProps> = ({
  onClose, onNext, exisingAddress, existingCoors, variant,
}) => {
  const [address, setAddress] = useState('');
  const [coords, setCoords] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });
  const [canProceed, setCanProceed] = useState(false);
  const { t } = useTranslation();

  // ! handlers
  const onAutocompleteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value || '');
  };
  const getPlace = (newPlace: google.maps.places.PlaceResult, ref: React.RefObject<HTMLInputElement>) => {
    const inputVal = (ref as unknown as HTMLInputElement).value || ref.current?.value;
    const { formatted_address } = newPlace;
    if (formatted_address) setAddress(formatted_address);
    if (!formatted_address && inputVal) setAddress(inputVal);
    if (!newPlace) return;
    const lat = newPlace.geometry?.location?.lat();
    const lng = newPlace.geometry?.location?.lng();
    if (lat === undefined || lng === undefined) return;
    setCoords({
      lat,
      lng,
    });
    setCanProceed(true);
  };
  const onReset = (e: MouseEvent) => {
    e.preventDefault();
    const input: HTMLInputElement | null = document.getElementById(
      'gmap_autocomplete_input',
    ) as HTMLInputElement | null;
    if (!input) return;
    input.value = '';
    setAddress('');
    setCanProceed(false);
  };
  const onNextPress = () => {
    if (!canProceed) return;
    onNext(coords, address);
  };

  // ! effects
  useLayoutEffect(() => {
    if (!exisingAddress || !existingCoors) return;
    setAddress(exisingAddress);
    setCoords(existingCoors);
    setCanProceed(true);
  }, [existingCoors, exisingAddress]);

  // ! render
  return (
    <Paper
      sx={{
        width: '100%',
        borderRadius: '8px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginX: '8px',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '1.2rem',
            paddingBottom: '0.625rem',
            paddingX: '1.7rem',
          }}
        >
          <Typography
            variant="h5"
            width="100%"
            fontWeight="600"
            color="primary"
          >
            {t('googleMap.searchLocation.searchLocationTitle')}
          </Typography>
          <IconButton onClick={onClose}>
            <img
              src={closeModalIcon}
              alt="close"
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MapComponent
          setCoords={setCoords}
          getPlace={getPlace}
          address={address}
          onReset={onReset}
          withAutocomplete
          coords={coords}
          variant={variant}
          onAutocompleteChange={onAutocompleteChange}
        />
      </Box>

      <Box className="flex-center" sx={{ flexBasis: '4.5rem' }}>
        <Button
          onClick={onNextPress}
          variant="contained"
          color="primary"
          disabled={!canProceed}
          sx={{
            // color: '#ffffff',
            borderRadius: '6px',
            paddingX: '2.6rem',
            fontSize: '16px',
          }}
        >
          {t('buttons.next')}
        </Button>
      </Box>
    </Paper>
  );
};

export default ModalSearchLocation;
