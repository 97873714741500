import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const ErrorLine = () => (
  <Box
    sx={{
      backgroundColor: '#f8cccc',
      paddingY: '0.5rem',
      paddingX: '1rem',
      borderRadius: '4px',
      display: 'flex',
      marginBottom: '15px',
      position: 'relative',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexBasis: '2.5rem',
        justifyContent: 'space-between',
      }}
    >
      <ReportProblemOutlinedIcon sx={{ color: '#740000', marginRight: '1rem' }} />
      <Divider orientation="vertical" sx={{ display: 'inline-block' }} />
    </Box>
    <Typography color="#740000" fontWeight={600} textAlign="center" flexGrow={1}>
      Lorem ipsum dolores est lorem ipsum dolores est nor lorem ipsum est ipsum dolores
    </Typography>
    <Box
      sx={{
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '15px 15px 0 15px',
        borderColor: '#f8cccc transparent transparent transparent',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    />
  </Box>
);

export default ErrorLine;
