import React, { ChangeEvent, FC, useState } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledSmall = styled('small')(
  ({ theme }) => `
    font-family: ${theme.direction === 'ltr' ? 'Inter, sans-serif' : 'Noto Kufi Arabic, sans-serif;'};
  `,
);

interface Props {
  phoneNumber: string;
  handleLogin: (otp: string) => void;
  onWrongNumberEntered: () => void;
  onResendOtp: () => void;
}

const VerifyOtp: FC<Props> = ({
  phoneNumber, handleLogin, onWrongNumberEntered, onResendOtp,
}) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');

  // ! handlers
  const onOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setOtp(val);
  };
  const onOtpSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!otp) return;
    handleLogin(otp);
  };

  // ! render
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TaskAltIcon sx={{ m: 1, color: 'primary.main' }} />
        <Typography variant="h6" color="primary.main" fontWeight={600}>
          {t('phoneLogIn.title')}
        </Typography>
        <Typography variant="subtitle2" fontWeight={500}>
          {t('otpLogIn.instructions')}
          {' '}
          {phoneNumber}
        </Typography>
        <Box
          component="form"
          onSubmit={onOtpSubmit}
          noValidate
          sx={{ mt: 1 }}
          textAlign="center"
        >
          <TextField
            value={otp}
            onChange={onOtpChange}
            autoFocus
            margin="normal"
            required
            id="otp"
            label={t('otpLogIn.inputLabel')}
            name="otp"
            autoComplete="OTP"
            size="small"
            sx={{
              '& input': {
                textAlign: 'left',
              },
            }}
          />
          <br />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            variant="subtitle2"
            onClick={onResendOtp}
            color="success.main"
            underline="none"
            fontWeight={500}
          >
            {t('buttons.resendOtp')}
          </Link>
          <br />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <StyledSmall
            style={{ color: 'grey', cursor: 'pointer' }}
            className="wrong-number-link"
            onClick={onWrongNumberEntered}
          >
            {t('buttons.wrongNumber')}
          </StyledSmall>
          <br />
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={!otp}
            sx={{
              mt: 3,
              mb: 2,
              fontWeight: 600,
              px: '2rem',
              fontSize: '15px',
            }}
          >
            {t('buttons.verify')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyOtp;
