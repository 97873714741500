import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  formLabel: {
    border: `solid 2px ${theme.palette.primary.dark}`,
    borderRadius: '6px',
    minWidth: '300px',
    marginBottom: '26px',
    padding: '0 5px 0 15px',
    marginLeft: '0!important',
    marginRight: '0!important',
    '& .MuiRadio-root': {
      padding: '7px',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  checkedLabel: {
    backgroundColor: '#cddce9',
  },
}));
