import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import CardIconLine from '../PaymentMethod/FormLeftSide/CardIconLine';
import { IAddress } from '../../types/user';

interface HasAddressItemProps {
  addressItem: IAddress;
  onIconClick: () => void;
  addressValue: number | null;
}

interface StyledControlProps extends FormControlLabelProps{
  isbold: number;
}

const StyledControl = styled(FormControlLabel)<StyledControlProps>(({ isbold }) => ({
  '& .MuiTypography-root': {
    fontWeight: isbold ? '600' : 'normal',
  },
}));

const HasAddressItem: FC<HasAddressItemProps> = ({ addressItem, onIconClick, addressValue }) => {
  const {
    id,
    name,
    address_line1,
    address_line2,
  } = addressItem;
  const isChecked = id === addressValue;
  return (
    <CardIconLine onIconClick={onIconClick}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <StyledControl
          value={id}
          control={(<Radio checked={isChecked} />)}
          label={name}
          isbold={+isChecked}
        />
        <Typography variant="caption" mt="-0.5rem" ml="1.9rem" fontWeight={isChecked ? '600' : 'normal'}>
          {`${address_line1 || ''} ${address_line2 || ''}`}
        </Typography>
      </Box>
    </CardIconLine>
  );
};

export default HasAddressItem;
