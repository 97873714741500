import React, { FC, useMemo, useState } from 'react';
import {
  Box, Grid, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ITreatment } from '../../types/treatment';
import VariantsList from './VariantsList';
import LeftSideTreatmentDetails from './TreatmentDetails/LeftSideTreatmentDetails';

const useStyles = makeStyles((theme: Theme) => ({
  rightSideGrid: {
    [theme.breakpoints.down('md')]: {
      height: 0,
      opacity: 0,
      overflow: 'hidden',

      '&.open': {
        transition: 'opacity 1s ease',
        height: 'unset',
        opacity: 1,
      },
    },
  },
  descriptionStyle: {
    '&.MuiTypography-root': {
      fontSize: '14px',
    },
  },
}));

interface Props {
  treatmentItem: ITreatment;
  selectedTrVariant: number | null;
  onVariantSelect: (variantId: number | null, treatmentItem: ITreatment) => void;
  disableOpenClose?: boolean;
}

const TreatmentDetails: FC<Props> = ({
  treatmentItem,
  selectedTrVariant,
  onVariantSelect,
  disableOpenClose,
}) => {
  const {
    subtitle,
    description,
    title,
    image_url: imageUrl,
    treatment_variants: variants,
  } = treatmentItem;

  // ! styles
  const { rightSideGrid, descriptionStyle } = useStyles();

  const [isInfoOpened, setIsInfoOpened] = useState(!!disableOpenClose);

  // ! memos
  const firstVariantInfo = useMemo(() => {
    if (!variants?.length) return null;
    const firstVar = variants[0];
    const {
      sale_price, currency_code_iso, unit_price = sale_price, subtitle: variantSubtitle,
    } = firstVar;
    if (!sale_price || !currency_code_iso) return null;
    return {
      sale_price,
      currency_code_iso,
      unit_price,
      subtitle: variantSubtitle,
    };
  }, [variants]);

  // ! handlers
  const handleVariantSelect = (variantId: number | null) => {
    onVariantSelect(variantId, treatmentItem);
  };
  const handleDetailsClick = () => {
    setIsInfoOpened((prevState) => {
      if (disableOpenClose) return true;
      return !prevState;
    });
  };

  // ! render
  return (
    <Grid container spacing={2}>
      {/* LEFT SIDE */}
      <LeftSideTreatmentDetails
        onDetailsClick={handleDetailsClick}
        firstVariantInfo={firstVariantInfo}
        title={title}
        subtitle={subtitle}
        imageUrl={imageUrl}
        isInfoOpened={isInfoOpened}
        disableOpenClose={disableOpenClose}
      />

      {/* RIGHT SIDE */}
      <Grid
        item
        xs={12}
        md={8}
        className={[rightSideGrid, isInfoOpened ? 'open' : ''].join(' ')}
      >
        {/* DESCRIPTION */}
        <Typography variant="body2" mb="1.5rem" className={descriptionStyle}>
          {description}
        </Typography>
        {/* VARIANTS LIST */}
        <Box>
          <VariantsList
            variants={variants}
            selectedTrVariant={selectedTrVariant}
            onVariantSelect={handleVariantSelect}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TreatmentDetails;
