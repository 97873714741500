import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface Props {
  itemName: string;
}

const HasNoItems: FC<Props> = ({ itemName }) => (
  <Typography
    variant="body2"
    textAlign="center"
    fontWeight={600}
    my="1rem"
  >
    {`no ${itemName} founded`}
  </Typography>
);

export default HasNoItems;
