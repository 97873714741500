declare global {
  interface Window {
    dataLayer: Array<{
      event?: GAEventsType;
      originalLocation?: string;
    }>;
  }
}

/*
The service was created to simplify the interface for interacting with Google Analytics.
Service methods fire events at certain stages of the application.
Local storage is used to prevent repeated events at the same stage of the application when the user reloads the page.
*/

type GAEventsType = 'landed'
| 'q_started'
| 'phone_submitted'
| 'signed_up'
| 'otp_resend_requested'
| 'treatments_expanded'
| 'treatment_variant_selected'
| 'treatment_plan_selected'
| 'appointment_created'
| 'treatment_preference_submitted'
| 'order_created'
| 'delivery_address_selected'
| 'payment_method_selected'
| 'order_confirmed'
| string;

interface IGaEvents {
  [key: string]: GAEventsType
}

class GaService {
  private readonly GAEvents: IGaEvents = {
    landed: 'landed',
    q_started: 'q_started',
    phone_submitted: 'phone_submitted',
    signed_up: 'signed_up',
    otp_resend_requested: 'otp_resend_requested',
    treatments_expanded: 'treatments_expanded',
    treatment_variant_selected: 'treatment_variant_selected',
    treatment_plan_selected: 'treatment_plan_selected',
    appointment_created: 'appointment_created',
    treatment_preference_submitted: 'treatment_preference_submitted',
    order_created: 'order_created',
    delivery_address_selected: 'delivery_address_selected',
    payment_method_selected: 'payment_method_selected',
    order_confirmed: 'order_confirmed',
  };

  private readonly repeatableEvents: Array<GAEventsType> = [
    'phone_submitted',
    'signed_up',
    'otp_resend_requested',
    'treatments_expanded',
    'treatment_variant_selected',
    'treatment_plan_selected',
    'appointment_created',
    'treatment_preference_submitted',
    'order_created',
    'delivery_address_selected',
    'payment_method_selected',
    'order_confirmed',
  ];

  private readonly mainEvent: GAEventsType = 'landed';

  private static getFromStorage() {
    const candidate = localStorage.getItem('GAEvents');
    const storageEvents: GAEventsType[] = candidate ? JSON.parse(candidate) : [];
    return storageEvents;
  }

  private pushGaEvent(event: GAEventsType) {
    let storageEvents = GaService.getFromStorage();

    const isMain = event === this.mainEvent;
    if (isMain) storageEvents = [];

    const hasSame = storageEvents.some((el) => el === event);
    const isRepeatable = this.repeatableEvents.some((el) => el === event);
    if (!isMain && hasSame && !isRepeatable) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
    });

    if (isMain || !hasSame) {
      storageEvents.push(event);
      localStorage.setItem('GAEvents', JSON.stringify(storageEvents));
    }
    console.log(`${event}`);
  }

  pushLocation() {
    const location = `${document?.location?.protocol}//${
      document?.location?.hostname
    }${document?.location?.pathname
    }${document?.location?.search}`;
    window.dataLayer = window.dataLayer || [];
    const candidate = window.dataLayer.find((el) => el.originalLocation === location);
    if (candidate) return;
    window.dataLayer.push({
      originalLocation: location,
    });
  }

  landed() {
    this.pushGaEvent(this.GAEvents.landed);
  }

  q_started() {
    this.pushGaEvent(this.GAEvents.q_started);
  }

  question_submitted(id: string | number) {
    const eventName = `q_${id}_submitted`;
    this.pushGaEvent(eventName);
  }

  phone_submitted() {
    this.pushGaEvent(this.GAEvents.phone_submitted);
  }

  signed_up() {
    this.pushGaEvent(this.GAEvents.signed_up);
  }

  otp_resend_requested() {
    this.pushGaEvent(this.GAEvents.otp_resend_requested);
  }

  treatments_expanded() {
    this.pushGaEvent(this.GAEvents.treatments_expanded);
  }

  treatment_variant_selected() {
    this.pushGaEvent(this.GAEvents.treatment_variant_selected);
  }

  treatment_plan_selected() {
    this.pushGaEvent(this.GAEvents.treatment_plan_selected);
  }

  appointment_created() {
    this.pushGaEvent(this.GAEvents.appointment_created);
  }

  treatment_preference_submitted() {
    this.pushGaEvent(this.GAEvents.treatment_preference_submitted);
  }

  order_created() {
    this.pushGaEvent(this.GAEvents.order_created);
  }

  delivery_address_selected() {
    this.pushGaEvent(this.GAEvents.delivery_address_selected);
  }

  payment_method_selected() {
    this.pushGaEvent(this.GAEvents.payment_method_selected);
  }

  order_confirmed() {
    this.pushGaEvent(this.GAEvents.order_confirmed);
  }
}

export const gaService = new GaService();
