import i18n from 'i18next';

interface IErrorObjectItem {
  message: string;
  duration?: number;
}

interface IErrorObject {
  [key: string]: IErrorObjectItem;
}

// eslint-disable-next-line no-shadow
export enum customErrors {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  CANT_FETCH_QUESTIONS = 'CANT_FETCH_QUESTIONS',
  NOT_AUTH = 'NOT_AUTH',
  NO_CURRENT_APPOINTMENT_ID = 'NO_CURRENT_APPOINTMENT_ID',
  CAN_NOT_CREATE_ORDER = 'CAN_NOT_CREATE_ORDER',
  CAN_NOT_CREATE_APPOINTMENT = 'CAN_NOT_CREATE_APPOINTMENT',
  CANT_FIND_ANSWERS = 'CANT_FIND_ANSWERS',
  CAN_NOT_FETCH_SYMPTOM = 'CAN_NOT_FETCH_SYMPTOM',
}

const createErrorObj = () => {
  const generalErrors: IErrorObject = {
    'Network Error': {
      message: i18n.t('errors.unexpectedError'),
    },
    [customErrors.UNKNOWN_ERROR]: {
      message: i18n.t('errors.unexpectedError'),
      duration: 3000,
    },
    [customErrors.NO_CURRENT_APPOINTMENT_ID]: {
      message: i18n.t('errors.noCurrentAppointmentId'),
    },
    [customErrors.CANT_FIND_ANSWERS]: {
      message: i18n.t('notifications.cantFindAnswers'),
    },
  };
  const fetchErrors: IErrorObject = {
    [customErrors.CANT_FETCH_QUESTIONS]: {
      message: i18n.t('errors.cantFetchQuestions'),
    },
  };
  const createErrors: IErrorObject = {
    [customErrors.CAN_NOT_CREATE_ORDER]: {
      message: i18n.t('errors.cantCreateOrder'),
    },
    [customErrors.CAN_NOT_CREATE_APPOINTMENT]: {
      message: i18n.t('errors.cantCreateAppointment'),
    },
  };
  const authErrors: IErrorObject = {
    [customErrors.NOT_AUTH]: {
      message: '401',
    },
  };
  return {
    ...createErrors,
    ...generalErrors,
    ...fetchErrors,
    ...authErrors,
  };
};

// eslint-disable-next-line import/no-mutable-exports
export let errObject: IErrorObject = createErrorObj();

i18n.on('languageChanged', () => {
  errObject = createErrorObj();
});
