import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface Props {
  title: string;
}

const QuestionnaireTitle: FC<Props> = ({ title }) => (
  <Typography
    variant="h5"
    color="#333333"
    fontWeight={600}
    mb="55px"
    textAlign="center"
  >
    {title}
  </Typography>
);

export default QuestionnaireTitle;
