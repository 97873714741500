import { IServiceMethodFunc } from '../types/services';
import httpService from '../httpService';
import { IPostTreatmentArgs, ITreatment } from '../types/treatment';

interface ITreatmentService {
  fetchTreatmentBySymptomId: IServiceMethodFunc<{
    symptomId: number; country_id?: null | number
  }, ITreatment[]>;
  postTreatment: IServiceMethodFunc<IPostTreatmentArgs, ITreatment[]>;
}

const treatmentService: ITreatmentService = {
  fetchTreatmentBySymptomId: ({ symptomId, country_id }) => {
    let url = `treatments?symptom_id=${symptomId}`;
    if (country_id) url = `${url}&country_id=${country_id}`;
    return httpService.get(url);
  },
  postTreatment: ({ appointment_id, treatment_plan_id, treatment_variant_id }) => {
    const body: IPostTreatmentArgs = {
      appointment_id,
    };
    if (treatment_variant_id) body.treatment_variant_id = treatment_variant_id;
    if (treatment_plan_id) body.treatment_plan_id = treatment_plan_id;
    return httpService.post('treatment_preferences', body);
  },
};

export default treatmentService;
