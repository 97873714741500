import React, { FC } from 'react';
import { Button, ButtonTypeMap } from '@mui/material';
import { useSelector } from '../../store';
import { getDirection } from '../../store/selectors/user';

interface Props {
  label: string;
  color: ButtonTypeMap['props']['color'];
  onClick: () => void;
  disabled?: boolean;
}

const PrevNextButton: FC<Props> = ({
  label, color, onClick, disabled,
}) => {
  const direction = useSelector(getDirection);
  return (
    <Button
      dir={direction}
      variant="contained"
      color={color}
      onClick={onClick}
      disabled={disabled}
      sx={{
        margin: '1rem 1rem 1.5rem 1rem',
        minWidth: '100px',
        boxShadow: 'none!important',
        transition: 'none!important',
      }}
    >
      {label}
    </Button>
  );
};

export default PrevNextButton;
