import { useParams } from 'react-router-dom';
import { useSelector } from '../store';
import { FullParams } from '../types/navigation';

interface IFormLikArgs {
  mainPath: string;
  orderIdArg?: string | number;
  appointmentIdArg?: string | number;
}

/*
  Route link structure should take into account the presence of an order id or a meeting id in the url
  The function finds the required data and adds it to the link
*/
const useFormLinkTo = () => {
  const currentAppointment = useSelector((state) => state.appointment.currentAppointment);
  const currentOrder = useSelector((state) => state.orders.currentOrder);
  const params = useParams<FullParams>();

  return (args: IFormLikArgs) => {
    const { orderIdArg, mainPath, appointmentIdArg } = args;

    const {
      symptomSlug,
      appointmentId: paramsAppointmentId,
      orderId: paramsOrderId,
    } = params;

    if (!symptomSlug) return '/not-found';

    const appointmentId = appointmentIdArg || paramsAppointmentId || currentAppointment?.id;
    const orderId = orderIdArg || paramsOrderId || currentOrder?.id;

    let navURL = `${mainPath}/${symptomSlug}`;

    if (appointmentId) navURL = `${navURL}/${appointmentId}`;
    if (orderId) navURL = `${navURL}/${orderId}`;
    return navURL;
  };
};

export default useFormLinkTo;
