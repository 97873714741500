/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { handleError } from './notifier/notifier';
import { IQuestion, ISymptomData } from '../../types/common';
import questionnaireService from '../../services/questionnaire';
import { IActionArg, IDefaultStateFields } from '../../types/store';
import { customErrors } from './notifier/errorObject';
import CustomError from './notifier/customErrorClass';
import {
  IUserAnswer, TNormalizedData, TUserAnswers, UserDescriptions,
} from '../../types/questionnaire';
import { questionsNormalizer } from '../../helpers/questionsNormalizer';
import { PatientQuestionnaireResponse } from '../../types/appointments';
import appointmentsService from '../../services/appointments';
import { gaService } from '../../helpers/googleAnalytics';

interface IQuestionnaireSlice extends IDefaultStateFields {
  symptomDataById: ISymptomData | null;
  userAnswers: TUserAnswers;
  normalizedData: TNormalizedData;
  userDescriptions: UserDescriptions;
}

const questionnaireSliceInitialState: IQuestionnaireSlice = {
  symptomDataById: null,
  loading: false,
  userAnswers: {},
  userDescriptions: {},
  normalizedData: null,
};

// ! helpers
const sortArrayByPosition = (array: Array<{ position: number }>) => array.sort((
  itemA,
  itemB,
) => itemA.position - itemB.position);
const sortQuestionsAndAnswersByPosition = (arrayOfQuestions: IQuestion[]) => {
  if (!arrayOfQuestions?.length) return;

  sortArrayByPosition(arrayOfQuestions);
  arrayOfQuestions.forEach((questionItem) => {
    if (questionItem?.answers?.length) sortArrayByPosition(questionItem.answers);
  });
};

// ! Thunks
export const fetchSymptomDataById = createAsyncThunk(
  'questionnaire/fetchSymptomDataById',
  async (symptomId: number, { rejectWithValue, dispatch }) => {
    try {
      const res = await questionnaireService.fetchSymptomDataById(symptomId);
      return res.data;
    } catch (e: any) {
      dispatch(handleError(new CustomError(customErrors.CANT_FETCH_QUESTIONS)));
      return rejectWithValue(e);
    }
  },
);
export const postQuestionnaireResponses = createAsyncThunk(
  'questionnaire/postQuestionnaireResponses',
  async (
    userAnswers: { questionnaireResponse: PatientQuestionnaireResponse, appointmentId: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const res = await appointmentsService.postQuestionnaireResponses(userAnswers);
      return res.data;
    } catch (e: any) {
      dispatch(handleError(e));
      return rejectWithValue(e);
    }
  },
);

const questionnaireSlice = createSlice({
  name: 'questionnaireSlice',
  initialState: questionnaireSliceInitialState,
  reducers: {
    setUserDescriptions(state, { payload }: IActionArg<UserDescriptions>) {
      const descEntries = Object.entries(payload);
      if (!descEntries?.length) return;
      const oldDesc = { ...state.userDescriptions };
      descEntries.forEach(([key, value]) => { oldDesc[key] = value; });
      state.userDescriptions = oldDesc;
    },
    clearUserDescriptions(state) {
      state.userDescriptions = {};
    },
    setUserAnswer(state, action: IActionArg<IUserAnswer>) {
      const { payload: { answer, questionId } } = action;
      gaService.question_submitted(questionId);
      state.userAnswers[questionId] = answer;
    },
    cleanUserAnswers(state) {
      state.userAnswers = {};
    },
    removeUserAnswer(state, { payload }: IActionArg<number | number[]>) {
      if (Array.isArray(payload)) {
        payload.forEach((id) => delete state.userAnswers[id]);
        return;
      }
      delete state.userAnswers[payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSymptomDataById.pending, pendingCase)
      .addCase(fetchSymptomDataById.rejected, errorCase)
      .addCase(fetchSymptomDataById.fulfilled, (state, { payload }: IActionArg<ISymptomData>) => {
        if (!payload) return;
        const payloadCopy: ISymptomData = JSON.parse(JSON.stringify(payload));
        sortQuestionsAndAnswersByPosition(payloadCopy.questions);
        const normalizedQuestions = questionsNormalizer(payloadCopy.questions);
        state.normalizedData = normalizedQuestions as TNormalizedData;
        state.loading = false;
        state.symptomDataById = payloadCopy;
      });
    builder.addCase(postQuestionnaireResponses.pending, pendingCase)
      .addCase(postQuestionnaireResponses.rejected, errorCase)
      .addCase(postQuestionnaireResponses.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setUserDescriptions,
  setUserAnswer,
  removeUserAnswer,
  cleanUserAnswers,
  clearUserDescriptions,
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
