import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  salePrice: number;
  discount: number;
}

const DiscountBlock: FC<Props> = ({ discount, salePrice }) => (
  <Box display="flex">
    <Typography
      variant="caption"
      display="block"
      lineHeight="1"
      fontWeight="500"
      color="#b2b2b2"
      fontSize={14}
      sx={{ textDecoration: 'line-through' }}
      className="item-discount"
    >
      {`KWD ${salePrice}`}
    </Typography>
    <Typography
      variant="caption"
      display="block"
      lineHeight="1"
      fontWeight="500"
      color="#1cb4b0"
      fontSize={14}
      ml="5px"
      className="item-discount"
    >
      {`(${discount}%)`}
    </Typography>
  </Box>
);

export default DiscountBlock;
