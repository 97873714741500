import React, { FC, useMemo } from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import DiscountBlock from './DiscountBlock';

interface Props {
  unitPrice: number;
  salePrice: number;
  currency: string;
  hideDiscount?: boolean;
  priceTextAlign?: TypographyProps['textAlign'];
}

const PriceBlock: FC<Props> = ({
  salePrice, unitPrice, currency, hideDiscount, priceTextAlign,
}) => {
  const discount = useMemo(() => {
    const increase = salePrice - unitPrice;
    const percentage = (increase / unitPrice) * 100;
    return Math.round(percentage);
  }, [salePrice, unitPrice]);
  return (
    <Box>
      <Typography
        variant="body1"
        fontWeight="600"
        textAlign={priceTextAlign || 'right'}
        fontSize={16}
        className="item-price"
      >
        {`${currency} ${salePrice}`}
      </Typography>
      {/* DISCOUNT BLOCK */}
      {!hideDiscount && <DiscountBlock salePrice={unitPrice} discount={discount} />}
    </Box>
  );
};

export default PriceBlock;
