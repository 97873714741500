import { IServiceMethodFunc } from '../types/services';
import httpService from '../httpService';
import { IAddress } from '../types/user';

interface IAddressService {
  fetchAddresses: IServiceMethodFunc<void, IAddress[]>;
  updateAddress: IServiceMethodFunc<IAddress, IAddress>;
  postNewAddress: IServiceMethodFunc<Partial<IAddress>, IAddress>;
}

const addressService: IAddressService = {
  fetchAddresses: () => httpService.get('addresses'),
  postNewAddress: (newAddress) => httpService.post('addresses', newAddress),
  updateAddress: (address) => httpService.patch(`addresses/${address.id}`, address),
};

export default addressService;
