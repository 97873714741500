import React, { FC } from 'react';
import { FormControl, RadioGroup } from '@mui/material';
import VariantsListItem from './VariantsListItem/VariantsListItem';
import { ITreatmentPlan, ITreatmentVariant } from '../../types/treatment';

interface Props {
  variants: ITreatmentVariant[] | ITreatmentPlan[];
  selectedTrVariant: number | null;
  onVariantSelect: (variantId: number | null) => void;
}

const VariantsList: FC<Props> = ({ variants, selectedTrVariant, onVariantSelect }) => (
  <FormControl sx={{ width: '100%' }}>
    <RadioGroup>
      {
        variants.map((variant) => (
          <VariantsListItem
            key={variant.id}
            variant={variant}
            selectedTrVariant={selectedTrVariant}
            onVariantSelect={onVariantSelect}
          />
        ))
      }
    </RadioGroup>
  </FormControl>
);

export default VariantsList;
