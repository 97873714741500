import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
} from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from '../../store';

interface Props {
  setInfoHeight: Dispatch<SetStateAction<number>>;
  infoHeight: number;
}

const InfoLine: FC<Props> = ({ setInfoHeight, infoHeight }) => {
  // ! selectors
  const infoMessage = useSelector<string | undefined>((state) => state.orders.currentOrder?.info_message);

  useEffect(() => {
    const line: HTMLElement | null = document.getElementById('running-line');
    if (!line) return;
    if (infoHeight === line.offsetHeight) return;
    setInfoHeight(line.offsetHeight);
  });

  // ! render
  if (!infoMessage) return null;
  return (
    <Box
      sx={{
        width: '100%',
        height: 'fit-content',
        minHeight: '24px',
        backgroundColor: '#20c7c4',
        marginTop: '2rem',
        textAlign: 'center',
      }}
    >
      <Typography
        id="running-line"
        variant="body1"
        color="common.white"
        fontWeight={600}
        title={infoMessage}
        sx={{ paddingX: '10px' }}
      >
        {infoMessage}
      </Typography>
    </Box>
  );
};

export default React.memo(InfoLine);
