import React, { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorLine from './ErrorLine';
import { useSelector } from '../../../store';
import { IOrderInstance } from '../../../types/order';

interface Props {
  onPlaceRequest: () => void;
}

const PaymentBottom: FC<Props> = ({ onPlaceRequest }) => {
  const { t } = useTranslation();
  const [isErrorLine] = useState(false);
  const orderNextAction = useSelector((state) => state.orders.currentOrder?.next_action);
  const ordersLoading = useSelector((state) => state.orders.loading);
  const currentOrder = useSelector<IOrderInstance | null>((state) => state.orders.currentOrder);
  const {
    currency_code_iso: currencyCode,
    final_amount: finalAmount,
  } = currentOrder || {} as IOrderInstance;
  // ! render
  return (
    <>
      {/* INFO */}
      {isErrorLine && <ErrorLine />}
      {(currencyCode && finalAmount)
        && (
          <Box sx={{
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <Typography variant="body1" fontWeight="600">
              {t('checkout.totalSum')}
              :
            </Typography>
            <Typography variant="body1" fontWeight="600" ml="1rem">
              {currencyCode}
              {' '}
              {finalAmount}
            </Typography>
          </Box>
        )}
      <Box className="flex-center" sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
        <Button
          disabled={ordersLoading || !orderNextAction}
          onClick={(orderNextAction) ? onPlaceRequest : () => {}}
          variant="contained"
          color={(orderNextAction) ? 'success' : 'secondary'}
          sx={{ fontWeight: 600, fontSize: '15px' }}
        >
          {orderNextAction?.display_text || t('buttons.placeRequest')}
        </Button>
      </Box>
    </>
  );
};

export default PaymentBottom;
