import React, { FC } from 'react';
import { Typography } from '@mui/material';
import FormCard from '../FormCard';

interface Props {
  isEmpty?: boolean;
}

const FormRightSideWrap: FC<Props> = ({ children, isEmpty }) => (
  <FormCard>
    <Typography variant="h6" mb="1rem">Order Summary</Typography>
    <Typography variant="body1" fontWeight="500">Items</Typography>
    {isEmpty
      ? (
        <Typography
          variant="body2"
          textAlign="center"
          fontWeight={600}
        >
          can not find order
        </Typography>
      )
      : children}
  </FormCard>
);

export default FormRightSideWrap;
