import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import CreditCardWrap from './CreditCardWrap';
import VisaIcon from '../../../../assets/icons/VisaIcon';
import MasterCardIcon from '../../../../assets/icons/MasterCardIcon';

interface Props {
  selectedCardId: number | null;
  onRadioClick: (cardId: number) => void;
  lastNums: string;
  instrumentId: number;
  isVisa: boolean;
}

const CreditItem: FC<Props> = ({
  onRadioClick,
  selectedCardId,
  lastNums,
  instrumentId,
  isVisa,
}) => (
  <CreditCardWrap checked={selectedCardId === instrumentId} onCardClick={() => onRadioClick(instrumentId)}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Radio sx={{ padding: 0 }} checked={selectedCardId === instrumentId} />
      <Box sx={{
        height: '25px', width: '25px', marginX: '3px', flexShrink: 0,
      }}
      >
        {isVisa ? <VisaIcon /> : <MasterCardIcon />}
      </Box>
      <Typography
        variant="body1"
        fontWeight={500}
        noWrap
      >
        Card ending
        {' '}
        {lastNums}
      </Typography>
    </Box>
  </CreditCardWrap>
);
export default CreditItem;
