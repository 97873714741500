import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Modal } from '@mui/material';

interface CustomModalWrapProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CustomModalWrap: FC<CustomModalWrapProps> = ({ setOpen, open, children }) => (
  <Modal className="flex-center" open={open} onClose={() => setOpen(false)}>
    <Box sx={{ maxWidth: '100%', outline: 'none' }}>
      {children}
    </Box>
  </Modal>
);

export default CustomModalWrap;
